import { CSVColumn, CSVFormat, CSV_VALUE_TYPE_DESCS, Campaign, VALID_PERIOD_MIN } from '@sasagase/types';
import iconv from 'iconv-lite';
import * as React from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { getCampaignsByShopReward, getRewardTemplateImage, postImage } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { toBR, useTimer } from '../../../lib';
import { isUserCSVField, useAvailableCSVFormats, useAvailableOptions } from '../../../lib/shopCustom';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../lib/usePrompt';
import FormErrorMessage from '../FormErrorMessage';
import { SaveResult } from './RewardEdit';

const regions = [
	{
		name: "北海道",
		list: [
			{ name: "北海道", value: "HOKKAIDO" }
		]
	},
	{
		name: "東北",
		list: [
			{ name: "青森県", value: "AOMORI" },
			{ name: "岩手県", value: "IWATE" },
			{ name: "宮城県", value: "MIYAGI" },
			{ name: "秋田県", value: "AKITA" },
			{ name: "山形県", value: "YAMAGATA" },
			{ name: "福島県", value: "FUKUSHIMA" },
		]
	},
	{
		name: "関東",
		list: [
			{ name: "茨城県", value: "IBARAKI" },
			{ name: "栃木県", value: "TOCHIGI" },
			{ name: "群馬県", value: "GUNMA" },
			{ name: "埼玉県", value: "SAITAMA" },
			{ name: "千葉県", value: "CHIBA" },
			{ name: "東京都", value: "TOKYO" },
			{ name: "神奈川県", value: "KANAGAWA" },
		]
	},
	{
		name: "北陸・甲信越",
		list: [
			{ name: "新潟県", value: "NIIGATA" },
			{ name: "富山県", value: "TOYAMA" },
			{ name: "石川県", value: "ISHIKAWA" },
			{ name: "福井県", value: "FUKUI" },
			{ name: "山梨県", value: "YAMANASHI" },
			{ name: "長野県", value: "NAGANO" },
		]
	},
	{
		name: "東海",
		list: [
			{ name: "岐阜県", value: "GIFU" },
			{ name: "静岡県", value: "SHIZUOKA" },
			{ name: "愛知県", value: "AICHI" },
			{ name: "三重県", value: "MIE" },
		]
	},
	{
		name: "近畿",
		list: [
			{ name: "滋賀県", value: "SHIGA" },
			{ name: "京都府", value: "KYOTO" },
			{ name: "大阪府", value: "OSAKA" },
			{ name: "兵庫県", value: "HYOGO" },
			{ name: "奈良県", value: "NARA" },
			{ name: "和歌山県", value: "WAKAYAMA" },
		]
	},
	{
		name: "中国",
		list: [
			{ name: "鳥取県", value: "TOTTORI" },
			{ name: "島根県", value: "SHIMANE" },
			{ name: "岡山県", value: "OKAYAMA" },
			{ name: "広島県", value: "HIROSHIMA" },
			{ name: "山口県", value: "YAMAGUCHI" },
		]
	},
	{
		name: "四国",
		list: [
			{ name: "徳島県", value: "TOKUSHIMA" },
			{ name: "香川県", value: "KAGAWA" },
			{ name: "愛媛県", value: "EHIME" },
			{ name: "高知県", value: "KOCHI" },
		]
	},
	{
		name: "九州",
		list: [
			{ name: "福岡県", value: "FUKUOKA" },
			{ name: "佐賀県", value: "SAGA" },
			{ name: "長崎県", value: "NAGASAKI" },
			{ name: "熊本県", value: "KUMAMOTO" },
			{ name: "大分県", value: "OITA" },
			{ name: "宮崎県", value: "MIYAZAKI" },
			{ name: "鹿児島県", value: "KAGOSHIMA" },
		]
	},
	{
		name: "沖縄",
		list: [
			{ name: "沖縄県", value: "OKINAWA" }
		]
	}
];

export interface RewardEditFormValues {
	id: string;
	rewardType: string; // 'item' | 'coupon'
	name: string;
	description: string;
	image: string;
	limitedType: string; // 'unlimited' | 'limited'
	quantity: string;
	csvFormat: string;
	csvClientType: string; // 'orderer' | 'user'
	csvUserSettings: Record<string, Record<string, string>>;
	optionAttributes?: Record<string, unknown>;
	issueFreq: string;
	discountType: string; // 'price' | 'rate' | 'shipping'
	discountPrice: string;
	discountRate: string;
	validPeriod: string;
	validDays: string;
	displayType: string; // 'private' | 'public'
	couponImageType: string; // 'default' | 'template' | 'user'
	template: string;
	userCouponImage: string;
	cabinetImageUrl: string;
	usingType: string; // 'limited' | 'unlimited'
	usingLimit: string;
	issueType: string; // 'limited' | 'unlimited'
	issueLimit: string;
	combineUse: string; // 'disallow' | 'allow'
	conditionType: string; // 'nothing' | 'price' | 'amount'
	conditionPrice: string;
	conditionAmount: string;
	targetType: string; // 'all' | 'specify'
	couponTarget: string;
	conditionRankType: string; // 'nothing' | 'specify'
	conditionRankCond: string[];
	purchaseHistoryCond: string, // 'nothing' | 'new' | 'repeat'
	dynamicPeriod: string;
	purchaseCount: string;
	purchaseCountLower: string;
	purchaseCountUpper: string;
	genderCond: string; // 'nothing' | 'male' | 'female'
	ageRangeCond: string; // 'nothing' | 'specify'
	ageRangeCondLower: string;
	ageRangeCondUpper: string;
	birthMonthCond: string; // 'nothing' | 'specify'
	birthMonth: string;
	multiPrefectureCond: string; // 'nothing' | 'specify'
	prefectureCond: string[];
	prefectureAll?: string;
	region?: string[];
}

interface RewardEditFormProps {
	initValues: RewardEditFormValues;
	onSave: (values: RewardEditFormValues, file?: File) => Promise<SaveResult>;
}

async function filesToIds(files: FileList): Promise<string[]> {
	const decoder = new TextDecoder('Shift_JIS');

	const ids: string[] = [];
	for (const file of Array.from(files)) {
		const buff = await file.arrayBuffer();
		const data = decoder.decode(buff);

		try {
			ids.push(...data.split(`\r\n`));
		} catch (err) {
			alert(`ファイルの読込みに失敗しました。(${file.name})\n\n${err.message}`);
			throw err;
		}
	}
	return ids;
}

const csvRule = (format: CSVFormat, col: CSVColumn): RegisterOptions => {
	const desc = CSV_VALUE_TYPE_DESCS[col.valueType || 'any'];
	return {
		validate: (data: unknown) => {
			const val = String(data);
			if (!desc.regex.test(val)) {
				return `${desc.name} を入力してください`;
			}
			if (format.lengthEncode) {
				const buff = iconv.encode(val, format.lengthEncode);
				if (col.length != null && buff.length > col.length) {
					return desc.length(col.length) + '以下で入力してください';
				}
			}
			return true;
		},
	};
};

function getImageName(template: string, discountType: string, discountFactor: string): string {
	const specifyFactor = discountFactor ? '_' + discountFactor : '';
	return `t_${template}_${discountType.charAt(0)}${specifyFactor}.jpg`;
}

export function RewardEditForm(props: RewardEditFormProps): React.ReactElement {
	const location = useLocation();
	const urlParamsTab = new URLSearchParams(location.search).get("tab");
	if (urlParamsTab === "present") {
		props.initValues.rewardType = "item";
	} else if (urlParamsTab === "coupon") {
		props.initValues.rewardType = "coupon";
	}

	const prefectureAllCheck = (prefectureCond: string[]) => {
		const isAllIncluded = regions.flatMap(region => region.list).every(prefecture => prefectureCond.includes(prefecture.value));
		return isAllIncluded ? "checkAll" : '';
	}

	const regionCheck = (prefectureCond: string[]) => {
		const regionList = regions.flatMap(region =>
			region.list.every(prefecture => prefectureCond.includes(prefecture.value)) ? "checkAll" : ''
		);
		return regionList;
	}

	const { register, handleSubmit, watch, errors, setValue, getValues, reset, setError, clearErrors, formState: { isDirty, isSubmitting } } = useForm<RewardEditFormValues>({
		defaultValues: {
			prefectureAll: prefectureAllCheck(props.initValues.prefectureCond),
			region: regionCheck(props.initValues.prefectureCond),
			...props.initValues,
		},
		shouldUnregister: false,
	});
	// isSubmitting: 新規追加成功時に新しいUUIDのページへのリダイレクト制御のため
	usePrompt(PROMPT_CONFIRM_MESSAGE, (isDirty && !isSubmitting));

	const callAPI = useAPI();
	const [state] = useAppState();
	const [result, setResult] = React.useState('');
	const [messages, setMessages] = React.useState<string[]>([]);
	const [imageFile, setImageFile] = React.useState<File|undefined>(undefined);
	const [csvUserOpens, setCsvUserOpens] = React.useState<Record<string, boolean | undefined>>({});

	const availableCSVFormats = useAvailableCSVFormats();
	const userFieldCSVFormats = availableCSVFormats.filter((csv) => csv.columns.some(isUserCSVField));
	const availableOptions = useAvailableOptions();

	const setTimer = useTimer();

	React.useEffect(() => {
		register('image');
		register('userCouponImage');
	}, [register]);

	const handleChangeFile = async (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!ev.target.files?.length) {
			return;
		}
		const format = /^((.{0,20}).*)(\.(?:jpg|jpeg|png|gif|bmp|tif))$/i;
		if (!format.test(ev.target.files[0].name)) {
			alert('規定外のファイル形式です。画像ファイル(gif、gifアニメ、jpg、jpeg、tif、png、bmp)を選択してください。');
			return;
		}
		const id = await callAPI(postImage(state.params.shopId, ev.target.files[0]));
		setValue(ev.target.name, id.data);

		// Coupon,CabinetAPIで必要
		if (getValues('rewardType') == 'coupon') {
			/**
			 * CabinetAPIのファイル名・ファイル形式の仕様
			 * ファイル名:20バイト以内[半角20文字以内]
			 * 登録可能な形式 ： JPEG、GIF、アニメーションGIF、PNG、TIFF、BMP
			 *   ※PNG、TIFF、BMP形式の画像はJPEGに変換  (その他の形式はエラー)
			 *   作成するファイル名も変換後の拡張子を指定しないとエラー
			 */
			const match = id.data.replace(/-/g, '').match(format);
			const fileName = match[2] + (['.jpg', '.gif'].includes(match[3].toLowerCase()) ? match[3].toLowerCase() : '.jpg');
			const file = new File([ev.target.files[0]], fileName, { type: ev.target.files[0].type });
			setImageFile(file);
		}
		ev.target.value = '';
	};
	const handleChangeCouponTargetFile = async (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!ev.target.files?.length) {
			return;
		}
		const ids = await filesToIds(ev.target.files);
		setValue('couponTarget', ids.join(`\n`));
		setValue(ev.target.name, ev.target.files[0].name);

		ev.target.value = '';
	};
	const handleChangeConditionRankType = (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!ev.target.value) {
			return;
		}
		if (ev.target.value == 'nothing') {
			setValue('conditionRankCond[]', []);
		}
		if (ev.target.value == 'specify') {
			setValue('purchaseHistoryCond', 'nothing');
			setValue('genderCond', 'nothing');
			setValue('ageRangeCond', 'nothing');
			setValue('birthMonthCond', 'nothing');
			setValue('multiPrefectureCond', 'nothing');
		}
	};
	const handleClickDeleteImage = (name: string) => () => {
		setValue(name, '');
	};
	const generateTemplateImage = async () => {
		const { discountType, discountPrice, discountRate, template } = getValues();
		// 値引きプランに応じてテンプレート画像を生成
		const type = String(discountType);
		const factor = type == 'price' ? String(discountPrice) : 
						type == 'rate' ? String(discountRate) : 
						'';
		const image = await callAPI(getRewardTemplateImage(state.params.shopId, String(template), type, factor));
		if (!image.data) {
			return undefined;
		}

		// CabinetAPIへの登録で必要
		const name = getImageName(template, type, factor);
		const file = new File([image.data], name, { type: image.headers['content-type'] });
		return file;
	};
	const handleClickSave = handleSubmit(async (values) => {
		const quantity = values.quantity;
		const limitType = values.limitedType;
		if (limitType === "limited" && Number(quantity) === 0 && values.id) {
			// 特典に紐づくキャンペーンを取得
			const result = await callAPI(getCampaignsByShopReward(state.params.shopId, values.id));
			if (result.data.length > 0) {
				const closeCampaignNames: string[] = [];
				const campaigns: Campaign[] = result.data.map((obj: Record<string, unknown>) => Campaign.create(obj));
				// 終了していないキャンペーンで特典が利用不可のキャンペーン名を取得
				for (const campaign of campaigns.filter(campaign => campaign.isStateOnBefore('waitingReview'))) {
					const rewards = campaign.rewards;
					const hasValidReward = rewards.some((reward) => values.id !== reward.id && reward.isAvailable());
					if (!hasValidReward) {
						closeCampaignNames.push(campaign.name);
					}
				}
				if (closeCampaignNames.length > 0) {
					const message = `在庫数が0になることにより以下のキャンペーンで\n選択可能なレビュー特典がなくなります。\n「終了(キャンセル)」状態に変更されますがよろしいですか？\n\n${closeCampaignNames.join("\n")}`;
					if (!window.confirm(message)) {
						return;
					}
				}
			}
		}
		let saveFile;
		if (values.couponImageType === 'user') {
			if (imageFile) {
				saveFile = imageFile;
			}
		} else if (values.couponImageType == "template") {
			// Submit時にテンプレート画像を生成
			saveFile = await generateTemplateImage();
		}
		const res = await props.onSave(values, saveFile);
		setMessages([]);
		if (!res.result && res.errors && res.errors.length > 0) {
			const msg: string[] = [];
			res.errors.map((err) => {
				msg.push(err.message);
			});
			setMessages(msg);
			window.scrollTo(0, 0);
		}
		setResult(res.result ? 'is_success' : 'is_error');

		if (res.result) {
			setTimer(() => setResult(''), 6000);
			reset(values);
		}

	});
	const nonNegative: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
		const i = parseInt(ev.target.value, 10);
		ev.target.value = isNaN(i) ? "" : Math.max(0, i).toString();
	};
	const validationSpecifyTarget = () => {
		const { targetType, validPeriod, validDays, issueLimit, issueType, conditionRankType, conditionRankCond } = getValues();
		if (targetType == 'specify') {
			if ((validPeriod !== 'days' || parseInt(validDays, 10) > 14)
				&& (issueType === 'unlimited' || parseInt(issueLimit, 10) > 1000)
				&& (conditionRankType === 'nothing' || conditionRankCond.length <= 0)) {
				return '「クーポン対象商品」を指定した場合、クーポン有効期間を14日以内の範囲で設定する、またはクーポンの全利用回数上限を1000回以下に指定する、または会員ランクを指定して下さい。';
			}
		}
	};
	const validationSpecifyTargetToShipping = () => {
		const { targetType, discountType } = getValues();
		if (targetType == 'specify' && discountType == 'shipping') {
			return '送料無料の場合、クーポン対象商品を指定できません';
		}
	};
	const toUrlParam = (param: string | string[]): string => {
		if (Array.isArray(param)) {
			return "";
		} else if (param === "item") {
			return "present";
		} else if (param === "coupon") {
			return "coupon";
		} else {
			return "";
		}
	}

	const handleClickCSVUserSetting = (id: string) => () => {
		setCsvUserOpens((prev) => ({
			...prev,
			[id]: !prev[id],
		}));
	};

	const handlePurchaseRangeChange = () => {
		clearErrors('purchaseCount');
		if (!disabledCondition && getValues("purchaseCountLower") != "" && getValues("purchaseCountUpper") != "") {
			if (parseInt(getValues("purchaseCountLower"), 10) > parseInt(getValues("purchaseCountUpper"), 10)) {
				setError('purchaseCount', { type: 'manual', message: '購入回数の上限は下限よりも大きい値を指定してください' });
			}
		}
	};

	const handleAgeRangeChange = () => {
		clearErrors('ageRangeCond');
		if (getValues("ageRangeCondLower") != "" && getValues("ageRangeCondUpper") != "") {
			if (parseInt(getValues("ageRangeCondLower"), 10) > parseInt(getValues("ageRangeCondUpper"), 10)) {
				setError('ageRangeCond', { type: 'manual', message: '年齢の上限は下限よりも大きい値を指定してください' });
			}
		}
	};

	const validationPrefectureCond = () => {
		if (!disabledCondition && getValues("multiPrefectureCond") === "specify") {
			const prefectures = getValues("prefectureCond[]");
			if (Array.isArray(prefectures) && prefectures.length === 0) {
				return "居住地を選択してください";
			}
		}
	};

	const rewardType = watch('rewardType');
	const image = watch('image');
	const userCouponImage = watch('userCouponImage');
	const cabinetImageUrl = watch('cabinetImageUrl');
	const conditionRankType = watch('conditionRankType');
	const purchaseHistoryCond = watch('purchaseHistoryCond');
	const ageRangeCond = watch('ageRangeCond');
	const birthMonthCond = watch('birthMonthCond');
	const multiPrefectureCond = watch('multiPrefectureCond');
	const disabledCondition = conditionRankType === 'specify' ? true : false;

	const handleRegionChange = (index: number, isChecked: boolean) => {
		const region = regions[index];
		const prefectures = region.list.map(prefecture => prefecture.value);
		let prefectureCond: string[] = getValues("prefectureCond[]") || [];
		if (isChecked) {
			prefectures.forEach(prefecture => {
				if (!prefectureCond.includes(prefecture)) {
					prefectureCond.push(prefecture);
				}
			});
		} else {
			prefectureCond = prefectureCond.filter(prefecture => !prefectures.includes(prefecture));
		}
		setValue("prefectureCond[]", prefectureCond);
		setValue("prefectureAll", prefectureAllCheck(prefectureCond));
	};

	const handlePrefectureCondChange = () => {
		const prefectureCond: string[] = getValues("prefectureCond[]");
		setValue("region", regionCheck(prefectureCond));
		setValue("prefectureAll", prefectureAllCheck(prefectureCond));
	};

	const handlePrefectureSelectAll = (isChecked: boolean) => {
		let prefectureCond: string[] = [];
		if (isChecked) {
			prefectureCond = regions.flatMap(region => region.list.map(prefecture => prefecture.value));
		}
		setValue("prefectureCond[]", prefectureCond);
		setValue("region", regionCheck(prefectureCond));
		setValue("prefectureAll", prefectureAllCheck(prefectureCond));
	};

	return (
		<>
			<div>
				<h1 className="el_pageTtl">レビュー特典登録</h1>
				<p className="el_pageDesc">プレゼント品・クーポンの新規登録、確認・編集ができます。</p>
				<div className="bl_row">
					<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
						<div className="bl_panel bl_panel__bt">
							{messages.length > 0 && 
								<div className="bl_panel_row">
									<h3 className="el_lv3Heading el_errorMessage">エラー</h3>
									{messages.map((msg, idx) =>
										<p key={`msg_${idx}`} className="el_errorMessage fs_14">{toBR(msg)}</p>
									)}
								</div>
							}
							<div className="bl_panel_row">
								<h3 className="el_lv3Heading">レビュー特典種類</h3>
								<label className="bl_label">
									<input type="radio" name="rewardType" value="item" ref={register} /><span>プレゼント品</span>
								</label>
								<label className="bl_label">
									<input type="radio" name="rewardType" value="coupon" ref={register} /><span>クーポン</span>
								</label>
							</div>
							<div className="bl_panel_row">
								<div className="bl_panel_row">
									<h3 className="el_lv3Heading">レビュー特典名</h3>
									<input type="text" placeholder="プレゼント" name="name" ref={register({
										required: '特典名を入力してください',
									})} />
									<FormErrorMessage errors={errors} name="name" />
								</div>
								<div className="bl_panel_row">
									<h3 className="el_lv3Heading">詳細説明文</h3>
									<textarea className="el_textarea" rows={3} placeholder="プレゼント" name="description" ref={register} />
								</div>
							</div>
							{rewardType == 'item' &&
								<div className="bl_panel_row">
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">プレゼント品画像</h3>
										<div className="bl_imgUpload">
											<div className="bl_imgUpload_imgWrap">
												{image &&
													<img src={`/storage/${state.params.shopId}/${image}`} />
												}
											</div>
											<div className="bl_imgUpload_txtWrap">
												<label className="el_btnTxt">
													<input type="file" name="image" onChange={handleChangeFile} />
													画像のアップロード
												</label>
												<button className="el_btnTxt" onClick={handleClickDeleteImage('image')}>削除</button>
												<p>※画像サイズは240px×240pxを推奨</p>
											</div>
										</div>
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">個数の指定</h3>
										<label className="bl_label">
											<input type="radio" name="limitedType" value="unlimited" ref={register} /><span>無制限</span>
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="limitedType" value="limited" ref={register} /><p className="bl_inputFormWrap">
												<span>個数を指定</span><input className="el_inlineInputL" type="text" name="quantity" onChange={nonNegative} ref={register({
													validate: (v) => getValues('limitedType') == 'unlimited' || parseInt(v, 10) >= 0,
												})} /><span>個限定</span>
											</p>
										</label>
										<FormErrorMessage errors={errors} name="quantity" message="個数を入力してください" />
										<p className="txt_blue fs_12 mt_16">※「個数を指定」した場合には、在庫数が0になった時点でユーザーは当該のプレゼント品の選択ができなくなります。また、キャンペーンの特典がすべて選択できない状態 (特典設定したプレゼント品の在庫数がすべて0の状態)になった時点で当該キャンペーンは自動的に終了となります。クレーム等の原因になりかねませんので、個数を指定する場合は十分ご注意ください。</p>
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">送り先CSV形式</h3>
										<div className="el_selectWrap">
											<select name="csvFormat" ref={register}>
												{availableCSVFormats.map(csv =>
													<option key={csv.id} value={csv.id}>{csv.name}</option>
												)}
											</select>
										</div>
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">CSV依頼主項目</h3>
										<label className="bl_label">
											<input type="radio" name="csvClientType" value="orderer" ref={register} /><span>注文者と同じ</span>
										</label>
										<label className="bl_label">
											<input type="radio" name="csvClientType" value="user" ref={register} /><span>ユーザ設定値</span>
										</label>
										<p className="txt_blue fs_12 mt_16">※「商品購入時と同じ配送先」に特典を送る場合であって、特典対象注文の「注文者」と「お届け先」が異なったときに、配送の依頼主に設定する値を指定します。</p>
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">CSVユーザ設定値</h3>
										{userFieldCSVFormats.map(csv =>
											<div className="bl_panel_indent" key={csv.id}>
												<h4 className={`el_lv4Heading bl_acc_ttl ${csvUserOpens[csv.id] ? 'is_active' : ''}`} onClick={handleClickCSVUserSetting(csv.id)}>{csv.name}</h4>
												<div className="bl_acc_body">
													{csv.columns.filter(isUserCSVField).map(col =>
														<div key={col.name}>
															<span>{col.name}</span>
															<input className="el_inlineInput textL" type="text" name={`csvUserSettings.${csv.id}.${col.name}`} ref={register(csvRule(csv, col))} />
															<FormErrorMessage errors={errors} name={`csvUserSettings.${csv.id}.${col.name}`} />
														</div>
													)}
												</div>
											</div>
										)}
									</div>
									{Object.keys(availableOptions).length > 0 &&
										<div className="bl_panel_row">
											<h3 className="el_lv3Heading">特典申込フォームオプション</h3>
											<div className="bl_panel_indent">
												{availableOptions.link &&
													<>
														<label className="bl_label">
															<span>特典詳細ページURL</span>
															<input className="el_inlineInput textL" type="text" name="optionAttributes.link" ref={register({
																pattern: /^\s*https?:\/\/([^/]+\.)?rakuten\.(ne|co)\.jp\//,
																setValueAs: (val) => val.trim(),
															})} />
														</label>
														<br/>
														<FormErrorMessage errors={errors} name="optionAttributes.link" message="楽天のURLのみ設定可能です" />
													</>
												}
												{availableOptions.kifuda &&
													<label className="bl_label">
														<input className="el_checkMark" type="checkbox" name="optionAttributes.kifuda" ref={register} />
														木札
													</label>
												}
												{availableOptions.maruKifuda &&
													<label className="bl_label">
														<input className="el_checkMark" type="checkbox" name="optionAttributes.maruKifuda" ref={register} />
														丸木札(女の子用)
													</label>
												}
												{availableOptions.maruKifudaM &&
													<label className="bl_label">
														<input className="el_checkMark" type="checkbox" name="optionAttributes.maruKifudaM" ref={register} />
														丸木札(男の子用)
													</label>
												}
												{availableOptions.namaebata &&
													<label className="bl_label">
														<input className="el_checkMark" type="checkbox" name="optionAttributes.namaebata" ref={register} />
														名前旗
													</label>
												}
												{availableOptions.namaekonori &&
													<label className="bl_label">
														<input className="el_checkMark" type="checkbox" name="optionAttributes.namaekonori" ref={register} />
														konori吹き流しパーツ名入れ
													</label>
												}
												{availableOptions.namaemagnet &&
													<label className="bl_label">
														<input className="el_checkMark" type="checkbox" name="optionAttributes.namaemagnet" ref={register} />
														お名前マグネット
													</label>
												}
											</div>
										</div>
									}
								</div>
							}
							{rewardType == 'coupon' &&
								<div className="bl_panel_indent">
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">クーポン発行タイミング</h3>
										<label className="bl_label"><input type="radio" name="issueFreq" value="individual" ref={register} />特典申込み時にお客様毎に発行する</label><br/>
										<label className="bl_label"><input type="radio" name="issueFreq" value="daily" ref={register} />毎日発行し、同日のクーポン利用者へ配布する</label><br/>
										<label className="bl_label"><input type="radio" name="issueFreq" value="weekly" ref={register} />毎週発行し、同週のクーポン利用者へ配布する</label><br/>
										<label className="bl_label"><input type="radio" name="issueFreq" value="monthly" ref={register} />毎月発行し、同月のクーポン利用者へ配布する</label><br/>
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">値引きプラン</h3>
										<label className="bl_label">
											<input type="radio" name="discountType" value="price" ref={register} />
											<p className="bl_inputFormWrap">
												<span>定額値引き</span><input className="el_inlineInputL" type="text" name="discountPrice" max={99999} onChange={nonNegative} ref={register({
													validate: (v) => getValues('discountType') != 'price' || parseInt(v, 10) >= 1,
													min: 1,
													max: 99999,
													maxLength: 5,
												})}	/><span>円 OFF</span>
											</p>
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="discountType" value="rate" ref={register} />
											<p className="bl_inputFormWrap">
												<span>定率値引き</span><input className="el_inlineInputL" type="text" name="discountRate" onChange={nonNegative} ref={register({
													validate: (v) => getValues('discountType') != 'rate' || parseInt(v, 10) >= 1,
													min: 1,
													max: 99,
												})} /><span>% OFF</span>
											</p>
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="discountType" value="shipping" ref={register({
												validate: {
													specifyTargetToShipping: () => validationSpecifyTargetToShipping(),
												},
											})} /><span>送料無料</span>
										</label>
										<FormErrorMessage errors={errors} name="discountPrice" message="定額値引き額を1〜99999の範囲で入力してください" />
										<FormErrorMessage errors={errors} name="discountRate" message="定率値引き割合を1〜99の範囲で入力してください" />
										<FormErrorMessage errors={errors} name="discountType" message={errors.discountType?.message} />
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">クーポン有効期間</h3>
										<label className="bl_label">
											<input type="radio" name="validPeriod" value="days" ref={register} />
											<p>
												<span>発行から</span><span className="sp_block"><input className="el_inlineInputM" type="text" name="validDays" onChange={nonNegative} ref={register({
													validate: {
														specifyTarget: () => validationSpecifyTarget(),
														min: (v) => getValues('validPeriod') !== 'days' || v >= VALID_PERIOD_MIN[getValues('issueFreq')] || `クーポン有効期間を${VALID_PERIOD_MIN[getValues('issueFreq')]}〜89の範囲で入力してください`,
														max: (v) => getValues('validPeriod') !== 'days' || v <= 89 || `クーポン有効期間を${VALID_PERIOD_MIN[getValues('issueFreq')]}〜89の範囲で入力してください`,
													},
												})} />日間有効（〜89）</span>
											</p>
										</label>
										<FormErrorMessage errors={errors} name="validDays" message={errors.validDays?.message} />
										<br/>
										<label className="bl_label">
											<input type="radio" name="validPeriod" value="nextMonth" ref={register} />発行から 翌月末まで有効
										</label>
										<br/>
										<label className="bl_label">
											<input type="radio" name="validPeriod" value="secondNextMonth" ref={register} />発行から 翌々月末まで有効
										</label>
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">クーポン画像</h3>
										<label className="bl_label">
											<input type="radio" name="couponImageType" value="default" ref={register} /><span>未指定（店舗ロゴを利用）</span>
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="couponImageType" value="template" ref={register} />
											{/* テンプレート選択部分は一旦廃止 */}
											<input type="hidden" name="template" value="a" ref={register} />
											<div className="bl_inputFormWrap">
												<span>テンプレートを使用する</span>
											</div>
										</label>
										<div className="bl_imgUpload pl_26">
											<div className="bl_imgUpload_imgWrap">
												<img src="/assets/img/tpl_a_p888.jpg" />
											</div>
											<div className="bl_imgUpload_imgWrap">
												<img src="/assets/img/tpl_a_r88.jpg" />
											</div>
											<div className="bl_imgUpload_imgWrap">
												<img src="/assets/img/tpl_a_s.jpg" />
											</div>
										</div>
										<br />
										<label className="bl_label">
											<input type="radio" name="couponImageType" value="user" ref={register({
												validate: (v) => !(getValues('userCouponImage') == '' && v == 'user')
															|| 'オリジナルを使用するを選択する場合、画像のアップロードは必須となります。下記より画像のアップロードをお願いします。',
											})} /><span>オリジナルを使用する</span>
										</label>
										<div className="bl_imgUpload pl_26">
											<div className="bl_imgUpload_imgWrap">
												{userCouponImage &&
													<img src={`/storage/${state.params.shopId}/${userCouponImage}`} />
												}
											</div>
											<div className="bl_imgUpload_txtWrap">
												<label className="el_btnTxt">
													<input type="file" name="userCouponImage" onChange={handleChangeFile} />
													画像のアップロード
												</label>
												<button className="el_btnTxt" onClick={handleClickDeleteImage('userCouponImage')}>削除</button>
												<p>※画像サイズは240px×240pxを推奨</p>
												<p>※画像は、gifとjpg以外は自動的にjpgに変換されます</p>
											</div>
										</div>
										<FormErrorMessage errors={errors} name="couponImageType" message={errors.couponImageType?.message} />
										{cabinetImageUrl &&
											<>
												<label className="bl_label">登録中のクーポン画像</label>
												<div className="bl_imgUpload pl_26">
													<div className="bl_imgUpload_imgWrap">
														<img src={`${cabinetImageUrl}`} />
														<input type="hidden" name="cabinetImageUrl" ref={register} />
													</div>
												</div>
											</>
										}
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">１ユーザーあたりの利用回数条件</h3>
										<label className="bl_label">
											<p className="bl_inputFormWrap">
												<input type="radio" name="usingType" value="limited" ref={register} />回数を指定する<span className="mobile_block mobile_indent_26"><span className="ml_16">1ユーザー</span><input className="el_inlineInputM" type="text" name="usingLimit" onChange={nonNegative} ref={register({
													validate: (v) => getValues('usingType') == 'unlimited' || parseInt(v, 10) >= 1,
													min: 0,
													max: 999999,
												})} />回まで利用可能</span>
											</p>
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="usingType" value="unlimited" ref={register} /><span>無制限</span>
										</label>
										<FormErrorMessage errors={errors} name="usingLimit" message="ユーザーあたりの利用回数条件を1〜999999の範囲で入力してください" />
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">クーポンの全利用回数上限</h3>
										<label className="bl_label">
											<p className="bl_inputFormWrap">
												<input type="radio" name="issueType" value="limited" ref={register} />回数を指定する<span className="mobile_block mobile_indent_26"><span className="ml_16">先着</span><input className="el_inlineInputM" type="text" name="issueLimit" onChange={nonNegative} ref={register({
													validate: (v) => getValues('issueType') == 'unlimited' || parseInt(v, 10) >= 1,
													min: 0,
													max: 999999,
												})} />回まで利用可能</span>
											</p>
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="issueType" value="unlimited" ref={register} /><span>無制限</span>
										</label>
										<FormErrorMessage errors={errors} name="issueLimit" message="全利用回数上限を1〜999999の範囲で入力してください" />
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">クーポン併用可否</h3>
										<label className="bl_label">
											<input type="radio" name="combineUse" value="disallow" ref={register} /><span>併用不可</span>
										</label>
										<label className="bl_label">
											<input type="radio" name="combineUse" value="allow" ref={register} /><span>併用可</span>
										</label>
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">利用金額・購入個数条件</h3>
										<label className="bl_label">
											<input type="radio" name="conditionType" value="nothing" ref={register} />指定しない
										</label>
										<br />
										<label className="bl_label">
											<p className="bl_inputFormWrap">
												<input type="radio" name="conditionType" value="price" ref={register} />金額条件<span className="mobile_block mobile_indent_26"><input className="el_inlineInputL" type="text" name="conditionPrice" onChange={nonNegative} ref={register({
													validate: (v) => getValues('conditionType') != 'price' || parseInt(v, 10) >= 1,
													min: 1,
													max: 999999999,
												})} />円(税込)以上購入の場合に利用可能</span>
											</p>
										</label>
										<br />
										<label className="bl_label">
											<p className="bl_inputFormWrap">
												<input type="radio" name="conditionType" value="amount" ref={register} />個数条件<span className="mobile_block mobile_indent_26"><input className="el_inlineInputL" type="text" name="conditionAmount" onChange={nonNegative} ref={register({
													validate: (v) => getValues('conditionType') != 'amount' || parseInt(v, 10) >= 1,
													min: 0,
													max: 999999999,
												})} />個以上購入の場合に利用可能</span>
											</p>
										</label>
										<FormErrorMessage errors={errors} name="conditionPrice" message="利用金額条件の額を1〜999999999の範囲で入力してください" />
										<FormErrorMessage errors={errors} name="conditionAmount" message="購入個数条件の個数を0〜999999999の範囲で入力してください" />
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">クーポン対象商品</h3>
										<label className="bl_label">
											<input type="radio" name="targetType" value="all" ref={register} /><span>指定しない（店内全商品）</span>
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="targetType" value="specify" ref={register({
												validate: {
													specifyTargetToShipping: () => validationSpecifyTargetToShipping(),
												},
											})} /><span>指定する</span>
										</label>
										<FormErrorMessage errors={errors} name="targetType" message={errors.targetType?.message} />
										<p className="fs_12">※対象商品の指定は5,000個までとなります。また、値引きプランが「送料無料」の場合は、対象商品の指定はできません。</p>
										<p className="fs_12">
											※対象商品を指定する場合、以下のいずれかを設定する必要があります<br/>
											・クーポン有効期間を14日以内の範囲で設定する<br/>
											・クーポンの全利用回数上限を1,000回以下に指定する<br/>
											・会員ランクを指定する
										</p>
										<div className="bl_productNumberUploader">
											<label className="el_btnTxt">
												<input type="file" name="couponTargetFile" onChange={handleChangeCouponTargetFile} />
												商品管理番号アップロード
											</label>
											<p>※半角 改行区切り csvまたはtxt形式 文字コード:Shift_JIS</p>
											<textarea className="el_textarea" name="couponTarget" ref={register} />
										</div>
										<FormErrorMessage errors={errors} name="couponTarget" message={errors.couponTarget?.message} />
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">会員ランク</h3>
										<label className="bl_label">
											<input type="radio" name="conditionRankType" value="nothing" ref={register} onChange={handleChangeConditionRankType} />指定しない
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="conditionRankType" value="specify" onChange={handleChangeConditionRankType} ref={register({
												validate: () => validationSpecifyTarget(),
											})} />指定する
										</label>
										<br />
										<label className="bl_label">
											<input className="el_checkMark" type="checkbox" disabled={conditionRankType == 'nothing'} name="conditionRankCond[]" value="5" ref={register} />ダイヤモンド
										</label>
										<label className="bl_label">
											<input className="el_checkMark" type="checkbox" disabled={conditionRankType == 'nothing'} name="conditionRankCond[]" value="4" ref={register} />プラチナ
										</label>
										<label className="bl_label">
											<input className="el_checkMark" type="checkbox" disabled={conditionRankType == 'nothing'} name="conditionRankCond[]" value="3" ref={register} />ゴールド
										</label>
										<label className="bl_label">
											<input className="el_checkMark" type="checkbox" disabled={conditionRankType == 'nothing'} name="conditionRankCond[]" value="2" ref={register} />シルバー
										</label>
										<label className="bl_label">
											<input className="el_checkMark" type="checkbox" disabled={conditionRankType == 'nothing'} name="conditionRankCond[]" value="1" ref={register} />レギュラー
										</label>
										<FormErrorMessage errors={errors} name="conditionRankType" message={errors.conditionRankType?.message} />
										{/* <p className="fs_12">
										※会員ランクを指定した場合、以下の条件は指定できません<br/>
										・店舗購入履歴<br/>
										・性別<br/>
										・年齢<br/>
										・誕生月<br/>
										・居住地
										</p> */}
									</div>
									{/* <div className="bl_panel_row">
										<h3 className="el_lv3Heading">店舗購入履歴</h3>
										<label className="bl_label">
											<input type="radio" name="purchaseHistoryCond" value="nothing" ref={register} />指定しない
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="purchaseHistoryCond" value="new" disabled={disabledCondition} ref={register} />購入履歴なし(新規)
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="purchaseHistoryCond" value="repeat" disabled={disabledCondition} ref={register} />購入履歴あり(リピーター)
										</label>
										<div className="bl_inputFormWrap ml_26">
											<span>対象期間</span>
											<span className='ml_16'>過去</span>
											<div className="el_selectWrap ml_8">
												<select name="dynamicPeriod" disabled={purchaseHistoryCond != 'repeat' || disabledCondition} ref={register({
													validate: (v) => disabledCondition || getValues('purchaseHistoryCond') != 'repeat' || v != ''
												})}>
													<option value=""></option>
													<option value="1">1</option>
													<option value="3">3</option>
													<option value="6">6</option>
													<option value="12">12</option>
													<option value="24">24</option>
												</select>
											</div><span className='ml_8'>ヶ月</span>
										</div>
										<br />
										<div className="bl_inputFormWrap ml_26">
											<span>購入回数</span>
											<div className="el_selectWrap ml_16">
												<select name="purchaseCountLower" onChange={handlePurchaseRangeChange} disabled={purchaseHistoryCond != 'repeat' || disabledCondition} ref={register({
													validate: {
														required: v => {
															if (!disabledCondition && getValues('purchaseHistoryCond') === 'repeat') {
																return v != '' || '購入回数(下限)を選択してください'
															}
														},
													}
												})}>
													<option value=""></option>
													{[...Array(10)].map((_, index) => (
														<option key={index + 1} value={index + 1}>
															{index + 1}
														</option>
													))}
												</select>
											</div><span className='ml_8'>回以上</span>
											<div className="el_selectWrap ml_8">
												<select name="purchaseCountUpper" onChange={handlePurchaseRangeChange} disabled={purchaseHistoryCond != 'repeat' || disabledCondition} ref={register({
													validate: {
														required: v => {
															if (!disabledCondition && getValues('purchaseHistoryCond') === 'repeat') {
																return v != '' || '購入回数(上限)を選択してください'
															}
														},
													}
												})}>
													<option value=""></option>
													{[...Array(10)].map((_, index) => (
														<option key={index + 1} value={index + 1}>
															{index + 1}
														</option>
													))}
												</select>
											</div><span className='ml_8'>回以下</span>
										</div>
										<FormErrorMessage errors={errors} name="dynamicPeriod" message="対象期間を選択してください" />
										<FormErrorMessage errors={errors} name="purchaseCountLower" />
										<FormErrorMessage errors={errors} name="purchaseCountUpper" />
										<FormErrorMessage errors={errors} name="purchaseCount" />
										<p className="fs_12">※店舗購入履歴は、ユーザーのクーポン獲得日から過去最大24ヶ月(730日)前までの購入履歴に基づいて設定できます。</p>
										<br />
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">性別</h3>
										<label className="bl_label">
											<input type="radio" name="genderCond" value="nothing" ref={register} /><span>指定しない</span>
										</label>
										<label className="bl_label">
											<input type="radio" name="genderCond" value="male" disabled={disabledCondition} ref={register} /><span>男性</span>
										</label>
										<label className="bl_label">
											<input type="radio" name="genderCond" value="female" disabled={disabledCondition} ref={register} /><span>女性</span>
										</label>
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">年齢</h3>
										<label className="bl_label">
											<input type="radio" name="ageRangeCond" value="nothing" ref={register} />指定しない
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="ageRangeCond" value="specify" disabled={disabledCondition} ref={register} />指定する
											<span className="mobile_block mobile_indent_26">
												<input className="el_inlineInputM ml_16" type="text" name="ageRangeCondLower" onChange={handleAgeRangeChange} disabled={ageRangeCond == 'nothing' || disabledCondition} ref={register({
													validate: (v) => disabledCondition || getValues('ageRangeCond') == 'nothing' || parseInt(v, 10) >= 1,
													min: 10,
													max: 100,
												})} />
												歳から</span>
											<span className="mobile_block mobile_indent_26">
												<input className="el_inlineInputM ml_8" type="text" name="ageRangeCondUpper" onChange={handleAgeRangeChange} disabled={ageRangeCond == 'nothing' || disabledCondition} ref={register({
													validate: (v) => disabledCondition || getValues('ageRangeCond') == 'nothing' || parseInt(v, 10) >= 1,
													min: 10,
													max: 100,
												})} />
												歳の場合クーポン獲得</span>
										</label>
										<FormErrorMessage errors={errors} name="ageRangeCondLower" message='年齢(上限)は10〜100の範囲で入力してください' />
										<FormErrorMessage errors={errors} name="ageRangeCondUpper" message='年齢(下限)は10〜100の範囲で入力してください' />
										<FormErrorMessage errors={errors} name="ageRangeCond" />
										<br />
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">誕生月</h3>
										<label className="bl_label">
											<input type="radio" name="birthMonthCond" value="nothing" ref={register} />指定しない
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="birthMonthCond" value="specify" disabled={disabledCondition} ref={register} />指定する
											<div className="el_selectWrap ml_16">
												<select name="birthMonth" disabled={birthMonthCond == 'nothing' || disabledCondition} ref={register({
													validate: {
														required: v => {
															if (!disabledCondition && getValues('birthMonthCond') === 'specify') {
																return v != '' || '誕生月を選択してください'
															}
														},
													}
												})}>
													<option value=""></option>
													{[...Array(12)].map((_, index) => (
														<option key={index + 1} value={index + 1}>
															{index + 1}
														</option>
													))}
												</select>
											</div><span className='ml_8'>月生まれの場合クーポン獲得</span>
										</label>
										<FormErrorMessage errors={errors} name="birthMonth" />
										<br />
									</div>
									<div className="bl_panel_row">
										<h3 className="el_lv3Heading">居住地</h3>
										<label className="bl_label">
											<input type="radio" name="multiPrefectureCond" value="nothing" ref={register} /><span>指定しない</span>
										</label>
										<br />
										<label className="bl_label">
											<input type="radio" name="multiPrefectureCond" value="specify" disabled={disabledCondition} ref={register({
												validate: () => validationPrefectureCond(),
											})} />指定する
										</label>
										<br />
										<div className='ml_16'>
											<label className="bl_label">
												<input className="el_checkMark ml_16" type="checkbox" name="prefectureAll" onChange={(event) => handlePrefectureSelectAll(event.target.checked)} disabled={multiPrefectureCond == 'nothing' || disabledCondition} value="checkAll" ref={register} />全選択／解除
											</label>

											<table className="bl_table bl_table__prefecture">
												<tbody className="bl_table_body">
													{
													regions.map((region, index) => {
														return (
															<tr key={index}>
																<td>
																	<label className="bl_label">
																		<input className="el_checkMark" type="checkbox" name={`region[${index}]`} onChange={(event) => handleRegionChange(index, event.target.checked)} disabled={multiPrefectureCond == 'nothing' || disabledCondition} value="checkAll"ref={register} />{region.name}
																	</label>
																</td>
																<td>
																	{region.list.map((prefecture, condIndex) => {
																		return (
																			<label key={condIndex} className="bl_label">
																				<input className="el_checkMark" type="checkbox" name="prefectureCond[]" onChange={() => handlePrefectureCondChange()} disabled={multiPrefectureCond == 'nothing' || disabledCondition} value={prefecture.value} ref={register({
																					validate: () => validationPrefectureCond(),
																				})} />{prefecture.name}
																			</label>
																		);
																	})}
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
											<FormErrorMessage errors={errors} name="prefectureCond[]" />
										</div>
									</div> */}
								</div>
							}
							<div className="el_largeBtnWrap">
								<button className={"el_largeBtn " + result} onClick={handleClickSave}>保存</button>
							</div>
							<Link className="el_backLink" to={`${state.params.basePath}/reward/reward?tab=${toUrlParam(rewardType)}`}>≪一覧へ戻る</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default RewardEditForm;