import { ItemGroup, NIL } from '@sasagase/types';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getGroups, postGroup, putGroup } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import GroupEditForm, { GroupEditFormProps } from './GroupEditForm';
import { sanitizeSku } from './GroupEditRow';
import { getItemGroupRequireDescList } from './GroupList';

type FormValues = GroupEditFormProps['initValues'];

interface GroupEditProps {}
type GroupEditParams = {
	groupId?: string;
	shopId?: string;
	flag?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function GroupEdit(props: GroupEditProps): React.ReactElement | null {
	const params = useParams<GroupEditParams>();
	const isNew = params.groupId == 'new';
	const isCopy = params.flag === 'copy';

	const navigate = useNavigate();
	const callAPI = useAPI();
	const [state] = useAppState();
	const [groups, setGroups] = React.useState<ItemGroup[] | null>(null);
	const [values, setValues] = React.useState<FormValues | null>(null);

	React.useEffect(() => {
		return callAPI(getGroups(params.shopId || ''), (err, result) => {
			if (err) {
				return;
			}

			const groups: ItemGroup[] = result.data.map((obj: Record<string, unknown>) => new ItemGroup(obj));
			setGroups(groups);

			const self = groups.find(group => group.id == params.groupId);
			const values = (isNew || !self) ? {} : {
				name: self.name,
				isAll: self.isAll ? 'true' : 'false',
				inputSkus: self.sku.join(','),
				skus: self.sku.join(','),
				inputExcludeSkus: self.excludeSku.join(','),
				excludeSkus: self.excludeSku.join(','),
				childGroupIds: self.childGroups.map(group => group.id),
				excludeChildGroupIds: self.excludeChildGroups.map(group => group.id),
			};
			if (isCopy) {
				values.name += ' のコピー';
			}
			setValues(values);
		});
	}, [params.shopId, params.groupId]);

	const handleSave = async (values: FormValues): Promise<boolean> => {
		if (!groups || !params.shopId || !params.groupId) {
			throw null;
		}

		const id = isNew || isCopy ? NIL : params.groupId;
		const map = new Map(groups.map(g => [g.id, g]));
		const group = new ItemGroup({
			id,
			name: values.name,
			isRef: false,
			isAll: values.isAll == 'true',
			sku: values.isAll == 'true' ? [] :
				String(values.skus).split(/\s*[,\t\n]\s*/).map(sku => sanitizeSku(sku.trim())).filter(Boolean),
			excludeSku: String(values.excludeSkus).split(/\s*[,\t\n]\s*/).map(sku => sanitizeSku(sku.trim())).filter(Boolean),
			childGroups: values.isAll == 'true' ? [] :
				values.childGroupIds?.map(id => map.get(id)),
			excludeChildGroups: values.excludeChildGroupIds?.map(id => map.get(id)),
		});
		
		if (isNew || isCopy) {
			const res = await callAPI(postGroup(params.shopId, group));
			const id = res.data;
			navigate(state.params.basePath + '/reward/group/' + id, {state: {newId: id}, replace: true});
			return true;
		} else {
			const res = await callAPI(putGroup(params.shopId, params.groupId, group));
			return Boolean(res.data);
		}
	};
	
	if (!groups || !values) {
		return null; // loading
	}

	const groupNames: Record<string, string> = {};
	for (const group of groups) {
		if (!group.hasDecendant(params.groupId)) {
			const desc = getItemGroupRequireDescList(group);
			groupNames[group.id] = `${group.name}【${desc.join(',')}】`;
		}
	}

	return <GroupEditForm initValues={values} groupNames={groupNames} onSave={handleSave} />;
}
export default GroupEdit;
