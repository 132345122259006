import * as React from 'react';

interface PasswordInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(function PasswordInput(props, ref) {
	const [pointerId, setPointerId] = React.useState<number | null>(null);
	const isActive = pointerId !== null;

	const handlePointerDown = (ev: React.PointerEvent) => {
		if (ev.button === 0) {
			setPointerId(ev.pointerId);
		}
	};
	const handlePointerUp = (ev: React.PointerEvent) => {
		if (ev.pointerId === pointerId) {
			setPointerId(null);
		}
	};
	const handleContextMenu = (ev: React.MouseEvent) => {
		if (ev.nativeEvent instanceof PointerEvent && ev.nativeEvent.pointerType === 'touch') {
			// タッチパネル長押しでメニュー表示されがちなので、それだけキャンセルする
			ev.preventDefault();
		}
	};
	return (
		<div className="bl_password">
			<input type={isActive ? 'text' : 'password'} {...props} ref={ref} />
			<span className={`el_showPassword ${isActive ? 'active' : ''}`}
				onPointerDown={handlePointerDown}
				onPointerUp={handlePointerUp}
				onPointerCancel={handlePointerUp}
				onPointerLeave={handlePointerUp}
				onContextMenu={handleContextMenu}
			></span>
		</div>
	);
});
export default PasswordInput;