import { RewardTargetsSummary } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import LinkGuide from '../../LinkGuide';

interface TargetSummaryProps {
	summary: RewardTargetsSummary;
	reviewCheckDate: number | null;
}

export function TargetSummary(props: TargetSummaryProps): React.ReactElement {
	const summary = props.summary;

	return (
		<div className="bl_panel bl_panel__ttlInv">
			<h2 className="el_lv2Heading">レビューデータ</h2>
			<LinkGuide guidePath='operation/review_privilege' link='レビューデータ' />
			<p className="fs_12 mb_24">最終レビューチェック日時 <time>{props.reviewCheckDate ? (dayjs(props.reviewCheckDate)).format("YYYY-MM-DD HH:mm") : ""}</time></p>
			<ul className="bl_reviewData">
				<li className="bl_reviewData_item">
					<h3 className="bl_reviewData_heading">対象商品数</h3>
					<p>{summary.targetProducts.toLocaleString()}<span className="el_unit">件</span></p>
				</li>
				<li className="bl_reviewData_item">
					<h3 className="bl_reviewData_heading">レビューあり</h3>
					<p>{summary.anyReviewed.toLocaleString()}<span className="el_unit">件({Number.isFinite(summary.anyReviewRate) ? (summary.anyReviewRate * 100).toFixed(1) : "-"}%)</span></p>
				</li>
				<li className="bl_reviewData_item">
					<h3 className="bl_reviewData_heading">片方レビュー </h3>
					<p>{summary.singleReviewed.toLocaleString()}<span className="el_unit">件</span></p>
				</li>
{/*
				<li className="bl_reviewData_item">
					<h3 className="bl_reviewData_heading">申込内容記入済み</h3>
					<p>{summary.done.toLocaleString()}<span className="el_unit">件({(summary.appliedRate * 100).toFixed(1)}%)</span></p>
				</li>
				<li className="bl_reviewData_item">
					<h3 className="bl_reviewData_heading">申込内容記入待ち</h3>
					<p>{summary.waitingAppling.toLocaleString()}<span className="el_unit">件</span></p>
				</li>
*/}
			</ul>
		</div>
	);
}
export default TargetSummary;