import * as React from 'react';
import { SubmitHandler, UseFormMethods } from 'react-hook-form';
import FormErrorMessage from '../FormErrorMessage';
import { CONTACT_SUBJECTS, FormValues } from './Contact';

interface ContactInputProps {
	methods: UseFormMethods<FormValues>;
	shopName?: string;
	onConfirm: SubmitHandler<any>;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ContactInput(props: ContactInputProps): React.ReactElement {
	const { methods, shopName, onConfirm } = props;
	const { register, errors }  = methods;

	return (
		<>
			<div className="bl_panel bl_panel__bt">
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">お問い合わせ店舗</h3>
					{shopName}
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">担当者名</h3>
					<input type="text" name="name"
						ref={register({ required: '担当者名を入力してください' })}
						/>
					<FormErrorMessage errors={errors} name="name" />
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">メールアドレス</h3>
					<input type="email" name="email"
						ref={register({
								required: 'メールアドレスを入力してください',
								pattern: {
									value: /\S+@\S+.\S+/,
									message: 'メールアドレスを入力してください'
								}
							})}
						/>
					<FormErrorMessage errors={errors} name="email" />
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">お問い合わせ種別</h3>
					<div className="el_selectWrap">
						<select name="subject" ref={register({ required: 'お問い合わせ種別を選択してください' })}>
							<option value="" disabled hidden>選択してください</option>
							{Object.entries(CONTACT_SUBJECTS).map(([k,v]) => 
								<option key={k} value={k}>{v}</option>
							)}
						</select>
					</div>
					<FormErrorMessage errors={errors} name="subject" />
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">お問い合わせ内容</h3>
					<textarea name="content" rows={5}
						ref={register({ required: 'お問い合わせ内容を入力してください' })}
						className={`${errors.content ? 'is_error' : ''}`} 
						></textarea>
					<FormErrorMessage errors={errors} name="content" />
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">注文番号</h3>
					<textarea name="orderNumber" rows={3} ref={register()}></textarea>
					<p className="txt_blue fs_12 mt_16">
						※注文の状況確認として、レビュー待ちや各種メールの送信、特典の申込み状況などを確認されたい場合は該当の注文番号を記載してください<br />
						※複数ある場合は改行区切り等で入力お願いします
					</p>
					<FormErrorMessage errors={errors} name="orderNumber" />
				</div>

				<div className="el_largeBtnWrap">
					<button className="el_largeBtn" type="button" onClick={onConfirm}>確認画面へ</button>
				</div>
			</div>
		</>
	);
}
export default ContactInput;