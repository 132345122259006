import { User } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { getUserByToken, putInviteUser } from "../../../api";
import { useAPI } from "../../../context";
import PasswordInput from '../../PasswordInput';
import FormErrorMessage from '../FormErrorMessage';

interface AccountRegistProps {}
type AccountRegistParams = {
	token?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AccountRegist(props: AccountRegistProps): React.ReactElement {
	const params = useParams<AccountRegistParams>();
	const { register, handleSubmit, errors, getValues }  = useForm();
	const callAPI = useAPI();
	const [inviteMail, setInviteMail] = React.useState('');
	const [redirect, setRedirect] = React.useState<string|null>(null);

	React.useEffect(() => {
		if (!params.token) {
			return;
		}

		return callAPI(getUserByToken(params.token), (err, result) => {
			if (err || !result.data) {
				setRedirect('/notfound');
				return;
			}

			const user = new User(result.data);
			if (user.mail === undefined || !user.hasEnableToken('review_login')) {
				setRedirect('/notfound');
				return;
			}
			if (user.setPass) {
				setRedirect(`/account/regist-completed/${params.token}`);
				return;
			}
			setInviteMail(user.mail);
		});
	}, [params.token]);

	if (redirect) {
		return <Navigate to={redirect} />;
	}

	const handleClickSubmit = handleSubmit(async (values) => {
		if (!params.token) {
			return;
		}
		
		try {
			const result = await callAPI(putInviteUser(params.token, values.name, values.password));
			if (result.data) {
				setRedirect(`/account/completed/regist`);
			}
		} catch (err) {
			return;
		}
	});

	return (
		<div className="ly_reissue">
			<div className="ly_reissue_inner">
				<h1 className="el_entryTitle"><img src="/assets/img/logo-w.svg" alt="ラクラクあつまレビュー" /></h1>
				<div className="bl_inviteForm">
					<div className="bl_loginFormRow">
						<p className="txt_blue">こんにちは。{inviteMail} アカウントが作成されました。続けるにはユーザー名を入力してパスワードを作成してください。</p>
					</div>
					<div className="bl_loginFormRow">
						<label className="bl_blueLabel">メールアドレス</label>
						<input type="text" readOnly={true} value={inviteMail} />
					</div>
					<div className="bl_loginFormRow">
						<label className="bl_blueLabel">ユーザー名</label>
						<input type="text" name="name" ref={register({ required: 'ユーザー名を入力してください。' })} />
						<FormErrorMessage errors={errors} name="name" />
					</div>
					<div className="bl_loginFormRow">
						<label className="bl_blueLabel">パスワード</label>
						<PasswordInput name="password"
							ref={register({
									required: 'パスワードを入力してください。',
									minLength: {
										value: 8,
										message: 'パスワードは8文字以上で入力してください。',
									},
								})}
							/>
						<FormErrorMessage errors={errors} name="password" />
					</div>
					<div className="bl_loginFormRow">
						<label className="bl_blueLabel">パスワード（確認用）</label>
						<PasswordInput name="password_confirm"
							ref={register({
									required: '確認用のパスワードを入力してください。',
									validate: input => input === getValues("password") || '入力したパスワードと一致していません。',
								})}
							/>
						<FormErrorMessage errors={errors} name="password_confirm" />
					</div>
					<button className="el_largeBtn el_largeBtn__fullWidth" type="button" onClick={handleClickSubmit}>アカウント登録</button>
				</div>
			</div>
		</div>
	);
}
export default AccountRegist;