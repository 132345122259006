import * as React from 'react';
import { Link } from 'react-router-dom';
import { GuideMenu } from './GuideMenu';

interface GuideSubProps {
	path: string;
	title: string;
	basePath: string;
	folderPath: string;
}
const GuideSub = (props: GuideSubProps): React.ReactElement => {

	return (
		<div className="bl_panel bl_panel__ttlInv">
			{props.path == 'use_guide/top' &&
				<GuideSubTop title={props.title} basePath={props.basePath} folderPath={props.folderPath} />
			}

			{props.path == 'registration_setting/use_flow' &&
				<GuideSubUseFlow title={props.title} basePath={props.basePath} folderPath={props.folderPath} />
			}
		</div>
	)
}

interface GuideSubPageProps {
	title: string;
	basePath: string;
	folderPath: string;
}

const GuideSubTop: React.FC<GuideSubPageProps> = (props) => {
	const guidePath = props.folderPath + "/registration_setting/use_flow";

	return (
		<>
			<h2 className="el_lv2Heading">{props.title}</h2>
			<div className="bl_guide">
				<h3 id="ラクラクあつまレビューとは">ラクラクあつまレビューとは</h3>
				<p>
					楽天ショップの運営会社が開発した楽天市場出店者様向け “レビュー施策” システムです。
					レビュー施策における煩雑な作業を自動化し、店舗様の扱う商材に合わせた施策を手間をかけずに打ち出すことができます。
				</p>
				<Link to={guidePath} className="banner"><img src="/assets/img/banner-first-guid.svg" width="384" height="94" alt="はじめてご利用の方はこちら" /></Link>
				<span style={{ display: 'block' }} className="txt_blue">初期設定からキャンペーン運用までの流れをご案内します</span>
				<GuideMenu basePath={props.basePath} folderPath={props.folderPath} />
			</div>
		</>
	);
}

const GuideSubUseFlow: React.FC<GuideSubPageProps> = (props) => {

	return (
		<>
			<h2 className="el_lv2Heading el_withIconHeading"><img src="/assets/img/q-beginner-s.svg" width="40" height="40" alt="" />はじめての<br />ラクラクあつまレビュー</h2>
			<div className="bl_guide bl_guideFlow">
				<span>ラクラクあつまレビューをはじめてご利用いただく方へ、初期設定からキャンペーン運用までの流れをご案内します。</span>
				<ol>
					<li>
						<h3><span>1</span>事前準備</h3>
						<ul>
							<li><Link to={props.folderPath + '/registration_setting/preparation#RMSのログイン情報を確認する'} className="el_btnWithMarkInv">RMSのログイン情報を確認する</Link></li>
							<li><Link to={props.folderPath + '/registration_setting/preparation#レビュー特典を準備する'} className="el_btnWithMarkInv">レビュー特典を準備する</Link></li>
						</ul>
						<span>初期設定</span>
					</li>
					<li>
						<h3><span>2</span>楽天との連携情報を登録する</h3>
						<ul>
							<li><Link to={props.folderPath + '/registration_setting/rakuten_system_setting#各種設定_2'} className="el_btnWithMarkInv">API利用情報を登録する</Link></li>
							<li><Link to={props.folderPath + '/registration_setting/rakuten_system_setting#各種設定_4'} className="el_btnWithMarkInv">楽天あんしんメルアドサービス情報を登録する</Link></li>
							<li><Link to={props.folderPath + '/registration_setting/rakuten_system_setting#各種設定_7'} className="el_btnWithMarkInv">楽天RMSへのログイン情報を登録する</Link></li>
						</ul>
					</li>
					<li>
						<h3><span>3</span>店舗情報を登録する</h3>
						<ul>
							<li><Link to={props.folderPath + '/registration_setting/shop_setting'} className="el_btnWithMarkInv">店舗情報を登録する</Link></li>
						</ul>
					</li>
					<li>
						<h3><span>4</span>メール情報を登録する</h3>
						<ul>
							<li><Link to={props.folderPath + '/registration_setting/mail_setting#メール送信設定'} className="el_btnWithMarkInv">メール送信情報を登録する</Link></li>
							<li><Link to={props.folderPath + '/registration_setting/mail_setting#送信メールテンプレート'} className="el_btnWithMarkInv">送信メールテンプレート情報を登録する</Link></li>
							<li><Link to={props.folderPath + '/registration_setting/mail_setting#特典対象外商品用メール'} className="el_btnWithMarkInv">特典対象外商品用メール情報を登録する</Link></li>
						</ul>
						<span>レビュー施策の設定</span>
					</li>
					<li>
						<h3><span>5</span>レビュー特典を登録する</h3>
						<ul>
							<li><Link to={props.folderPath + '/registration_setting/from_review_privilege_up_to_campaign_register#プレゼント品の登録方法'} className="el_btnWithMarkInv">プレゼント品を登録する</Link></li>
							<li><Link to={props.folderPath + '/registration_setting/from_review_privilege_up_to_campaign_register#クーポンの登録方法'} className="el_btnWithMarkInv">クーポンを登録する</Link></li>
						</ul>
					</li>
					<li>
						<h3><span>6</span>特典対象商品を登録する</h3>
						<ul>
							<li><Link to={props.folderPath + '/registration_setting/from_review_privilege_up_to_campaign_register#対象商品グループ登録方法'} className="el_btnWithMarkInv">特典対象商品の管理番号を登録する</Link></li>
						</ul>
					</li>
					<li>
						<h3><span>7</span>キャンペーンを登録する</h3>
						<ul>
							<li><Link to={props.folderPath + '/registration_setting/from_review_privilege_up_to_campaign_register#キャンペーン登録方法'} className="el_btnWithMarkInv">キャンペーンを登録する</Link></li>
							{/* <li><Link to={props.folderPath + '/registration_setting/from_review_privilege_up_to_campaign_register#'} className="el_btnWithMarkInv">キャンペーンの設定パターンを確認する</Link></li> */}
						</ul>
						<span>キャンペーン運用スタート</span>
					</li>
					<li>
						<h3><span>8</span>キャンペーンスタート後の流れ</h3>
						<ul>
							<li><Link to={props.folderPath + '/operation/review_privilege#レビュー待ち状況'} className="el_btnWithMarkInv">レビュー待ち状況を確認する</Link></li>
							<li><Link to={props.folderPath + '/operation/review_privilege#特典の送り先情報取得について'} className="el_btnWithMarkInv">プレゼント品を発送する</Link></li>
							{/* <li><Link to={props.folderPath + '/registration_setting/'} className="el_btnWithMarkInv">API利用情報の更新を反映する</Link></li> */}
							{/* <li><Link to={props.folderPath + '/registration_setting/'} className="el_btnWithMarkInv">楽天RMSへのログイン情報の更新を反映する</Link></li> */}
						</ul>
					</li>
				</ol>
			</div>
		</>
	);
}
export default GuideSub;