import parseReviewCSV from '@sasagase/parse-review-csv';
import { Review } from '@sasagase/types';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { postReviews } from '../../../../api';
import { useAPI } from '../../../../context';
import { FileSelector } from '../../../FileSelector';
import LinkGuide from '../../LinkGuide';

async function filesToReviews(files: FileList): Promise<Review[]> {
	const reviews: Review[] = [];
	for (const file of Array.from(files)) {
		try {
			const buff = await file.arrayBuffer();
			const fileReviews = await parseReviewCSV(buff);
			reviews.push(...fileReviews);
		} catch (err) {
			alert(`CSVの読込みに失敗しました。(${file.name})\n\n${err.message}`);
			throw err;
		}
	}
	return reviews;
}

interface ReviewUploadProps {
	onUploaded?: () => void;
}
type ReviewUploadParams = {
	shopId: string;
}

export function ReviewUpload(props: ReviewUploadProps): React.ReactElement {
	const callAPI = useAPI();
	const params = useParams<ReviewUploadParams>();

	const handleSelectFiles = async (files: FileList) => {
		const reviews = await filesToReviews(files);
		
		try {
			const result = await callAPI(postReviews(params.shopId ?? '', reviews));
			alert(`CSVのアップロードが完了しました。\n\n新規レビュー ${result.data.newReviews}件\n特典対象 ${result.data.targets}件`);
			if (result.data.targets <= 0) {
				return;
			}

			if (props.onUploaded) {
				props.onUploaded();
			}
		} catch (error) {
			alert(`アップロードしたCSVファイルが破損しています。`)
		}

	};

	return (
		<div className="bl_panel bl_panel__ttlInv">
			<h2 className="el_lv2Heading">レビューCSV アップロード（手動）</h2>
			<LinkGuide guidePath='operation/review_privilege' link='レビューCSVアップロード' />
			<FileSelector accept='.csv' onSelectFiles={handleSelectFiles}>
				<div className="el_fileDrop">
					<p>CSVのアップロードはこちらへ<br className="show_mobile" />ファイルをドロップ</p>
				</div>
			</FileSelector>
		</div>
	);
}
export default ReviewUpload;