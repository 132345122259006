import * as React from 'react';
import { contextListSelector } from './ListSelectorContext';

interface AllSelectorProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const AllSelector: React.FC<AllSelectorProps> = (props) => {
	const context = React.useContext(contextListSelector);
	const checkAll = React.useRef<null|HTMLInputElement>(null);

	React.useEffect(() => {
		if (!checkAll.current || !context) {
			return;
		}
		checkAll.current.checked = context.checkState.checked;
		checkAll.current.indeterminate = context.checkState.indeterminate;
	}, [checkAll.current, context?.checkState]);

	if (!context) {
		return null;
	}

	const { handleChangeAll } = context;

	return (
		<input {...props} type="checkbox" name='checkAll' ref={checkAll} onChange={handleChangeAll} />
	);
};
export default AllSelector;