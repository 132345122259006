import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { date, define, dynamic, enums, is, number, optional, size, string, type, union } from 'superstruct';
import FormErrorMessage from '../../FormErrorMessage';
import DateInput, { DateFields, toDate } from './DateInput';
import DaysInput, { DaysField } from './DaysInput';

const DEADLINEDAYS_MIN = 14;
const DEADLINEDAYS_MAX = 700;

type cutCheck = 'date' | 'days' | 'never';
type cutCheckObj = {
	"isCutDate": boolean,
	"isCutDays": boolean,
}
export interface CampaignEditFormStep1FormValue {
	priority: number;

	name: string;
	begin: DateFields;
	end: DateFields;
	isNeverEnd: 'true' | 'false';
	deadlineDate: DateFields;
	deadlineDays: DaysField;
	isCutCheck: cutCheck;
	reviewRequired: 'item' | 'shop' | 'both' | 'any';
	canManyRewards: 'true' | 'false';

	inProgressDate?: number;
	waitingReviewDate?: number;
	finishedDate?: number;
	cancelledDate?: number;
}


export const toCutCheck = (data: cutCheckObj): cutCheck => {
	if (data.isCutDate) {
		return 'date';
	} else if (data.isCutDays) {
		return 'days';
	} else {
		return 'never';
	}
}

function requiredString(val: unknown): boolean {
	return is(val, size(string(), 1, Infinity));
}
const dateValidator = (targetDate: Date, compareResult: boolean, ErrorMessage: string) => {
	if (targetDate.getTime()) {
		return compareResult || ErrorMessage;
	} else {
		return '有効な日付を入力してください';
	}
}

export const CampaignEditFormStep1Struct = dynamic((values: any) => {
	const isNeverEnd = values.isNeverEnd == 'true';
	const isDeadlineDate = values.isCutCheck == 'date';
	const isDeadlineDays = values.isCutCheck == 'days';

	const begin = toDate(values.begin);
	const end = toDate(values.end);
	const deadlineDate = toDate(values.deadlineDate);
	const deadlineDays = Number(values.deadlineDays);

	const anyDateField = union([string(), date(), number()]);
	const anyDaysField = union([string(), number()]);

	const isStarted = Boolean(values.inProgressDate);
	const isFinishedCamp = Boolean(values.waitingReviewDate);
	const isFinishedAll = Boolean(values.finishedDate || values.cancelledDate);

	return type({
		priority: number(),

		name: define<string>('name', (val) => requiredString(val) || 'キャンペーン名を入力してください'),
		begin: isStarted ? anyDateField :
			define<DateFields>('begin', () =>
				dateValidator(begin, begin > new Date(), '現在以降の日時を指定してください')
			),
		end: isFinishedCamp || isNeverEnd ? anyDateField :
			define<DateFields>('end', () => {
				const endValid = dateValidator(end, end >= begin, '開始日以降の日時を指定してください')
				// キャンペーン実施中で終了日時に過去の日時は不可
				const futureDateValid = isStarted && !isFinishedCamp ? dateValidator(end, end > new Date(), '現在日以降の日時を指定してください') : true;
				return endValid === true ? futureDateValid === true ? true : futureDateValid : endValid;
			}),
		deadlineDate: isFinishedAll || !isDeadlineDate ? anyDateField :
			define<DateFields>('deadline', () => {
				const endValid = dateValidator(deadlineDate, deadlineDate >= end, 'キャンペーン終了以降の日時を指定してください');
				// キャンペーン実施中で終了日時に過去の日時は不可
				const futureDateValid = isStarted && !isFinishedCamp ? dateValidator(deadlineDate, deadlineDate > new Date(), '現在日以降の日時を指定してください') : true;
				return endValid === true ? futureDateValid === true ? true : futureDateValid : endValid;
			}
			),
		deadlineDays: isFinishedAll || !isDeadlineDays ? anyDaysField :
			define<DaysField>('deadline', () =>
				!deadlineDays ? "有効な日数を入力してください" :
				deadlineDays < DEADLINEDAYS_MIN ? '最小指定日数よりも短い期間は指定できません。14日以上を指定してください。' :
				deadlineDays > DEADLINEDAYS_MAX ? '最大指定日数よりも長い期間は指定できません。700日以下を指定してください。' : true
			),
		isNeverEnd: enums(['true', 'false']),
		isCutCheck: enums(['date', 'days', 'never']),
		reviewRequired: enums(['item', 'shop', 'both', 'any']),
		canManyRewards: enums(['true', 'false']),

		inProgressDate: optional(number()),
		waitingReviewDate: optional(number()),
		finishedDate: optional(number()),
		cancelledDate: optional(number()),
	});
});

interface CampaignEditFormStep1Props {
	methods: UseFormMethods<CampaignEditFormStep1FormValue>;
}

export function CampaignEditFormStep1(props: CampaignEditFormStep1Props): React.ReactElement {
	const { register, watch, errors } = props.methods;

	const isNeverEnd = watch('isNeverEnd') == 'true';
	const isCutDate = watch('isCutCheck') == 'date';
	const isCutDays = watch('isCutCheck') == 'days';
	const isStarted = Boolean(watch('inProgressDate'));
	const isFinishedCamp = Boolean(watch('waitingReviewDate'));
	const isFinishedAll = Boolean(watch('finishedDate') || watch('cancelledDate'));

	return (
		<div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">キャンペーン名</h3>
				<input type="text" name="name" ref={register} />
				<FormErrorMessage errors={errors} name="name" />
			</div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">キャンペーン実施期間</h3>
				<label className="bl_label">
					<input type="radio" name="isNeverEnd" value="false" ref={register} disabled={isFinishedCamp} />
					<span>期限を設定する</span>
				</label>
				<br />
				{!isNeverEnd &&
					<>
						<div className="bl_inputFormWrap ml_26">
							<span>開始</span>
							<DateInput name="begin" methods={props.methods} seconds="00" minDate={new Date()} disabled={isStarted} />
						</div>

						<FormErrorMessage errors={errors} name="begin" />

						<div className="bl_inputFormWrap ml_26">
							<span>終了</span>
							<DateInput name="end" methods={props.methods} seconds="59" minDate={new Date()} disabled={isFinishedCamp} />
						</div>
						<FormErrorMessage errors={errors} name="end" />
					</>
				}
				<label className="bl_label">
					<input type="radio" name="isNeverEnd" value="true" ref={register} disabled={isFinishedCamp} />
					<span>無期限</span>
				</label>
				{isNeverEnd &&
					<>
						<div className="bl_inputFormWrap ml_26">
							<span>開始</span>
							<DateInput name="begin" methods={props.methods} seconds="00" minDate={new Date()} disabled={isStarted} />
						</div>
						<FormErrorMessage errors={errors} name="begin" />
					</>
				}
			</div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">レビュー受付期間</h3>
				<label className="bl_label">
					<input type="radio" name="isCutCheck" value="date" ref={register} disabled={isFinishedAll} />
					<span>期限を設定する（対象日時まで）</span>
				</label><br />
				{isCutDate &&
					<>
						<div className="bl_inputFormWrap ml_26">
							<span>終了</span>
							<DateInput name="deadlineDate" methods={props.methods} seconds="59" minDate={new Date()} disabled={isFinishedAll} />
						</div>
						<FormErrorMessage errors={errors} name="deadlineDate" />
					</>
				}
				<label className="bl_label">
					<input type="radio" name="isCutCheck" value="days" ref={register} disabled={isFinishedAll} />
					<span>期限を設定する（商品発送後の指定日数期間）</span>
				</label><br />
				{isCutDays &&
					<>
						<div className="bl_inputFormWrap ml_26">
							<span>商品発送後 </span>
							<DaysInput name="deadlineDays" methods={props.methods} maxDate={DEADLINEDAYS_MAX} minDate={0} disabled={isFinishedAll} />
						</div>
						<div className="bl_inputFormWrap ml_26 txt_blue fs_12">※指定日数は14〜700日の範囲で入力してください。</div>
						<FormErrorMessage errors={errors} name="deadlineDays" />
					</>
				}
				<label className="bl_label">
					<input type="radio" name="isCutCheck" value="never" ref={register} disabled={isFinishedAll} />
					<span>無期限</span>
				</label>
			</div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">対象条件</h3>
				<label className="bl_label">
					<input type="radio" name="reviewRequired" value="item" ref={register} /><span>商品レビューを記入</span>
				</label>
				<br />
				<label className="bl_label">
					<input type="radio" name="reviewRequired" value="shop" ref={register} /><span>ショップレビューを記入</span>
				</label>
				<br />
				<label className="bl_label">
					<input type="radio" name="reviewRequired" value="both" ref={register} /><span>商品・ショップレビューの両方を記入</span>
				</label>
				<br />
				<label className="bl_label">
					<input type="radio" name="reviewRequired" value="any" ref={register} /><span>商品・ショップレビューのどちらかを記入</span>
				</label>
			</div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">特典の取扱い</h3>
				<p className="mb_16">同一の特典対象商品を同一のお客様により同日に2つ以上購入された場合</p>
				<label className="bl_label">
					<input type="radio" name="canManyRewards" value="false" ref={register} /><span>購入分のうち１つのみを特典対象とする</span>
				</label>
				<br />
				<label className="bl_label">
					<input type="radio" name="canManyRewards" value="true" ref={register} /><span>購入分すべてを特典対象とする</span>
				</label>
			</div>
		</div>
	);
}
export default CampaignEditFormStep1;
