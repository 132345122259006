import { CCType, Shop, ccTypes } from '@sasagase/types';
import * as React from 'react';
import { checkLogin, getLicenseExpiryDate, getShop, putShop, sendRakutenTest } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../lib/usePrompt';
import RakutenSettingCheckLogin from './RakutenSettingCheckLogin';
import RakutenSettingRmsForm from './RakutenSettingRmsForm';
import RakutenSettingShopAccountForm from './RakutenSettingShopAccountForm';
import RakutenSettingSmtpForm from './RakutenSettingSmtpForm';
import RakutenSettingSmtpTestMail from './RakutenSettingSmtpTestMail';
import RakutenSettingUserAccountForm from './RakutenSettingUserAccountForm';

interface RakutenSettingProps {}

const rmsInitValues = {
	serviceSecret: '',
	licenseKey: '',
	licenseExpiryDate: 0
};
const rmsServiceSquareInitValues = {
	serviceSquareLicenseKey: '',
	serviceSquareLicenseExpiryDate: 0
};
function getRmsInitValues(useServiceSquare = false) {
	return useServiceSquare ? rmsServiceSquareInitValues : rmsInitValues;
}
const smtpInitValues = {
	mail: '',
	mailSenderName: '',
	ccType: ccTypes[0],
	mailCcBcc: '',
	smtpAuth: '',
	smtpPass: ''
};
const shopAccountInitValues = {
	shopAccountId: '',
	shopAccountPass: '',
};
const userAccountInitValues = {
	userAccountId: '',
	userAccountPass: '',
};
type RmsFormValues = typeof rmsInitValues;
type SmtpFormValues = {
	mail: string;
	mailSenderName: string;
	ccType: CCType,
	mailCcBcc: string;
	smtpAuth: string;
	smtpPass?: string;
};
type ShopAccountFormValues = {
	shopAccountId: string;
	shopAccountPass?: string;
};
type UserAccountFormValues = {
	userAccountId: string;
	userAccountPass?: string;
};

function toRmsValues(shop: Shop): RmsFormValues {
	return {
		...rmsInitValues,
		serviceSecret: shop.serviceSecret ?? '',
		licenseKey: shop.enabledLicenseKey ?? '',
		licenseExpiryDate: shop.enabledLicenseExpiryDate ?? 0,
	};
}
function toRmsUpdateValues(shop: Shop): Record<string, string|number> {
	return shop.useServiceSquare
		? {
			serviceSquareLicenseKey: shop.serviceSquareLicenseKey ?? '',
			serviceSquareLicenseExpiryDate: shop.serviceSquareLicenseExpiryDate ?? 0,
		}
		: {
			serviceSecret: shop.serviceSecret ?? '',
			licenseKey: shop.licenseKey ?? '',
			licenseExpiryDate: shop.licenseExpiryDate ?? 0,
		};
}
function toSmtpValues(shop: Shop, input = false): SmtpFormValues  {
	const required = {
		mail: shop.mail ?? '',
		mailSenderName: shop.mailSenderName ?? '',
		ccType: shop.ccType ?? ccTypes[0],
		mailCcBcc: shop.mailCcBcc ?? '',
		smtpAuth: shop.smtpAuth ?? '',
	};
	return input
		? required
		: {
			...required,
			smtpPass: shop.smtpPass || undefined
		};
}
function toShopAccountValues(shop: Shop, input = false): ShopAccountFormValues {
	const required = {
		shopAccountId: shop.shopAccountId ?? '',
	};
	return input
		? required
		: {
			...required,
			shopAccountPass: shop.shopAccountPass || undefined,
		};
}
function toUserAccountValues(shop: Shop, input = false): UserAccountFormValues {
	const required = {
		userAccountId: shop.userAccountId ?? '',
	};
	return input
		? required
		: {
			...required,
			userAccountPass: shop.userAccountPass || undefined,
		};
}

const initFormIsDirtys = {
	rms: false,
	smtp: false,
	shopAccount: false,
	userAccount: false
};
export type FormIsDirtys = typeof initFormIsDirtys;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function RakutenSetting(props: RakutenSettingProps): React.ReactElement|null {
	const callAPI = useAPI();
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const [baseShop, setBaseShop] = React.useState<Shop>();
	const [rmsValues, setRmsValues] = React.useState<RmsFormValues>(rmsInitValues);
	const [smtpInputValues, setSmtpInputValues] = React.useState<SmtpFormValues>(smtpInitValues);
	const [smtpValues, setSmtpValues] = React.useState<SmtpFormValues>(smtpInitValues);
	const [shopAccountInputValues, setShopAccountInputValues] = React.useState<ShopAccountFormValues>(shopAccountInitValues);
	const [shopAccountValues, setShopAccountValues] = React.useState<ShopAccountFormValues>(shopAccountInitValues);
	const [userAccountInputValues, setUserAccountInputValues] = React.useState<UserAccountFormValues>(userAccountInitValues);
	const [userAccountValues, setUserAccountValues] = React.useState<UserAccountFormValues>(userAccountInitValues);
	const [loading, setLoading] = React.useState(true);
	const [formIsDirtys, setFormIsDirtys] = React.useState<FormIsDirtys>(initFormIsDirtys);
	usePrompt(PROMPT_CONFIRM_MESSAGE, Object.values(formIsDirtys).some(v => v));

	React.useEffect(() => {
		return callAPI(getShop(shopId), (err, result) => {
			if (err) {
				return;
			}
			const shop = new Shop(result.data);
			setBaseShop(shop);
			setRmsValues(toRmsValues(shop));
			setSmtpInputValues(toSmtpValues(shop, true));
			setSmtpValues(toSmtpValues(shop));
			setShopAccountInputValues(toShopAccountValues(shop, true));
			setShopAccountValues(toShopAccountValues(shop));
			setUserAccountInputValues(toUserAccountValues(shop, true));
			setUserAccountValues(toUserAccountValues(shop));
			setLoading(false);
		});
	}, [shopId]);

	const handleSaveRms = async(values: Record<string, string>) => {
		try {
			const rmsValues = baseShop?.useServiceSquare
				? { serviceSquareLicenseKey: values.licenseKey }
				: values;
			const shop = new Shop({
				id: shopId,
				...baseShop,
				...(getRmsInitValues(baseShop?.useServiceSquare)),
				...rmsValues
			});
			const res = await callAPI(putShop(shopId, toRmsUpdateValues(shop)));
			if (res) {
				let shopDummy = new Shop(shop.toJSON());	// 表示整形用
				setRmsValues(toRmsValues(shopDummy));

				const resGetExpiryDate = await callAPI(getLicenseExpiryDate(shopId));
				if (resGetExpiryDate) {
					const expiryDate = resGetExpiryDate.data[shopId] ?? 0;
					shopDummy = new Shop({
						...shop.toJSON(),
						licenseExpiryDate: baseShop?.useServiceSquare ? undefined : expiryDate,
						serviceSquareLicenseExpiryDate: baseShop?.useServiceSquare ? expiryDate : undefined,
					});
					setRmsValues(toRmsValues(shopDummy));
				}
			}
			return Boolean(res.data);
		} catch (err) {
			return false;
		}
	}

	const handleInitRms = async() => {
		try {
			const res = await callAPI(putShop(shopId, getRmsInitValues(baseShop?.useServiceSquare)));
			setRmsValues(rmsInitValues);
			return Boolean(res.data);
		} catch (err) {
			return false;
		}
	}

	const handleSaveSmtp = async(values: Record<string, string>) => {
		try {
			const shop = new Shop({
				id: shopId,
				...baseShop,
				...values
			});
			const updateValues = toSmtpValues(shop);
			const res = await callAPI(putShop(shopId, updateValues));
			const shopDummy = new Shop({	// 表示整形用
				...shop.toJSON(),
				smtpPass: updateValues.smtpPass ?? baseShop?.smtpPass,
			});
			setSmtpInputValues(toSmtpValues(shopDummy, true));
			setSmtpValues(toSmtpValues(shopDummy));
			return Boolean(res.data);
		} catch (err) {
			return false;
		}
	}

	const handleInitSmtp = async() => {
		try {
			const res = await callAPI(putShop(shopId, smtpInitValues));
			setSmtpInputValues(smtpInitValues);
			setSmtpValues(smtpInitValues);
			return Boolean(res.data);
		} catch (err) {
			return false;
		}
	}

	const handleTestMail = async(mail: string) => {
		try {
			const res = await callAPI(sendRakutenTest(shopId, mail));
			return res.data.result ? 
					`「${res.data.subject}」という件名でテストメールを ${mail} 宛に送信しました` :
					`テストメールの送信に失敗しました`;
		} catch (err) {
			console.log(err);
			return 'テストメールの送信に失敗しました';
		}
	}

	const handleSaveShopAccount = async (values: Record<string, string>) => {
		try {
			const shop = new Shop({
				id: shopId,
				...baseShop,
				...values
			});
			const updateValues = toShopAccountValues(shop);
			const res = await callAPI(putShop(shopId, updateValues));
			const shopDummy = new Shop({	// 表示整形用
				...shop.toJSON(),
				shopAccountPass: updateValues.shopAccountPass ?? baseShop?.shopAccountPass,
			});
			setShopAccountInputValues(toShopAccountValues(shopDummy, true));
			setShopAccountValues(toShopAccountValues(shopDummy));
			return Boolean(res.data);
		} catch (err) {
			return false;
		}
	}

	const handleInitShopAccount = async() => {
		try {
			const res = await callAPI(putShop(shopId, shopAccountInitValues));
			setShopAccountInputValues(shopAccountInitValues);
			setShopAccountValues(shopAccountInitValues);
			return Boolean(res.data);
		} catch (err) {
			return false;
		}
	}

	const handleSaveUserAccount = async (values: Record<string, string>) => {
		try {
			const shop = new Shop({
				id: shopId,
				...baseShop,
				...values
			});
			const updateValues = toUserAccountValues(shop);
			const res = await callAPI(putShop(shopId, updateValues));
			const shopDummy = new Shop({	// 表示整形用
				...shop.toJSON(),
				userAccountPass: updateValues.userAccountPass ?? baseShop?.userAccountPass,
			});
			setUserAccountInputValues(toUserAccountValues(shopDummy, true));
			setUserAccountValues(toUserAccountValues(shopDummy));
			return Boolean(res.data);
		} catch (err) {
			return false;
		}
	}

	const handleInitUserAccount = async() => {
		try {
			const res = await callAPI(putShop(shopId, userAccountInitValues));
			setUserAccountInputValues(userAccountInitValues);
			setUserAccountValues(userAccountInitValues);
			return Boolean(res.data);
		} catch (err) {
			return false;
		}
	}

	const handleCheckLogin = async() => {
		try {
			const res = await callAPI(checkLogin(shopId));
			return res.data;
		} catch (err) {
			return err;
		}
	}

	if (loading) {
		return null; // loading
	}
	return (
		<div className="ly_main__rakutenSetting">
			<h1 className="el_pageTtl">楽天システム設定</h1>
			<p className="el_pageDesc">楽天システム情報の確認・編集を行うことができます。また、各情報の初期化ができます。</p>
			<div className="bl_row">
				<RakutenSettingRmsForm
					initValues={rmsValues}
					setValues={rmsValues}
					onSave={handleSaveRms}
					onInit={handleInitRms}
					setFormIsDirtys={setFormIsDirtys}
					/>
				<div className="bl_col bl_col__4">
					<div className="bl_panel bl_panel__ttlInv">
						<h2 className="el_lv2Heading">楽天あんしんメルアドサーバ<span style={{ fontSize: '0.9em' }}>(SMTP AUTH)</span>情報</h2>
						<p className="el_rakutenSettingDesc">楽天あんしんメルアドサーバ情報を入力してください。</p>
						<RakutenSettingSmtpForm
							initValues={smtpInputValues}
							setValues={smtpValues}
							onSave={handleSaveSmtp}
							onInit={handleInitSmtp}
							setFormIsDirtys={setFormIsDirtys}
							/>
						<RakutenSettingSmtpTestMail
							enable={Boolean(smtpInputValues.mail)}
							onClick={handleTestMail}
							/>
					</div>
				</div>
				<div className="bl_col bl_col__4">
					<div className="bl_panel bl_panel__ttlInv">
						<h2 className="el_lv2Heading">楽天アカウント情報</h2>
						<p className="el_rakutenSettingNote">※以下の「楽天ショップアカウント情報」・「楽天ユーザーアカウント情報」を設定いただくと「レビューCSV アップロード」が不要です。</p>
						<RakutenSettingShopAccountForm
							initValues={shopAccountInputValues}
							setValues={shopAccountValues}
							onSave={handleSaveShopAccount}
							onInit={handleInitShopAccount}
							setFormIsDirtys={setFormIsDirtys}
							/>
						<RakutenSettingUserAccountForm
							initValues={userAccountInputValues}
							setValues={userAccountValues}
							onSave={handleSaveUserAccount}
							onInit={handleInitUserAccount}
							setFormIsDirtys={setFormIsDirtys}
							/>
						<RakutenSettingCheckLogin
							onClick={handleCheckLogin}
							/>
					</div>
				</div>
			</div>
		</div>
	);
}
export default RakutenSetting;