import * as React from 'react';
import { Link } from 'react-router-dom';
import { NOT_LOGIN_PAGE_TITLES, PAGE_TITLES } from '../Side';
import { GUIDE_TITLES } from './Guide';

export const modifyGuideAnchor = (basePath: string ) => {
	return function modify({ children, href }: { children: any, href: any }): React.ReactElement {
		const guidBasePath = basePath + '/guide';
		if (href.toString().startsWith('http')) {
			// 外部リンク
			return <span className='el_externalLink'><a href={href} target="_blank" rel="noopener noreferrer">{children}</a></span>;
		} else if (href.toString().startsWith('#')) {
			// ページ内リンク
			return <span><a href={href}>{children}</a></span>;
		} else {
			const url = new URL(href, 'http://localhost');	// `/operation/review_privilege#レビューCSVアップロード` などの指定の対応
			if (GUIDE_TITLES.has(url.pathname.slice(1))) {
				// 利用ガイド別ページへのリンク
				return <span><Link to={guidBasePath + href}>{children}</Link></span>;
			} else if (PAGE_TITLES.has(url.pathname)) {
				// 利用ガイド以外のラクラクあつまレビュー内のページへのリンク
				return <span className='el_externalLink'><a href={basePath + href} target="_blank" rel="noreferrer">{children}</a></span>;
			} else if (NOT_LOGIN_PAGE_TITLES.has(url.pathname)) {
				// 未ログイン時の各ページへのリンク
				return <span className='el_externalLink'><a href={href} target="_blank" rel="noreferrer">{children}</a></span>;
			}
		}
		return <span><Link to={href}>{children}</Link></span>;
	};
};
