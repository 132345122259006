import { MailTemplate } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { deleteTemplate, getTemplateFilter, getTemplates, putTemplateFavorite } from '../../../api';
import { useAPI, useAppState } from '../../../context';

interface TemplateListProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TemplateList(props: TemplateListProps): React.ReactElement {
	const [state] = useAppState();
	const callAPI = useAPI();
	const [templates, setTemplates] = React.useState<null|MailTemplate[]>(null);
	const [searchForm, setSearchFrom] = React.useState("");
	const { register, setValue, getValues, reset } = useForm();
	const checkAll = React.useRef<null|HTMLInputElement>(null);

	React.useEffect(() => {
		if (!state.params.shopId || templates) {
			return;
		}
		return callAPI(getTemplates(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const templates = result.data.map((obj: Record<string, unknown>) => new MailTemplate(obj));
			setTemplates(templates);
		});
	}, [state.params.shopId, templates]);

	React.useEffect(() => {
		const docClickHandler = (ev: any) => {
			if (!(ev.target instanceof HTMLElement) || ev.target.closest("th.bl_table_filterHead")) {
				return;
			}
			setSearchFrom("");
		};
		document.body.addEventListener('click', docClickHandler);
		return () => document.body.removeEventListener('click', docClickHandler);
	}, []);

	const handleChangeCheckAll = () => {
		if (!checkAll.current || !templates) {
			return;
		}
		setValue('check', Array(templates.length).fill(checkAll.current.checked));
	};
	const handleChangeCheck = () => {
		if (!checkAll.current) {
			return;
		}
		// React hook formの仕様上はできるはずだが、なぜか getValues('check') だと取得できない
		const checks = getValues().check;
		const isAll = Object.values(checks).every(Boolean);
		const isPartial = !isAll && Object.values(checks).some(Boolean);
		checkAll.current.checked = isAll;
		checkAll.current.indeterminate = isPartial;
	};

	const handleClickSearchForm = async () => {
		setSearchFrom(searchForm ? "" : "is_active");
	}

	const handleChangeFilter = async (e: React.ChangeEvent<HTMLSelectElement>) => {
		const target = e.target;
		const filter = target.value;
		if (!state.params.shopId || !filter) {
			return;
		}
		return callAPI(getTemplateFilter(state.params.shopId, filter), (err, result) => {
			if (err) {
				return;
			}
			const templates = result.data.map((obj: Record<string, unknown>) => new MailTemplate(obj));
			setTemplates(templates);
			reset();
			setSearchFrom("");
		});
	}

	const handleClickFavorite = async () => {
		if (!templates) {
			return;
		}
		const checks = getValues().check;
		for (const [idx, check] of checks.entries()) {
			if (!check) {
				continue;
			}
			const id = templates[idx].id;
			await callAPI(putTemplateFavorite(state.params.shopId, id, !templates[idx].isFavorite));
		}
		setTemplates(null);
	}

	const handleClickDelete = async () => {
		if (!templates) {
			return;
		}
		const checks: boolean[] = getValues().check || [];
		const names: string[] = Array.from(checks.entries()).filter(([, check]) => check).map(([idx]) => `・${templates[idx].name}`);

		if (names.length <= 0) {
			alert(`削除するテンプレートを選択してください。`);
			return;
		}

		if (window.confirm(`以下のテンプレートを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			for (const [idx, check] of checks.entries()) {
				if (!check) {
					continue;
				}
				const id = templates[idx].id;
				const res = await callAPI(deleteTemplate(state.params.shopId, id));
				if (!res.data) {
					alert(`送信メールテンプレート ${templates[idx].name} の削除に失敗しました。この送信メールテンプレートは使用中です。`);
				}
			}
		}
		setTemplates(null);
	};

	const toEditURL = (templateId: string) => {
		return state.params.basePath + '/mail/template/' + templateId;
	};
	const toCopyURL = (templateId: string) => {
		return state.params.basePath + '/mail/template/' + templateId + '/copy';
	};

	return (
		<>
			<div>
				<h1 className="el_pageTtl">送信メールテンプレート</h1>
				<p className="el_pageDesc">キャンペーン用メールテンプレートの新規作成、確認・編集ができます。</p>
				<div className="bl_row">
					<div className="bl_col bl_col__12">
						<div className="bl_panel bl_panel__bt">
							<div className="bl_panel_headerFooter">
								<Link className="el_btn el_btn__plus" to={`${state.params.basePath}/mail/template/new`}>新規追加</Link>
								<button className="el_btnInv" type="button" onClick={handleClickFavorite}>お気に入り</button>
								<button className="el_btnBlueInv" type="button" onClick={handleClickDelete}>削除</button>
							</div>
							{templates && <>
								<table className="bl_table bl_table__mailTemplate">
									<thead className="bl_table_head">
										<tr>
											<th><input className="el_checkMark" type="checkbox" name='checkAll' ref={checkAll} onChange={handleChangeCheckAll} /></th>
											<th>テンプレート名</th>
											<th className={"bl_table_filterHead bl_table_filterHead__beforeBtn " + searchForm}>
												<span className="bl_table_filterHeadTtl" onClick={handleClickSearchForm}>メールカテゴリ</span>
												<div className="bl_tableSearchForm bl_tableSearchForm__left">
													<p className="bl_tableSearchForm_ttl">フィルタ</p>
													<div className="el_selectWrap">
														<select onChange={handleChangeFilter}>
															<option value="all">すべて表示</option>
															<option value="review_request">フォローメール</option>
															<option value="review_completed">レビュー完了</option>
															<option value="application_completed">申込受付完了</option>
														</select>
													</div>
												</div>
											</th>
										</tr>
									</thead>
									<tbody className="bl_table_body">
										{templates.map((template, idx) => 
											<tr key={template.id}>
												<td><input className="el_checkMark" type="checkbox" name={`check[${idx}]`} ref={register} onChange={handleChangeCheck} /></td>
												<td className={template.isFavorite ? `is_favorite` : ``}>{template.name}</td>
												<td>
													<span><TemplateCategory category={template.category} /></span>
													<div className="bl_table_btnWrap">
														<Link className="el_btnInv" to={toEditURL(template.id)}>編集</Link>
														<Link className="el_btnInv" to={toCopyURL(template.id)}>複製</Link>
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
								<p className="el_resultCount">全{templates.length}件</p>
							</>}
							
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

interface TemplateCategoryProps {
	category: string;
}
function TemplateCategory(props: TemplateCategoryProps): React.ReactElement {
	if (props.category == "review_request") {
		return <>フォローメール</>;
	} else if (props.category == "review_completed") {
		return <>レビュー完了</>;
	} else if (props.category == "application_completed") {
		return <>申込受付完了</>;
	}
	return <></>;
}
export default TemplateList;