import * as React from 'react';
import LinkGuide from '../LinkGuide';

interface RakutenSettingCheckLoginProp {
	onClick: () => Promise<string>,
}

export function RakutenSettingCheckLogin(props: RakutenSettingCheckLoginProp): React.ReactElement {
	const [info, setInfo] = React.useState('チェックを開始してください');
	const [buttonEnable, setButtonEnable] = React.useState(true);

	const handleClickCheck = async () => {
		const loginCheck = props.onClick();
		setInfo("ログイン可能か確認中です。しばらくお待ちください。");
		setButtonEnable(false);
		loginCheck.then((result) => {
			setInfo(result);
			setButtonEnable(true);
		})

	};

	return (
		<>
			<h3 className="el_lv2Heading">ログイン可能かチェックする<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_9' className='el_linkIcon__guideF' /></h3>
			<p className="el_rakutenSettingDesc">保存された楽天アカウント情報を使ってログイン可能か確認します。</p>
			<p className="el_rakutenSettingNote">{info}</p>
			<input type="button" value={buttonEnable ? "チェック開始" : "チェック中"} className="el_largeBtn" onClick={handleClickCheck} disabled={!buttonEnable} />
		</>
	);
}
export default RakutenSettingCheckLogin;