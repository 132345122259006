import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTimer } from '../../../lib';
import FormErrorMessage from '../FormErrorMessage';
import LinkGuide from '../LinkGuide';
import RakutenInput from './RakutenInput';
import { FormIsDirtys } from './RakutenSetting';

interface RakutenSettingSmtpFormProps {
	initValues: Record<string, any>;
	setValues: Record<string, any>;
	onSave: (values: Record<string, any>) => Promise<boolean>;
	onInit: () => Promise<boolean>;
	setFormIsDirtys: React.Dispatch<React.SetStateAction<FormIsDirtys>>;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function RakutenSettingSmtpForm(props: RakutenSettingSmtpFormProps): React.ReactElement {
	const { register, handleSubmit, errors, reset, formState: { isDirty } } = useForm({
		defaultValues: props.initValues,
	});
	React.useEffect(() => {
		props.setFormIsDirtys(prev => ({ ...prev, smtp: isDirty }));
	}, [isDirty]);

	const [result, setResult] = React.useState('');

	React.useEffect(() => {
		reset(props.initValues);
	}, [props.initValues]);

	const setTimer = useTimer();

	const handleClickSave = handleSubmit(async (values) => {
		const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}shop\.rakuten\.co\.jp$/;
		if (reg.test(values.mail)) {
			alert('楽天ドメイン(shop.rakuten.co.jp)のメールアドレス(マスクアドレス)は利用できません。\n楽天RMS上の店舗・企業詳細情報に表示されている「店舗連絡先メールアドレス」を入力してください');
			return;
		}

		const res = await props.onSave(values);
		setResult(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setResult(''), 6000);
			reset(values);
		}
	});

	const handleClickInit = async () => {
		if (window.confirm("楽天あんしんメルアドサーバ(SMTP AUTH)情報 を初期化してよろしいですか？")) {
			const res = await props.onInit();
			setResult(res ? 'is_success' : 'is_error');

			if (res) {
				setTimer(() => setResult(''), 6000);
				reset();
			}
		}
	};

	const smtpPassRules = props.setValues.smtpPass ?
		// 設定済み時(任意入力) : 未設定時(必須入力)
		{} : { required: 'SMTP AUTH パスワードを入力してください' };

	return (
		<>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">
					送信元メールアドレス
					<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_4' className='el_linkIcon__guideF' />
				</h3>
				<RakutenInput type="text" name="mail" ref={register({
						required: '送信元メールアドレスを入力してください',
						pattern: {
							value: /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
							message: '送信元メールアドレスを入力してください'
						}
					})}
					errors={errors} storedValue={props.setValues.mail} />
				<FormErrorMessage errors={errors} name="mail" />
			</div>
			<div className="bl_panel_row">
				<h2 className="el_lv3Heading">
					メール送信者表示名
				</h2>
				<RakutenInput type="text" name="mailSenderName" ref={register({ required: 'メール送信者表示名を入力してください' })}
					errors={errors} storedValue={props.setValues.mailSenderName} />
				<FormErrorMessage errors={errors} name="mailSenderName" />
			</div>
			<div className="bl_panel_row">
				<h2 className="el_lv3Heading">
					メールCC・BCC設定
				</h2>
				<label className="bl_label">
					<input type="radio" name="ccType" value="CC" ref={register} /><span>CC設定する</span>
				</label>
				<br />
				<label className="bl_label">
					<input type="radio" name="ccType" value="BCC" ref={register} /><span>BCC設定する</span>
				</label>
				<br />
				<label className="bl_label">
					<input type="radio" name="ccType" value="none" ref={register} /><span>設定しない</span>
				</label>
			</div>
			<div className="bl_panel_row">
				<h2 className="el_lv3Heading">
					CC・BCC送信先メールアドレス
					<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_4' className='el_linkIcon__guideF' />
				</h2>
				<RakutenInput type="text" name="mailCcBcc" ref={register({
						pattern: {
							value: /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
							message: 'CC・BCC送信先メールアドレスを入力してください'
						}
					})}
					errors={errors} storedValue={props.setValues.mailCcBcc} />
				<FormErrorMessage errors={errors} name="mailCcBcc" message='CC・BCC送信先メールアドレスを入力してください' />
				<p className="txt_blue fs_12 mt_8">※未設定の場合は送信元メールアドレス宛に送信します。</p>
			</div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">
					SMTP AUTH ID
					<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_4' className='el_linkIcon__guideF' />
				</h3>
				<RakutenInput type="text" name="smtpAuth" ref={register({ required: 'SMTP AUTH IDを入力してください' })}
					errors={errors} storedValue={props.setValues.smtpAuth} />
				<FormErrorMessage errors={errors} name="smtpAuth" />
			</div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">
					SMTP AUTH パスワード
					<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_4' className='el_linkIcon__guideF' />
				</h3>
				<RakutenInput name="smtpPass" ref={register(smtpPassRules)}
					errors={errors} hide storedValue={props.setValues.smtpPass} />
				<FormErrorMessage errors={errors} name="smtpPass" />
			</div>
			<div className="el_largeBtnWrap">
				<button className={"el_largeBtn " + result} onClick={handleClickSave}>保存</button>
				<button className="el_largeBtnBlueInv" onClick={handleClickInit}>初期化</button>
			</div>
		</>
	);
}
export default RakutenSettingSmtpForm;