import { PageInfo, RakutenOrder, RewardTarget, RewardTargetStatus, rewardTargetStatus, rewardTargetStatusInfos } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { TableVirtuoso } from 'react-virtuoso';
import { InsertSeparator, uri } from '../../../../lib';
import LinkGuide from '../../LinkGuide';

interface TargetListProps {
	targets: RewardTarget[] | null;
	pageInfo: PageInfo | null;
	methods: UseFormMethods<any>;
	loadMore: () => void;
	onSubmitFilter: () => void;
	onChangeCanSendMail: (target: RewardTarget, canSendMail: boolean) => void;
}

export function TargetList(props: TargetListProps): React.ReactElement {
	const { register } = props.methods;
	const [activeForm, setActiveForm] = React.useState('');

	React.useEffect(() => {
		const docClickHandler = (ev: any) => {
			if (!(ev.target instanceof HTMLElement) || ev.target.closest("th.bl_table_filterHead")) {
				return;
			}
			setActiveForm('');
		};
		document.body.addEventListener('click', docClickHandler);
		return () => document.body.removeEventListener('click', docClickHandler);
	}, []);

	const handleChangeCanSendMail = (target: RewardTarget) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeCanSendMail(target, ev.currentTarget.checked);
	};
	const handleClickActiveForm = (form: string) => {
		setActiveForm(prev => prev === form ? '' : form);
	};
	const handleSubmitFilter = () => {
		setActiveForm('');
		props.onSubmitFilter();
	};
	const handleEndReached = () => {
		props.loadMore();
	};

	const toRMSOrderDetailURL = (orderNumber: string) => uri`https://order-rp.rms.rakuten.co.jp/order-rb/individual-order-detail-sc/init?orderNumber=${orderNumber}`;
	const toShippingDateString = (order: RakutenOrder) => {
		if (order.shippingDate == null) {
			return '未発送';
		}
		return dayjs(order.shippingDate).subtract(1, 'day').format('YYYY-MM-DD');
	};
	const toStatusString = (status: RewardTargetStatus) => {
		return rewardTargetStatusInfos[status]?.name ?? status;
	};
	const isActiveForm = (form: string) => {
		return activeForm === form ? 'is_active' : '';
	}

	return (
		<div className="bl_panel bl_panel__ttlInv">
			<h2 className="el_lv2Heading">レビュー待ち状況一覧</h2>
			<LinkGuide guidePath='operation/review_privilege' link='レビュー待ち状況一覧' />
			<TableVirtuoso
				className='bl_virtual_table bl_table__reviewStatus'
				style={{ height: '' /* 指定しないとTableVirtuosoのデフォルト値が設定されるので、空文字を指定してCSSで設定している height を使わせる */ }}
				data={props.targets || []}
				fixedHeaderContent={() => (
					<tr>
						<th className={`bl_table_filterHead ${isActiveForm('order')}`}>
							<span className="bl_table_filterHeadTtl" onClick={() => handleClickActiveForm('order')}>注文番号</span>
							<div className="bl_tableSearchForm bl_tableSearchForm__alignLeft">
								<p className="bl_tableSearchForm_ttl">検索</p>
								<div className="el_searchInputWrap">
									<input type="text" name="filterOrderNumber" ref={register} />
									<button className="el_searchBtn" type="button" onClick={handleSubmitFilter} />
								</div>
							</div>
						</th>
						<th>SKU</th>
						<th>キャンペーン名</th>
						<th>発送日</th>
						<th>商品レビュー</th>
						<th>ショップレビュー</th>
						<th>フォローメール</th>
						<th>メール送信</th>
						<th className={`bl_table_filterHead ${isActiveForm('status')}`}>
							<span className="bl_table_filterHeadTtl" onClick={() => handleClickActiveForm('status')}>特典申込状況</span>
							<div className="bl_tableSearchForm bl_tableSearchForm__right">
								<p className="bl_tableSearchForm_ttl">状況でフィルタ</p>
								<div className="el_selectWrap">
									<select name="filterStatus" ref={register} onChange={handleSubmitFilter}>
										<option value="all">すべて表示</option>
										{rewardTargetStatus.map((status) =>
											<option key={status} value={status}>{rewardTargetStatusInfos[status].name}</option>
										)}
									</select>
								</div>
							</div>
						</th>
					</tr>
				)}
				computeItemKey={(_, target) => target.id}
				itemContent={(_, target) => (
					<>
						<td>
							{target.orders.map(order =>
								<a key={order.number} href={toRMSOrderDetailURL(order.number)} target="_blank" rel="noreferrer">{order.number}</a>
							)}
						</td>
						<td>{target.sku}</td>
						<td>
							<InsertSeparator unique>
								{target.campaigns.map(campaign => campaign.name)}
							</InsertSeparator>
						</td>
						<td>
							{target.orders.map(order =>
								<time key={order.number}>{toShippingDateString(order)}</time>
							)}
						</td>
						<td>{target.hasItemReview ? '済' : '未確認'}</td>
						<td>{target.hasShopReview ? '済' : '未確認'}</td>
						<td>{
							target.orders.some(order => order.hasSentFollowMail && order.followMailDate) ? '済' :
								target.orders.some(order => order.hasSentFollowMail) ? '送信待ち' : '未送信'
						}</td>
						<td>
							<label className="el_toggleBtn">
								<input type="checkbox" checked={target.orders.some(order => order.canSendingMail)} onChange={handleChangeCanSendMail(target)} />
								<span />
							</label>
						</td>
						<td>{toStatusString(target.getStatus())}</td>
					</>
				)}
				totalCount={props.pageInfo ? props.pageInfo.recordsTotal : 0}
				endReached={() => handleEndReached()}
			/>
			<p className="el_resultCount">全{props.pageInfo ? props.pageInfo.recordsTotal : '-'}件</p>
		</div>
	);
}
export default TargetList;