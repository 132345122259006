import { Campaign, ItemGroup, MailTemplate, NIL, Reward, ShopEntity } from '@sasagase/types';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { is } from 'superstruct';
import { getCampaign, getGroups, getRewards, getShopEntity, getTemplates, postCampaign, putCampaign } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import CampaignEditForm, { CampaignEditFormValues, schema } from './CampaignEditForm';
import { toCutCheck } from './CampaignEditForm/CampaignEditFormStep1';
import { MailFormValue } from './CampaignEditForm/CampaignEditFormStep4';
import { toDate, toDateFields } from './CampaignEditForm/DateInput';
import { toDaysField } from './CampaignEditForm/DaysInput';
import { sanitizeSku } from './GroupEditRow';

function toMail(values: MailFormValue | undefined): Campaign['followMail'] {
	const bodys = [
		{ type: "header" as const, content: values ? values.bodyHeader : "" },
		...(values ? values.bodyElement : [])
	];
	const mail = {
		disable: values ? values.disable === 'true' : undefined,
		subject: values ? values.subject : "",
		body: bodys,
		signature: values ? values.signature : "",
	};
	return mail;
}

function toCampaign(id: string, values: CampaignEditFormValues, groups: ItemGroup[], rewards: Reward[]): Campaign {
	const groupMap = new Map(groups.map(group => [group.id, group]));
	const rewardMap = new Map(rewards.map(reward => [reward.id, reward]));

	const campaignRewards = values.rewards.filter(reward => reward.val)
		.map(reward => rewardMap.get(reward.val))
		.filter((reward): reward is Reward => reward !== undefined);
	const isNeverEnd = values.isNeverEnd == 'true';
	const isCutDate = values.isCutCheck == 'date';
	const isCutDays = values.isCutCheck == 'days';
	const shouldCloseApplication = values.shouldCloseApplication == 'true';
	const isDraft = !is(values, schema);

	const campaign = Campaign.create({
		id,
		itemGroup: ItemGroup.create({
			id: NIL,
			name: `キャンペーン ${values.name}`,
			isRef: true,
			isAll: values.itemGroup.isAll == 'true',
			sku: values.itemGroup.isAll == 'true' ? [] :
				values.itemGroup.skus.split(/[,\t\n]/).map(str => sanitizeSku(str.trim())).filter(Boolean),
			excludeSku: values.itemGroup.excludeSkus.split(/\s*[,\t\n]\s*/).map(str => sanitizeSku(str.trim())).filter(Boolean),
			childGroups: values.itemGroup.isAll == 'true' ? [] :
				values.itemGroup.childGroupIds.map(id => groupMap.get(id)),
			excludeChildGroups: values.itemGroup.excludeChildGroupIds?.map(id => groupMap.get(id)),
		}),
		rewards: campaignRewards,
		priority: values.priority,
		name: values.name,
		beginDate: toDate(values.begin).getTime() || undefined,
		endDate: isNeverEnd ? undefined : (toDate(values.end).getTime() || undefined),
		reviewDeadlineDate: isCutDate ? (toDate(values.deadlineDate).getTime() || undefined) : undefined,
		reviewDeadlineDays: isCutDays ? Number(values.deadlineDays) || undefined : undefined,
		isNeverEnd,
		isCutDate,
		isCutDays,
		isItemReviewRequired: values.reviewRequired == 'item' || values.reviewRequired == 'both',
		isShopReviewRequired: values.reviewRequired == 'shop' || values.reviewRequired == 'both',
		isAnyReviewRequired: values.reviewRequired == 'any',
		canManyRewards: values.canManyRewards == 'true',
		canChooseDest: values.destType == 'form' && campaignRewards.some(reward => !reward.isCoupon),
		defaultDestType: ['shipping', 'orderer'].includes(values.destType) ? values.destType : 'shipping',
		applicationClosingDays: shouldCloseApplication ? Number(values.applicationClosingDays) : undefined,
		shouldCloseApplication,
		followMail: toMail(values.followMail),
		requestMail: toMail(values.requestMail),
		receivedMail: toMail(values.receivedMail),
		isDraft,

		inProgressDate: isDraft ? undefined : values.inProgressDate,
		waitingReviewDate: isDraft ? undefined : values.waitingReviewDate,
		finishedDate: isDraft ? undefined : values.finishedDate,
		cancelledDate: isDraft ? undefined : values.cancelledDate,
	});
	return campaign;
}

function toMailValues(mail: Campaign['followMail']): MailFormValue {
	const bodyHeaderData = mail.body.find((val) => val.type === "header");
	return {
		disable: mail.disable ? 'true' : 'false',
		template: '',
		subject: mail.subject,
		bodyHeader: bodyHeaderData ? bodyHeaderData.content : '',
		bodyElement: mail.body.filter((val) => val.type !== "header"),
		signature: mail.signature,
		email: '',
	};
}

function toValues(campaign: Campaign): CampaignEditFormValues {
	const toBoolString = (bool: boolean) => bool ? 'true' as const : 'false' as const;
	const destType = campaign.canChooseDest ? 'form' as const : 
		campaign.defaultDestType === 'shipping' ? 'shipping' as const : 'orderer' as const;

	const values = {
		priority: campaign.priority,
		name: campaign.name,
		begin: toDateFields(campaign.beginDate),
		end: toDateFields(campaign.endDate),
		deadlineDate: toDateFields(campaign.reviewDeadlineDate),
		deadlineDays: toDaysField(campaign.reviewDeadlineDays),
		isNeverEnd: toBoolString(campaign.isNeverEnd),
		isCutCheck: toCutCheck(campaign),
		reviewRequired:
			campaign.isAnyReviewRequired ? 'any' as const :
			campaign.isItemReviewRequired && campaign.isShopReviewRequired ? 'both' as const :
			campaign.isShopReviewRequired ? 'shop' as const : 'item' as const,
		canManyRewards: toBoolString(campaign.canManyRewards),

		inProgressDate: campaign.inProgressDate,
		waitingReviewDate: campaign.waitingReviewDate,
		finishedDate: campaign.finishedDate,
		cancelledDate: campaign.cancelledDate,

		rewards: campaign.rewards.map(r => ({ val: r.id })),
		isCouponReward: campaign.rewards.some(reward => reward.isCoupon),
		isManyRewardOriginal: campaign.rewards.length > 1 ? true : false,
		destType: destType,
		destTypeOriginal: destType,
		applicationClosingDays: String(campaign.applicationClosingDays ?? ''),
		shouldCloseApplication: toBoolString(campaign.shouldCloseApplication),
		itemGroup: {
			isAll: campaign.itemGroup.isAll ? 'true' : 'false',
			inputSkus: campaign.itemGroup.sku.join(','),
			skus: campaign.itemGroup.sku.join(','),
			inputExcludeSkus: campaign.itemGroup.excludeSku.join(','),
			excludeSkus: campaign.itemGroup.excludeSku.join(','),
			childGroupIds: campaign.itemGroup.childGroups.map(group => group.id),
			excludeChildGroupIds: campaign.itemGroup.excludeChildGroups.map(group => group.id),
			group: {},
			excludeGroup: {},
			itemName: '',
			upper: '',
			lower: '',
			excludeItemName: '',
			excludeUpper: '',
			excludeLower: '',
		},
		followMail: toMailValues(campaign.followMail),
		requestMail: toMailValues(campaign.requestMail),
		receivedMail: toMailValues(campaign.receivedMail),
	};
	if (values.rewards.length <= 0) {
		values.rewards.push({ val: '' });
	}
	return values;
}

interface CampaignEditProps {}
type CampaignEditParams = {
	shopId?: string;
	campaignId?: string;
	flag?: string;
}

export const initValues: CampaignEditFormValues = {
	priority: 0,

	name: '',
	begin: '',
	end: '',
	isNeverEnd: 'false',
	deadlineDate: '',
	deadlineDays: '',
	isCutCheck: 'never',
	reviewRequired: 'item',
	canManyRewards: 'false',

	rewards: [{ val: '' }],
	destType: 'shipping',
	applicationClosingDays: '',
	shouldCloseApplication: 'false',
	itemGroup: {
		isAll: 'false',
		inputSkus: '',
		skus: '',
		inputExcludeSkus: '',
		excludeSkus: '',
		childGroupIds: [],
		excludeChildGroupIds: [],
		group: {},
		excludeGroup: {},
		itemName: '',
		upper: '',
		lower: '',
		excludeItemName: '',
		excludeUpper: '',
		excludeLower: '',
	},
	followMail: {
		disable: 'false',
		template: '',
		subject: '',
		bodyHeader: '{{orderFull}}　様　(注文番号:　{{ordernumber}}　)',
		bodyElement: [],
		signature: '',
		email: '',
	},
	requestMail: {
		disable: 'false',
		template: '',
		subject: '',
		bodyHeader: '{{orderFull}}　様　(注文番号:　{{ordernumber}}　)',
		bodyElement: [],
		signature: '',
		email: '',
	},
	receivedMail: {
		disable: 'false',
		template: '',
		subject: '',
		bodyHeader: '{{orderFull}}　様　(注文番号:　{{ordernumber}}　)',
		bodyElement: [],
		signature: '',
		email: '',
	},
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function CampaignEdit(props: CampaignEditProps): React.ReactElement | null {
	const params = useParams<CampaignEditParams>();
	const isNew = params.campaignId == 'new';
	const isCopy = params.flag === 'copy';

	const callAPI = useAPI();
	const navigate = useNavigate();
	const [state] = useAppState();
	const [values, setValues] = React.useState<CampaignEditFormValues | null>(null);
	const [rewards, setRewards] = React.useState<Reward[] | null>(null);
	const [groups, setGroups] = React.useState<ItemGroup[] | null>(null);
	const [templates, setTemplates] = React.useState<null|MailTemplate[]>(null);

	React.useEffect(() => {
		if (isNew || !params.shopId || !params.campaignId || !state.shop) {
			return;
		}
		const email = String(state.shop.mail);
		return callAPI(getCampaign(params.shopId, params.campaignId), (err, result) => {
			if (err) {
				return null;
			}
			const campaign = Campaign.create(result.data);
			const values = toValues(campaign);
			if (isCopy) {
				values.priority = Date.now();
				values.name += ' のコピー';
				values.inProgressDate = undefined;
				values.waitingReviewDate = undefined;
				values.finishedDate = undefined;
				values.cancelledDate = undefined;
			}
			values.followMail.email = email;
			values.requestMail.email = email;
			values.receivedMail.email = email;
			setValues(values);
		});
	}, [params.shopId, params.campaignId, state.shop]);

	React.useEffect(() => {
		if (!state.shop || !isNew) {
			return;
		}
		const email = String(state.shop.mail);
		return callAPI(getShopEntity(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const shopEntity = new ShopEntity(result.data);
			const vals = initValues;
			vals.priority = Date.now();
			vals.followMail.signature = shopEntity.signature;
			vals.followMail.email = email;
			vals.requestMail.signature = shopEntity.signature;
			vals.requestMail.email = email;
			vals.receivedMail.signature = shopEntity.signature;
			vals.receivedMail.email = email;
			setValues(vals);
		});
	}, [state.shop]);

	React.useEffect(() => {
		if (!params.shopId) {
			return;
		}
		return callAPI(getRewards(params.shopId), (err, result) => {
			if (err) {
				return null;
			}
			const rewards: Reward[] = result.data.map((obj: Record<string, unknown>) => Reward.create(obj));
			setRewards(rewards);
		});
	}, [params.shopId]);

	React.useEffect(() => {
		if (!params.shopId) {
			return;
		}
		return callAPI(getGroups(params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const groups: ItemGroup[] = result.data.map((obj: Record<string, unknown>) => new ItemGroup(obj));
			setGroups(groups);
		});
	}, [params.shopId]);

	React.useEffect(() => {
		if (!params.shopId) {
			return;
		}
		return callAPI(getTemplates(params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const templates = result.data.map((obj: Record<string, unknown>) => new MailTemplate(obj));
			setTemplates(templates);
		});
	}, [params.shopId]);

	const handleSave = async (values: CampaignEditFormValues): Promise<boolean> => {
		if (!groups || !rewards || !params.shopId || !params.campaignId) {
			throw null;
		}
		if (isNew || isCopy) {
			const campaign = toCampaign(NIL, values, groups, rewards);
			const res = await callAPI(postCampaign(params.shopId, campaign));
			const id = res.data;
			navigate(`${state.params?.basePath}/reward/campaign/${id}`, {state: {newId: id}, replace: true});
			return true;
		} else {
			const campaign = toCampaign(params.campaignId, values, groups, rewards);
			try {
				const res = await callAPI(putCampaign(params.shopId, params.campaignId, campaign));
				return res.data;
			} catch (err) {
				return false;
			}
		}
	};

	if (!values || !rewards || !groups || !templates) {
		return null; // loading
	}

	return <CampaignEditForm initValues={values} onSave={handleSave} rewards={rewards} groups={groups} templates={templates} toCampaign={toCampaign} />;
}
export default CampaignEdit;