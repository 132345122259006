import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAppState } from '../../../context';
import { useTimer } from '../../../lib';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../lib/usePrompt';
import FormErrorMessage from '../FormErrorMessage';
import MailEditRow from './MailEditRow';

interface TemplateEditFormProps {
	initValues: Record<string, any>;
	onSave: (values: Record<string, any>) => Promise<boolean>;
}
export function TemplateEditForm(props: TemplateEditFormProps): React.ReactElement {
	const [state] = useAppState();
	const formMethods = useForm({
		defaultValues: props.initValues,
	});
	const { register, handleSubmit, errors, reset, formState: { isDirty, isSubmitting } } = formMethods;
	// isSubmitting: 新規追加成功時に新しいUUIDのページへのリダイレクト制御のため
	usePrompt(PROMPT_CONFIRM_MESSAGE, (isDirty && !isSubmitting));

	const [saveState, setSaveState] = React.useState('');

	const setTimer = useTimer();

	const handleClickSave = handleSubmit(async (values) => {
		const res = await props.onSave(values);
		setSaveState(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setSaveState(''), 6000);
			reset(values);
		}
	});

	return (
		<>
			<h1 className="el_pageTtl">送信メールテンプレート</h1>
			<p className="el_pageDesc">キャンペーン用メールテンプレートの新規作成、確認・編集ができます。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<h2 className="el_lv2Heading">設定</h2>
						<div className="bl_panel_row bl_panel_row__indent">
							<h3 className="el_lv3Heading">テンプレート名</h3>
							<input type="text" name="name" ref={register({ required: 'テンプレート名を入力してください' })} />
							<FormErrorMessage errors={errors} name="name" />
						</div>
						<div className="bl_panel_row bl_panel_row__indent">
							<h3 className="el_lv3Heading">メールカテゴリ</h3>
							<label className="bl_label">
								<input type="radio" name="category" value="review_request" ref={register({ required: 'メールカテゴリを選択してください' })} /><span>フォローメール</span>
							</label>
							<br />
							<label className="bl_label">
								<input type="radio" name="category" value="review_completed" ref={register({ required: 'メールカテゴリを選択してください' })} /><span>レビュー完了</span>
							</label>
							<br />
							<label className="bl_label">
								<input type="radio" name="category" value="application_completed" ref={register({ required: 'メールカテゴリを選択してください' })} /><span>申込受付完了</span>
							</label>
							<FormErrorMessage errors={errors} name="category" />
						</div>
						<MailEditRow methods={formMethods} />
						<div className="el_largeBtnWrap">
							<button className={"el_largeBtn " + saveState} type="button" onClick={handleClickSave}>保存</button>
						</div>
						<Link className="el_backLink" to={`${state.params.basePath}/mail/template`}>≪一覧へ戻る</Link>
					</div>
				</div>
			</div>
		</>
	);
}
export default TemplateEditForm;