import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { recoverPassword } from '../../../api';
import { useAPI } from '../../../context';
import FormErrorMessage from '../FormErrorMessage';

interface AccountRecoverProps {}

interface ResetResult {
	result?: boolean;
	message?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AccountRecover(props: AccountRecoverProps): React.ReactElement {
	const { register, handleSubmit, errors } = useForm({
		defaultValues: { mail: '' },
	});
	const callAPI = useAPI();
	const [result, setResult] = React.useState<ResetResult|null>(null);

	const handleClickRecover = handleSubmit(async (values) => {
		const res = await callAPI(recoverPassword(values.mail));
		if (res.data) {
			setResult({ result: true });
		} else {
			setResult({ result: false, message: 'メールアドレスに誤りがあるか、このメールアドレスは登録されていません。' });
		}
	});

	return (
		<div className="ly_reissue">
			<div className="ly_reissue_inner">
				<h1 className="el_entryTitle"><Link to="/"><img src="/assets/img/logo-w.svg" alt="ラクラクあつまレビュー" /></Link></h1>
				<div className="bl_passForm">
					<div className="bl_loginFormRow">
						<p className="txt_blue">パスワードをお忘れですか？&nbsp;メールアドレスを入力してください。新しいパスワードを発行するリンクを送信いたします。</p>
					</div>
					{result &&
						<div className="bl_loginFormRow">
							{result.result && <>
								<p className="txt_blue">
									<b>確認メールの送信が完了しました。</b><br />
									入力されたメールアドレス宛に「【ラクラクあつまレビュー】パスワード再設定手続き」という件名のメールを送信しました。<br />
									メールの内容に従って、次のステップへ進んでください。
								</p>
							</>}
							{!result.result &&
								<p className="el_errorMessage">
									{result.message}
								</p>
							}
						</div>
					}
					<div className="bl_loginFormRow">
						<label className="bl_blueLabel" htmlFor="mail">メールアドレス</label>
						<input id="mail" type="email" name="mail" placeholder="aaa@aa.co.jp"
							ref={register({
								required: 'メールアドレスを入力してください',
								pattern: {
									value: /\S+@\S+.\S+/,
									message: 'メールアドレスを入力してください'
								}
							})} />
						<FormErrorMessage errors={errors} name="mail" />
					</div>
					<button className="el_largeBtn el_largeBtn__fullWidth" type="button" onClick={handleClickRecover}>新しいパスワードを取得</button>
				</div>
			</div>
		</div>
	);
}
export default AccountRecover;