import { MailContent } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { getReviewMail, sendReviewMail } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { useTimer } from '../../../lib';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../lib/usePrompt';
import FormErrorMessage from '../FormErrorMessage';
import OriginalTagModal from './OriginalTagModal';
import Preview from './Preview';

interface NotCoveredEditFormProps {
	initValues: Record<string, any>;
	onSave: (values: Record<string, any>) => Promise<boolean>;
}

export function NotCoveredEditForm(props: NotCoveredEditFormProps): React.ReactElement {
	const [state] = useAppState();
	const { register, handleSubmit, errors, getValues, reset, formState: { isDirty, isSubmitting } }  = useForm({
		defaultValues: props.initValues,
	});
	// isSubmitting: 新規追加成功時に新しいUUIDのページへのリダイレクト制御のため
	usePrompt(PROMPT_CONFIRM_MESSAGE, (isDirty && !isSubmitting));
	const callAPI = useAPI();
	const [saveState, setSaveState] = React.useState('');
	const [previewValues, setPreviewValues] = React.useState<MailContent | null>(null);
	const [enableTagModal, setEnableTagModal] = React.useState<boolean>(false);
	const [sendComplete, setSendComplete] = React.useState(false);
	const [isClick, setIsClick] = React.useState(false);

	const setTimer = useTimer();

	const getFormValue = () => {
		return {
			subject: getValues('subject'),
			body: getValues('body'),
			signature: getValues('signature'),
			to: getValues('email'),
		};
	}

	const handleClickOpenPreview = async () => {
		const formValues = getFormValue();
		const res = await callAPI(getReviewMail(state.params.shopId, 'preview', formValues));
		const mailContent = new MailContent(res.data);
		setPreviewValues(mailContent);
	};

	const handleClickClosePreview = () => {
		setPreviewValues(null);
	};

	const handleClickSendTest = async () => {
		const formValues = getFormValue();
		const to = formValues.to;
		const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
		if (reg.test(to)) {
			setIsClick(true);

			const res = await callAPI(getReviewMail(state.params.shopId, 'testMail', formValues));
			const mailContent = MailContent.create({
				...res.data
			});
			callAPI(sendReviewMail(state.params.shopId, mailContent), (err, result) => {
				if (err) {
					return;
				}
				if (result.data) {
					setSendComplete(true);
				}
			});
		} else {
			alert(`メールアドレスを入力してください`);
		}
	};

	const handleClickSwitchTagModal = () => {
		setEnableTagModal(!enableTagModal);
	};

	const handleClickSave = handleSubmit(async (values) => {
		const res = await props.onSave(values);
		setSaveState(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setSaveState(''), 6000);
			reset(values);
		}
	});

	const toSendTestButtonString = (flg: boolean) => {
		return flg ? '送信完了' : 'テスト送信';
	}

	return (
		<>
			{previewValues && <Preview values={previewValues} onClose={handleClickClosePreview} />}
			{enableTagModal && <OriginalTagModal onClose={handleClickSwitchTagModal} />}
			<h1 className="el_pageTtl">特典対象外商品用メール</h1>
			<p className="el_pageDesc">特典対象外の商品を購入したお客様に送信するメールの新規作成、確認・編集ができます。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<h2 className="el_lv2Heading">設定</h2>
						<div className="bl_panel_row bl_panel_row__indent">
							<h3 className="el_lv3Heading">テンプレート名</h3>
							<input type="text" name="name" ref={register({ required: 'テンプレート名を入力してください' })} />
							<FormErrorMessage errors={errors} name="name" />
						</div>
						<div className="bl_panel_row bl_panel_row__indent">
							<h3 className="el_lv3Heading">メール件名</h3>
							<input type="text" name="subject" ref={register({ required: 'メール件名を入力してください' })} />
							<FormErrorMessage errors={errors} name="subject" />
						</div>
						<div className="bl_panel_row bl_panel_row__indent">
							<h3 className="el_lv3Heading">メール本文</h3>
							<div className='mb_8 fs_12 txt_blue'>
								フリーテキスト内には独自タグを入力できます。
								<button className="el_btnBlueInv" type="button" onClick={handleClickSwitchTagModal} disabled={enableTagModal}>独自タグ一覧</button>
							</div>
							<textarea className="el_textarea" name="body" rows={3} ref={register({ required: 'メール本文を入力してください' })}></textarea>
							<FormErrorMessage errors={errors} name="body" />
						</div>
						<div className="bl_panel_row bl_panel_row__indent">
							<h3 className="el_lv3Heading">署名</h3>
							<textarea className="el_textarea" name="signature" rows={3} ref={register} />
						</div>
						<div className="bl_panel_row mb_16">
							<button className="el_btnBlueInv" type="button" onClick={handleClickOpenPreview}>プレビューを表示</button>
						</div>
						<div className="bl_panel_row bl_mailTemp_test">
							<button className="el_btnInv" type="button" onClick={handleClickSendTest} disabled={isClick}>{toSendTestButtonString(sendComplete)}</button>
							<input type="email" name="email" ref={register} />
						</div>
						<div className="el_largeBtnWrap">
							<button className={"el_largeBtn " + saveState} type="button" onClick={handleClickSave}>保存</button>
						</div>
						<Link className="el_backLink" to={`${state.params.basePath}/mail/not-covered`}>≪一覧へ戻る</Link>
					</div>
				</div>
			</div>
		</>
	);
}
export default NotCoveredEditForm;