import * as React from 'react';
import { contextListSelector } from './ListSelectorContext';

interface ListSelectorProps extends React.InputHTMLAttributes<HTMLInputElement> {
	idx: number | string;
}

export const ListSelector: React.FC<ListSelectorProps> = (props) =>  {
	const context = React.useContext(contextListSelector);

	if (!context) {
		return null;
	}

	const { handleChange, register } = context;

	const { idx, ...inputAttr } = props;
	return (
		<input {...inputAttr} type="checkbox" name={`check.${idx}`} ref={register} onChange={handleChange} />
	);
}
export default ListSelector;