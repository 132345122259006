import ja from 'date-fns/locale/ja';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAppState } from '../../../context';
import { useTimer } from '../../../lib';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../lib/usePrompt';
import FormErrorMessage from '../FormErrorMessage';
registerLocale('ja', ja);

interface AccountSettingEditFormProps {
	initValues: Record<string, any>;
	onSave: (values: Record<string, any>) => Promise<boolean>;
}

export function AccountSettingEditForm(props: AccountSettingEditFormProps): React.ReactElement {
	const [state] = useAppState();
	const { control, register, handleSubmit, errors, getValues, reset, formState: { isDirty } }  = useForm({
		defaultValues: props.initValues,
	});
	usePrompt(PROMPT_CONFIRM_MESSAGE, isDirty);

	const [saveState, setSaveState] = React.useState('');

	const setTimer = useTimer();

	const handleClickSave = handleSubmit(async (values) => {
		const res = await props.onSave(values);
		setSaveState(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setSaveState(''), 6000);
			reset(values);
		}
	});

	return (
		<>
			<h1 className="el_pageTtl">ユーザーアカウント設定</h1>
			<p className="el_pageDesc">ユーザーアカウントの新規作成、確認・編集ができます。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">ユーザー名</h2>
							{props.initValues.name}
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">メールアドレス</h2>
							{props.initValues.mail}
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">権限</h2>
							<div className="bl_panel_row">
								<h3 className="el_lv4Heading">レビューチェックツール</h3>
								<label className="bl_label">
									<input type="radio" name="review_check_tools" value="hide" ref={register} />
									<span>非表示</span>
								</label>
								<label className="bl_label">
									<input type="radio" name="review_check_tools" value="read" ref={register} />
									<span>表示</span>
								</label>
							</div>
							<div className="bl_panel_row">
								<h3 className="el_lv4Heading">レビュー待ち状況・特典申込受付</h3>
								<label className="bl_label">
									<input type="radio" name="review_status" value="hide" ref={register} />
									<span>非表示</span>
								</label>
								<label className="bl_label">
									<input type="radio" name="review_status" value="read" ref={register} />
									<span>閲覧のみ</span>
								</label>
								<label className="bl_label">
									<input type="radio" name="review_status" value="write" ref={register} />
									<span>編集可</span>
								</label>
							</div>
							<div className="bl_panel_row">
								<h3 className="el_lv4Heading">レビュー特典登録・メール設定</h3>
								<label className="bl_label">
									<input type="radio" name="reward_mail" value="hide" ref={register} />
									<span>非表示</span>
								</label>
								<label className="bl_label">
									<input type="radio" name="reward_mail" value="read" ref={register} />
									<span>閲覧のみ</span>
								</label>
								<label className="bl_label">
									<input type="radio" name="reward_mail" value="write" ref={register} />
									<span>編集可</span>
								</label>
							</div>
{/*
							<div className="bl_panel_row">
								<h3 className="el_lv4Heading">レビュー分析</h3>
								<label className="bl_label">
									<input type="radio" name="analysis" value="hide" ref={register} />
									<span>非表示</span>
								</label>
								<label className="bl_label">
									<input type="radio" name="analysis" value="read" ref={register} />
									<span>表示</span>
								</label>
							</div>
*/}
							<div className="bl_panel_row mb_32">
								<h3 className="el_lv4Heading">ユーザーアカウント設定・楽天システム設定</h3>
								<label className="bl_label">
									<input type="radio" name="account_rakuten" value="hide" ref={register} />
									<span>非表示</span>
								</label>
								<label className="bl_label">
									<input type="radio" name="account_rakuten" value="write" ref={register} />
									<span>編集可</span>
								</label>
								<p className="el_note mt_16">※ユーザーアカウント設定・楽天システム設定は、「編集可」を選択するとその権限を付与されたユーザーも、ユーザーアカウント設定および 楽天システム設定内の情報を自由に閲覧・編集できるようになりますのでご注意ください。</p>
							</div>
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">アカウント有効期限</h2>
							<label className="bl_label">
								<input type="radio" name="account_expired" value="disabled" ref={register} onChange={() => reset({ expired_at: null })} />
								<span>設定しない</span>
							</label>
							<br />
							<label className="bl_label">
								<input type="radio" name="account_expired" value="enabled" ref={register} />
								<span>設定する</span>
							</label>
							<br />
							<div className="bl_inputFormWrap ml_26">
								<Controller
									control={control}
									name="expired_at"
									rules={{
										validate: (date) => {
											return (getValues('account_expired') == 'disabled' || date >= new Date()) || 'アカウント有効期限は現在より過去は設定できません。'
										}
									}}
									render={({ onChange, onBlur, value }) => (
										<ReactDatePicker
											locale="ja"
											onChange={onChange}
											onBlur={onBlur}
											selected={value}
											dateFormat="yyyy/MM/dd"
											minDate={new Date()}
											/>
									)}
									/>&nbsp;まで
								
							</div>
							<FormErrorMessage errors={errors} name="expired_at" />
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">アカウントのアクション</h2>
							<label className="bl_label">
								<input type="radio" name="account_state" value="active" ref={register} />
								<span>アクティブにする</span>
							</label>
							<br />
							<label className="bl_label">
							<input type="radio" name="account_state" value="suspend" ref={register} />
								<span>停止する</span>
							</label>
						</div>
						<div className="el_largeBtnWrap">
							<button className={"el_largeBtn " + saveState} type="button" onClick={handleClickSave}>保存</button>
						</div>
						<Link className="el_backLink" to={`${state.params.basePath}/account/account-setting`}>≪一覧へ戻る</Link>
					</div>
				</div>
			</div>
		</>
	);
}
export default AccountSettingEditForm;