import { Shop, User } from '@sasagase/types';
import * as React from 'react';
import { ContextState, LoginState } from "./state";

type SetAction = React.SetStateAction<ContextState>;
export function userSetter(user: User | null): SetAction {
	return prev => ({ ...prev, user });
}
export function loginSetter(login: LoginState | null): SetAction {
	return prev => ({ ...prev, login });
}
export function loginMessageSetter(message: string): SetAction {
	return prev => ({ ...prev, login: { message } });
}
export function sessionExpireSetter(handle: NodeJS.Timeout | null): SetAction {
	return prev => ({ ...prev, sessionExpire: handle });
}
export function userLogoutSetter(): SetAction {
	return prev => {
		if (prev.sessionExpire) {
			clearTimeout(prev.sessionExpire);
		}
		return { user: null, login: { message: 'ログアウトしました' }, sessionExpire: null };
	};
}
export function shopsSetter(shops: Record<string, Shop>): SetAction {
	return prev => ({ ...prev, shops });
}
export function shopSetter(shop?: Shop): SetAction {
	return prev => ({ ...prev, shop });
}

export function setParams(params: unknown): SetAction {
	return prev => ({ ...prev, params });
}
export function setModal(modal: boolean): SetAction {
	return prev => ({ ...prev, modal });
}