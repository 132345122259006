import * as React from 'react';
import { ListSelectorContext } from '../../../ListSelector';
import RequestListContent from './RequestListContent';

interface RequestListProps {}

export function RequestList(props: RequestListProps): React.ReactElement {
	return (
		<ListSelectorContext>
			<RequestListContent {...props} />
		</ListSelectorContext>
	);
}
export default RequestList;