import { User } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { changePassword, changeUser } from '../../api';
import { useAPI, useAppState, userSetter } from '../../context';
import { useTimer } from '../../lib';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../lib/usePrompt';
import PasswordInput from '../PasswordInput';
import FormErrorMessage from './FormErrorMessage';

interface ManagementProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Management(props: ManagementProps): React.ReactElement {
	const [state, setState] = useAppState();
	const { register, handleSubmit, errors, getValues, setValue, reset, formState: { isDirty } }  = useForm({
		defaultValues: {
			name: state.user?.name,
			mail: state.user?.mail,
			oldPass: '',
			newPass: '',
			newPassConfirm: '',
		},
	});
	usePrompt(PROMPT_CONFIRM_MESSAGE, isDirty);

	const callAPI = useAPI();
	const [result, setResult] = React.useState('');

	const setTimer = useTimer();

	const handleClickBack = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.go(-1);
	};
	const handleClickSave = handleSubmit(async (values)  => {
		/* password */
		if (values.oldPass || values.newPass || values.newPassConfirm) {
			if (values.newPass != values.newPassConfirm) {
				return;
			}
			if (values.newPass.length <= 0) {
				return;
			}

			setResult('');
			const resPass = await callAPI(changePassword(values.oldPass, values.newPass));
			if (!resPass.data) {
				setResult('is_error');
				return;
			}
			setValue('oldPass', '');
			setValue('newPass', '');
			setValue('newPassConfirm', '');
		}
		/* name,mail */
		const user = new User({
			capabilities: state.user?.capabilities,
			id: state.user?.id,
			name: values.name,
			mail: values.mail,
		});
		const res = await callAPI(changeUser(user));
		if (res.data) {
			setState(userSetter(user));
			setResult('is_success');
			setTimer(() => setResult(''), 6000);
			reset(values);
		} else {
			setResult('is_error');
		}
	});

	const newPassClassName = result == 'is_error' ? 'is_error' : '';

	return (
		<>
			<h1 className="el_pageTtl">ユーザー管理</h1>
			<p className="el_pageDesc">ユーザー名やメールアドレスの確認・編集を行うことができます。<br className="show_mobile" />また、パスワードの再設定ができます。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row">
							<h2 className="el_lv2Heading">ユーザー名</h2>
							<div className="bl_panel_row bl_panel_row__indent">
								<input type="text" name="name"
									ref={register({ required: 'ユーザー名を入力してください' })}
									className={`${errors.name ? 'is_error' : ''}`} 
									/>
								<FormErrorMessage errors={errors} name="name" />
							</div>
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv2Heading">メールアドレス</h2>
							<div className="bl_panel_row bl_panel_row__indent">
								<input type="email" name="mail"
									ref={register({
											required: 'メールアドレスを入力してください',
											pattern: {
												value: /\S+@\S+.\S+/,
												message: 'メールアドレスを入力してください'
											}
										})}
									className={`${errors.mail ? 'is_error' : ''}`} 
									/>
								<FormErrorMessage errors={errors} name="mail" />
							</div>
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv2Heading mb_24">パスワード設定</h2>
							<div className="bl_panel_row bl_panel_row__indent">
								<h3 className="el_lv3Heading">現在のパスワード</h3>
								{/* NOTE: エラー: is_error */}
								<PasswordInput name="oldPass"
									ref={register({
											minLength: {
												value: 8,
												message: 'パスワードは8文字以上で入力してください。',
											},
										})}
									className={`${errors.oldPass ? 'is_error' : newPassClassName}`} 
									/>
								<FormErrorMessage errors={errors} name="oldPass" />
								{/* NOTE: エラーメッセージ: span要素 */}
								{newPassClassName &&
									<span>パスワードが異なります</span>
								}
							</div>
							<div className="bl_panel_row bl_panel_row__indent">
								<h3 className="el_lv3Heading">新しいパスワード</h3>
								<PasswordInput name="newPass"
									ref={register({
											minLength: {
												value: 8,
												message: 'パスワードは8文字以上で入力してください。',
											},
											validate: {
												condRequired: input => {
													const { oldPass } = getValues();
													if (oldPass.length > 0) {
														return input.length > 0 || '新しいパスワードを入力してください。';
													}
												}
											}
										})}
									className={`${errors.newPass ? 'is_error' : ''}`} 
									/>
								<FormErrorMessage errors={errors} name="newPass" />
							</div>
							<div className="bl_panel_row bl_panel_row__indent">
								<h3 className="el_lv3Heading">新しいパスワード（確認用）</h3>
								<PasswordInput name="newPassConfirm"
									ref={register({
											validate: input => input === getValues("newPass") || '確認用のパスワードが異なります',
										})}
									className={`${errors.newPassConfirm ? 'is_error' : ''}`} 
									/>
								<FormErrorMessage errors={errors} name="newPassConfirm" />
							</div>
						</div>
						<div className="bl_panel_row mb_48">
							<div className="el_largeBtnWrap">
								{/* NOTE: エラー: is_error, 成功: is_success */}
								<button className={"el_largeBtn " + result} type="button" onClick={handleClickSave}>保存</button>
							</div>
						</div>
						<div className="bl_panel_row mt_16">
							<a className="el_largeBtnBlueInv" href={state.params.basePath  + "/two-factor-auth-setting"}>2段階認証の設定</a>
						</div>
						<div className="bl_panel_row mt_16">
							<a className="el_backLink" href="#" onClick={handleClickBack}>≪戻る</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Management;