import ja from 'date-fns/locale/ja';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from 'react-hook-form';
import { useTimer } from '../../../lib';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../lib/usePrompt';
import FormErrorMessage from '../FormErrorMessage';
registerLocale('ja', ja);

interface SettingFormProps {
	initValues: Record<string, string|number|boolean|Date>;
	onSave: (values: Record<string, string|number|boolean|Date>) => Promise<boolean>;
}

export function SettingForm(props: SettingFormProps): React.ReactElement {
	const { register, handleSubmit, getValues, errors, control, reset, formState: { isDirty } } = useForm({
		defaultValues: props.initValues,
	});
	usePrompt(PROMPT_CONFIRM_MESSAGE, isDirty);

	const [result, setResult] = React.useState('');

	const setTimer = useTimer();

	const handleClickSave = handleSubmit(async (values) => {
		const res = await props.onSave({...values});
		setResult(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setResult(''), 6000);
			reset(values);
		}
	});

	const numberLimit: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
		const i = parseInt(ev.target.value, 10);
		ev.target.value = isNaN(i) ? "" : Math.min(Math.max(0, i), 21).toString();
	};

	return (
		<>
			<div>
				<h1 className="el_pageTtl">メール送信設定</h1>
				<p className="el_pageDesc">メール送信者表示名やフォローメール送信タイミング等の設定ができます。</p>
				<div className="bl_row">
					<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
						<div className="bl_panel bl_panel__bt">
							<div className="bl_panel_row">
								<h2 className="el_lv3Heading">署名</h2>
								<textarea className="el_textarea" name="signature" rows={3} placeholder="ぷりふあ人形
〒700-0976 岡山県岡山市北区辰巳29-113
(TEL) 086-250-0092 (e-mail) rakuten@prefer.jp" defaultValue={""} ref={register}  />
							</div>
							<div className="bl_panel_row">
								<h2 className="el_lv3Heading">フォローメール送信タイミング</h2>
								<div>
									<span>商品発送後</span>
									<input className="el_inlineInputS" type="text" name="sendFollowAfterShippingDays" onChange={numberLimit} ref={register({ required: 'フォローメール送信タイミングの日付を入力してください' })} />
									<span>日後</span>
									<span style={{ display: 'inline-block' }}>
										<Controller
											control={control}
											rules={{ required: 'フォローメール送信タイミングの時間を入力してください' }}
											name="datePicker"
											render={({ onChange, value }) => (
												<ReactDatePicker
													locale="ja"
													onChange={onChange}
													selected={value}
													showTimeSelect
													showTimeSelectOnly
													timeIntervals={10}
													timeCaption="時間"
													dateFormat="HH:mm"
													timeFormat="HH:mm"
													/>
											)}
										/>
									</span>
									<span> に送信</span>
									<FormErrorMessage errors={errors} name="sendFollowAfterShippingDays" />
									<FormErrorMessage errors={errors} name="datePicker" />
									<input type="hidden" name="excludeMailAddress" ref={register} />
								</div>
							</div>
							<div className="el_largeBtnWrap">
								<button className={"el_largeBtn " + result} onClick={handleClickSave}>保存</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default SettingForm;