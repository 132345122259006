import * as React from 'react';
import { SubmitHandler, UseFormMethods } from 'react-hook-form';
import { toBR } from '../../../lib';
import { CONTACT_SUBJECTS, FormValues } from './Contact';

interface ContactConfirmProps {
	methods: UseFormMethods<FormValues>;
	shopName?: string;
	onSend: SubmitHandler<any>;
	onBack: () => void;
	btnClass: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ContactConfirm(props: ContactConfirmProps): React.ReactElement {
	const { methods, shopName, onSend, onBack, btnClass } = props;
	const [disabled, setDisabled] = React.useState<boolean>(false);
	const { handleSubmit, getValues }  = methods;
	const {
		name,
		email,
		subject,
		content,
		orderNumber,
	} = getValues();

	const handleClickSend = handleSubmit(async (values) => {
		if (disabled) {
			return;
		}

		setDisabled(true);
		await onSend(values);
		setDisabled(false);
	});

	const btnClasses = ['el_largeBtn', btnClass].filter(Boolean);

	return (
		<>
			<div className="bl_panel bl_panel__bt">
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">お問い合わせ店舗</h3>
					{shopName}
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">担当者名</h3>
					{name}
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">メールアドレス</h3>
					{email}
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">お問い合わせ種別</h3>
					{CONTACT_SUBJECTS[subject]}
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">お問い合わせ内容</h3>
					{toBR(content)}
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">注文番号</h3>
					{toBR(orderNumber)}
				</div>
				<div className="el_largeBtnWrap">
					<button className="el_largeBtnBlueInv" type="button" onClick={onBack}>修正する</button>
					<button className={btnClasses.join(' ')} type="button" onClick={handleClickSend} disabled={disabled}>送信</button>
				</div>
			</div>
		</>
	);
}
export default ContactConfirm;