import * as React from 'react';
import { useForm, UseFormMethods, useWatch } from 'react-hook-form';

type CheckState = {
	checked: boolean;
	indeterminate: boolean;
};
type Checks = Record<string | number, boolean>;
export type ListSelectorContextValue = {
	checkState: CheckState;
	handleChange: () => void;
	handleChangeAll: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	setChecks: (checked: Checks) => void;
} & Pick<UseFormMethods, 'register'|'getValues'>;

export const contextListSelector = React.createContext<ListSelectorContextValue | null>(null);

type UseListSelector = {
	getChecks: () => Checks;
	reset: (init?: Checks) => void;
	refresh: () => void;
};
export function useListSelector(): UseListSelector {
	const context = React.useContext(contextListSelector);

	const getChecks = React.useCallback(() => {
		return context?.getValues()?.check ?? {};
	}, [context]);

	const reset = React.useCallback((init?: Checks) => {
		if (!init) {
			const checks = getChecks();
			init = Object.fromEntries(Object.keys(checks).map(key => [key, false]));
		}
		context?.setChecks(init);
	}, [context]);

	const refresh = React.useCallback(() => {
		context?.handleChange();
	}, [context]);

	return {
		getChecks,
		reset,
		refresh,
	};
}

interface ListSelectorContextProps {
	children?: React.ReactNode;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ListSelectorContext: React.FC<ListSelectorContextProps> = (props) => {
	const { reset, getValues, register, control } = useForm({
		shouldUnregister: false,
	});
	const [checkState, setCheckState] = React.useState<CheckState>({
		checked: false,
		indeterminate: false,
	});

	const check = React.useCallback(() => {
		const checks = Object.values(getValues().check ?? {});
		const isAll = checks.length > 0 && checks.every(Boolean);
		const isPartial = !isAll && checks.some(Boolean);
		return {
			checked: isAll,
			indeterminate: isPartial,
		};
	}, [getValues]);

	const handleChange = React.useCallback(() => {
		setCheckState(prev => {
			const state = check();
			if (prev.checked == state.checked && prev.indeterminate == state.indeterminate) {
				return prev;
			}
			return state;
		});
	}, [setCheckState, check]);

	const setChecks = React.useCallback((checked: Checks) => {
		reset({ check: checked });
		handleChange();
	}, [reset, handleChange]);

	const handleChangeAll = React.useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
		const checks = getValues().check ?? {};
		setChecks(Object.fromEntries(Object.keys(checks).map(key => [key, ev.currentTarget.checked])));
	}, [getValues, setChecks]);

	const checks = useWatch<Checks>({
		control,
		name: 'check',
		defaultValue: {},
	});
	React.useEffect(() => {
		handleChange();
	}, [checks]);

	const value = React.useMemo(() => ({
		checkState,
		handleChange,
		handleChangeAll,
		setChecks,
		register,
		getValues,
	}), [checks, checkState, handleChange, handleChangeAll, register, getValues]);

	return (
		<contextListSelector.Provider value={value}>
			{props.children}
		</contextListSelector.Provider>
	);
};
export default ListSelectorContext;