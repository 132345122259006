import { MailTemplate, NIL, ShopEntity } from '@sasagase/types';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getShopEntity, getTemplate, postTemplate, putTemplate } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { TemplateEditForm } from './TemplateEditForm';

interface TemplateEditProps {}
type TemplateEditParams = {
	templateId?: string;
	flag?: string;
}

const initValues = {
	name: '',
	category: '',
	bodyHeader: '{{orderFull}}　様　(注文番号:　{{ordernumber}}　)',
	bodyElement: [],
	signature: '',
	email: ''
};
type FormValues = typeof initValues;

function toMailTemplate(id: string|undefined, values: Partial<FormValues>): MailTemplate {
	const bodys = [
		{ type: "header", content: values.bodyHeader },
		...(values.bodyElement || [])
	];
	return new MailTemplate({
		id: id || NIL,
		name: values.name,
		category: values.category,
		body: bodys,
		signature: values.signature,
		isFavorite: false, // ここでの更新時は無視する
	});
}
function toValues(template: MailTemplate): FormValues {
	const bodyHeaderData: any = template.body.find((val: any) => val.type === "header");
	return {
		...initValues,
		name: template.name,
		category: template.category,
		bodyHeader: bodyHeaderData ? bodyHeaderData.content : '',
		bodyElement: template.body.filter((val: any) => val.type !== "header") as [],
		signature: template.signature,
	};
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TemplateEdit(props: TemplateEditProps): React.ReactElement | null {
	const params = useParams<TemplateEditParams>();
	const isNew = params.templateId == 'new';
	const isCopy = params.flag === 'copy';

	const navigate = useNavigate();
	const callAPI = useAPI();
	const [state] = useAppState();
	const [values, setValues] = React.useState<FormValues | null>(null);

	React.useEffect(() => {
		if (!params.templateId || isNew || !state.shop) {
			return;
		}
		const email = String(state.shop.mail);
		return callAPI(getTemplate(state.params.shopId, params.templateId), (err, result) => {
			if (err) {
				return;
			}
			const template = new MailTemplate(result.data);
			if (isCopy) {
				template.name += ' のコピー';
			}
			setValues({
				...toValues(template),
				email: email,
			});
		});
	}, [state.params.shopId, params.templateId, state.shop]);

	React.useEffect(() => {
		if (!state.shop || !isNew) {
			return;
		}
		const email = String(state.shop.mail);
		return callAPI(getShopEntity(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const shopEntity = new ShopEntity(result.data);
			setValues({
				...initValues,
				signature: shopEntity.signature,
				email: email,
			});
		});
	}, [state.shop]);

	const handleSave = async (values: Record<string, any>) => {
		const id = isNew || isCopy ? '' : params.templateId;
		const template = toMailTemplate(id, values);
		try {
			if (!id) {
				const res = await callAPI(postTemplate(state.params.shopId, template));
				const id = res.data;
				navigate(state.params.basePath + '/mail/template/' + id, {state: {newId: id}, replace: true});
				return true;
			} else {
				const res = await callAPI(putTemplate(state.params.shopId, template.id, template));
				return Boolean(res);
			}
		} catch (err) {
			return false;
		}
	};
	
	if (!values) {
		return null; // loading
	}

	return <TemplateEditForm initValues={values} onSave={handleSave} />;
}
export default TemplateEdit;
