import { RakutenItem } from '@sasagase/types';
import * as React from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { UseFormMethods } from 'react-hook-form';
import sanitizeHtml from 'sanitize-html';
import { searchItems } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { nonNegative } from '../../../lib';

const CONFIRM_MANAGE_NUMBWER_NUM = 10;	// ItemAPI経由で取得した商品管理番号をフォーム追加前に確認する件数

export const sanitizeSku = (html: string) => {
	return sanitizeHtml(html, {
		disallowedTagsMode: 'escape',
		allowedTags: [ 'span', 'br', 'div'],
		allowedAttributes: {
			span: [ 'contenteditable', 'data-*' ]
		},
	});
};

export interface GroupEditRowFormValues {
	isAll: string;
	inputSkus: string;
	skus: string;
	inputExcludeSkus: string;
	excludeSkus: string;
	childGroupIds: string[];
	excludeChildGroupIds: string[];
	group: Record<string, boolean>;
	excludeGroup: Record<string, boolean>;
	itemName: string;
	upper: string;
	lower: string;
	excludeItemName: string;
	excludeUpper: string;
	excludeLower: string;
}
const nameList: ReadonlyArray<keyof GroupEditRowFormValues> = [
	'isAll',
	'inputSkus',
	'skus',
	'inputExcludeSkus',
	'excludeSkus',
	'childGroupIds',
	'excludeChildGroupIds',
	'group',
	'excludeGroup',
	'itemName',
	'upper',
	'lower',
	'excludeItemName',
	'excludeUpper',
	'excludeLower'
] as const;

const defaultToName = (name: string) => name;

const groupTagName = 'span';

interface GroupEditRowProps {
	methods: UseFormMethods<any>;
	groupNames: Record<string, string>;
	toName?: (name: string) => string;
}

export function GroupEditRow(props: GroupEditRowProps): React.ReactElement {
	const { register, watch, setValue, getValues, trigger } = props.methods;
	const toName = props.toName || defaultToName;
	
	const callAPI = useAPI();
	const [state] = useAppState();
	const [tab, setTab] = React.useState('group');
	const [excludeTab, setExcludeTab] = React.useState('group');
	const [btnState, setBtnState] = React.useState(false);
	const refSku = React.useRef<null|HTMLDivElement>(null);
	const refExcludeSku = React.useRef<null|HTMLDivElement>(null);

	const names: Record<typeof nameList[number], string> = React.useMemo(() => {
		const ret: Record<string, string> = {};
		for (const name of nameList) {
			ret[name] = toName(name);
		}
		return ret;
	}, [toName]);

	React.useEffect(() => {
		register(names.childGroupIds);
		register(names.excludeChildGroupIds);
	}, [register, names]);

	const childGroupIds: GroupEditRowFormValues['childGroupIds'] = watch(names.childGroupIds);
	const excludeChildGroupIds: GroupEditRowFormValues['excludeChildGroupIds'] = watch(names.excludeChildGroupIds);

	const getGroupTag = (id: string, name: string) => {
		return `<${groupTagName} contenteditable="${false}" data-groupid="${id}"><${groupTagName} data-removeid="${id}"></${groupTagName}>${name}</${groupTagName}>`;
	};

	React.useEffect(() => {
		const prev = getValues(names.skus);
		setValue(names.inputSkus, childGroupIds.map(id => getGroupTag(id, props.groupNames[id])) + prev);
		const prevExclude = getValues(names.excludeSkus);
		setValue(names.inputExcludeSkus, excludeChildGroupIds.map(id => getGroupTag(id, props.groupNames[id])) + prevExclude);
	}, []);

	const triggerAll = () => {
		trigger(nameList.map(name => names[name]));
	};
	const handleClickTab = (type: 'include'|'exclude', name: string): React.MouseEventHandler => () => {
		if (type === 'include') setTab(name);
		if (type === 'exclude') setExcludeTab(name);
	};
	const handleClickRemoveGroup = (type: 'include'|'exclude') => (ev: any) => {
		const target = ev.target.closest('[data-removeid]');
		if (!target) {
			return;
		}

		const swChildGroupIds = type === 'include' ? childGroupIds : excludeChildGroupIds;
		const swNameChildGroupIds = type === 'include' ? names.childGroupIds : names.excludeChildGroupIds;
		const swNameInputSkus = type === 'include' ? names.inputSkus : names.inputExcludeSkus;

		const removeId = target.dataset.removeid;
		const modified = swChildGroupIds.filter(id => id != removeId);
		setValue(swNameChildGroupIds, modified);

		const prev: string = getValues(swNameInputSkus);
		setValue(swNameInputSkus, prev.replace(getGroupTag(removeId, props.groupNames[removeId]), ''));
		triggerAll();
	};
	const handleClickAdd = async (type: 'include'|'exclude') => {
		const swTab = type === 'include' ? tab : excludeTab;
		const swChildGroupIds = type === 'include' ? childGroupIds : excludeChildGroupIds;
		const swNameChildGroupIds = type === 'include' ? names.childGroupIds : names.excludeChildGroupIds;
		const swNameGroup = type === 'include' ? names.group : names.excludeGroup;
		const swNameSkus = type === 'include' ? names.skus : names.excludeSkus;
		const swNameInputSkus = type === 'include' ? names.inputSkus : names.inputExcludeSkus;
		const swNameItemName = type === 'include' ? names.itemName : names.excludeItemName;
		const swNameUpper = type === 'include' ? names.upper : names.excludeUpper;
		const swNameLower = type === 'include' ? names.lower : names.excludeLower;

		if (swTab == 'group') {
			const addingIds = Object.entries(watch(swNameGroup)) // getValues(names.group) だと上手く取得できないので
				.filter(([, checked]) => checked)
				.map(([id]) => id);
			setValue(swNameChildGroupIds, [...swChildGroupIds, ...addingIds]);
			setValue(swNameGroup, Object.fromEntries(addingIds.map(id => [id, false])));
			const prev = getValues(swNameInputSkus);
			setValue(swNameInputSkus, prev + addingIds.map(id => getGroupTag(id, props.groupNames[id])).join(''));
		} else {
			if (!state.params.shopId) {
				return;
			}
			const itemName = getValues(swNameItemName);
			const upper = getValues(swNameUpper);
			const lower = getValues(swNameLower);
			if (!itemName) {
				alert('商品名を入力してください');
				return;
			} else if (!upper && !lower) {
				alert('指定金額を入力してください');
				return;
			}
			if ((Number(upper) || Infinity) < (Number(lower) || -Infinity)) {
				return;
			}

			// 楽天のAPI経由で商品情報を取得し、対象商品に追記
			setBtnState(true);
			const res = await callAPI(searchItems(state.params.shopId, itemName, lower, upper));
			const items: RakutenItem[] = res.data || [];
			if (items.length > 0) {
				const manageNumbers = items.slice(0, CONFIRM_MANAGE_NUMBWER_NUM).map((item) => item.manageNumber).join(`\n`) +
										(items.length > CONFIRM_MANAGE_NUMBWER_NUM ? `\n... 他${items.length - CONFIRM_MANAGE_NUMBWER_NUM}件` : ``);
				if (window.confirm(`以下${items.length}件の商品を追加します。よろしいですか？\n\n${manageNumbers}`)) {
					let skus = getValues(swNameSkus);
					skus = (skus ? skus + `,` : '') + items.map((item) => item.manageNumber).join(`,`);
					setValue(swNameSkus, skus);
					let inputSkus = getValues(swNameInputSkus);
					inputSkus = (inputSkus ? inputSkus + `,` : '') + items.map((item) => item.manageNumber).join(`,`);
					setValue(swNameInputSkus, inputSkus);
					alert(`${items.length}件の商品を追加しました`);
				}
			} else {
				alert('該当する商品がありませんでした');
			}
			setBtnState(false);
		}
		triggerAll();
	};

	const filterSkus = (nodes?: NodeListOf<ChildNode>) => {
		const skus: string[] = [];
		if (!nodes) return skus;
		nodes.forEach(child => {
			if (child.nodeName !== groupTagName.toUpperCase()) {
				skus.push(String(child.nodeName == '#text' ? child.nodeValue : child.textContent));
			}
		});
		return skus;
	};

	const handleChangeSku = (type: 'include'|'exclude') => (ev: ContentEditableEvent) => {
		const swRef = type === 'include' ? refSku : refExcludeSku;
		const swNameChildGroupIds = type === 'include' ? names.childGroupIds : names.excludeChildGroupIds;
		const swNameGroup = type === 'include' ? names.group : names.excludeGroup;
		const swNameSkus = type === 'include' ? names.skus : names.excludeSkus;
		const swNameInputSkus = type === 'include' ? names.inputSkus : names.inputExcludeSkus;

		const skus = filterSkus(swRef.current?.childNodes);
		const groupTags = Array.from(swRef.current?.querySelectorAll(groupTagName) || []);
		const groupIds = groupTags.map(g => g.dataset.groupid).filter(Boolean);

		setValue(swNameChildGroupIds, groupIds);
		setValue(swNameGroup, Object.fromEntries(groupIds.map(id => [id, false])));
		setValue(swNameSkus, skus.join(',').split(/\s*[,\t\n]\s*/).filter(Boolean).join(','));
		setValue(swNameInputSkus, ev.target.value);
	};

	const handlePaste = (ev: React.ClipboardEvent<HTMLDivElement>) => {
		ev.preventDefault();

		const text = ev.clipboardData.getData("text/plain");

		const selection = window.getSelection();
		if (!selection || !selection.rangeCount) {
			return;
		}
		selection.deleteFromDocument();

		const range = selection.getRangeAt(0);
		const node = document.createTextNode(text);
		range.insertNode(node);
		range.setStartAfter(node);
		range.setEndAfter(node);
		selection.removeAllRanges();
		selection.addRange(range);
	};

	const handleBlur = (type: 'include'|'exclude') => () => {
		const swRef = type === 'include' ? refSku : refExcludeSku;
		const swNameSkus = type === 'include' ? names.skus : names.excludeSkus;
		const swNameInputSkus = type === 'include' ? names.inputSkus : names.inputExcludeSkus;

		// 入力した商品管理番号(HTML含む)をサニタイズ
		const sanitizedValue = sanitizeSku(String(swRef.current?.innerHTML));
		setValue(swNameInputSkus, sanitizedValue);

		// サニタイズ後の文字列から商品管理番号を抽出
		const dom = new DOMParser().parseFromString(sanitizedValue, 'text/html');
		const skus = filterSkus(dom.querySelector('body')?.childNodes);
		setValue(swNameSkus, skus.join(',').split(/\s*[,\t\n]\s*/).filter(Boolean).join(','));
	};

	const btnName = btnState ? '検索中' : '追加';
	const skuDisabled = watch(names.isAll) == 'true';

	return (
		<>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">対象商品指定方法</h3>
				<label className="bl_label">
					<input type="radio" name={names.isAll} value="false" ref={register} />
					<span>個別で商品を指定する</span>
				</label>
				<br />
				<label className="bl_label">
					<input type="radio" name={names.isAll} value="true" ref={register} />
					<span>全商品を対象にする</span>
				</label>
			</div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">対象商品</h3>
				<div className="bl_selectProduct">
					<div className="bl_selectProduct_selected">
						<ContentEditable
							innerRef={refSku}
							className={`textarea ${skuDisabled && 'disabled'}`}
							html={skuDisabled ? '' : watch(names.inputSkus)}
							onChange={handleChangeSku('include')}
							onClick={handleClickRemoveGroup('include')}
							onPaste={handlePaste}
							onBlur={handleBlur('include')}
							placeholder={"対象商品の【商品管理番号】を入力"}
							disabled={skuDisabled}
							/>
						<p className="fs_12 txt_blue mt_8">※半角改行またはコンマ区切り</p>
					</div>
					<div className="bl_selectProduct_selection">
						<ul className="bl_selectProduct_selectionTab">
							<li className={tab == 'group' ? 'is_active' : ''} data-type="group" onClick={handleClickTab('include', 'group')}>登録グループを追加</li>
							<li className={tab == 'price' ? 'is_active' : ''} data-type="price" onClick={handleClickTab('include', 'price')}>金額を指定して追加</li>
						</ul>
						{tab == 'group' &&
							<div className="bl_selectProduct_selectionInner bl_selectProduct_selectionInner__group is_active">
								<ul className="bl_selectProduct_group">
									{Object.entries(props.groupNames).map(([id, name]) =>
										(!childGroupIds.includes(id) && !excludeChildGroupIds.includes(id)) &&
										<li key={id}><input className="el_checkMark" type="checkbox" name={`${names.group}.${id}`} ref={register} disabled={skuDisabled} />{name}</li>
									)}
								</ul>
							</div>
						}
						{tab == 'price' &&
							<div className="bl_selectProduct_selectionInner bl_selectProduct_selectionInner__price is_active">
								<div className="bl_selectProduct_price">
									<span><input className="el_inlineInputL textL" type="text" name={names.itemName} ref={register} disabled={skuDisabled} />商品名(部分一致)</span>
									<span><input className="el_inlineInputL" type="text" name={names.lower} onChange={nonNegative} ref={register} disabled={skuDisabled} />円(税込)以上</span>
									<span><input className="el_inlineInputL" type="text" name={names.upper} onChange={nonNegative} ref={register} disabled={skuDisabled} />円(税込)以下</span>
									<span>指定した商品名と金額の範囲の商品を検索し追加します</span>
								</div>
							</div>
						}
						<div className="txt_alignRight">
							<button className="el_btnInv mt_8" type="button" onClick={() => handleClickAdd('include')} disabled={btnState || skuDisabled}>{btnName}</button>
						</div>
					</div>
				</div>
			</div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">除外商品</h3>
				<div className="bl_selectProduct">
					<div className="bl_selectProduct_selected">
						<ContentEditable
							innerRef={refExcludeSku}
							className='textarea'
							html={watch(names.inputExcludeSkus)}
							onChange={handleChangeSku('exclude')}
							onClick={handleClickRemoveGroup('exclude')}
							onPaste={handlePaste}
							onBlur={handleBlur('exclude')}
							placeholder={"除外商品の【商品管理番号】を入力"}
							/>
						<p className="fs_12 txt_blue mt_8">※半角改行またはコンマ区切り</p>
					</div>
					<div className="bl_selectProduct_selection">
						<ul className="bl_selectProduct_selectionTab">
							<li className={excludeTab == 'group' ? 'is_active' : ''} data-type="group" onClick={handleClickTab('exclude', 'group')}>登録グループを追加</li>
							<li className={excludeTab == 'price' ? 'is_active' : ''} data-type="price" onClick={handleClickTab('exclude', 'price')}>金額を指定して追加</li>
						</ul>
						{excludeTab == 'group' &&
							<div className="bl_selectProduct_selectionInner bl_selectProduct_selectionInner__group is_active">
								<ul className="bl_selectProduct_group">
									{Object.entries(props.groupNames).map(([id, name]) =>
										(!childGroupIds.includes(id) && !excludeChildGroupIds.includes(id)) &&
										<li key={id}><input className="el_checkMark" type="checkbox" name={`${names.excludeGroup}.${id}`} ref={register} />{name}</li>
									)}
								</ul>
							</div>
						}
						{excludeTab == 'price' &&
							<div className="bl_selectProduct_selectionInner bl_selectProduct_selectionInner__price is_active">
								<div className="bl_selectProduct_price">
									<span><input className="el_inlineInputL textL" type="text" name={names.excludeItemName} ref={register} />商品名(部分一致)</span>
									<span><input className="el_inlineInputL" type="text" name={names.excludeLower} onChange={nonNegative} ref={register} />円(税込)以上</span>
									<span><input className="el_inlineInputL" type="text" name={names.excludeUpper} onChange={nonNegative} ref={register} />円(税込)以下</span>
									<span>指定した商品名と金額の範囲の商品を検索し追加します</span>
								</div>
							</div>
						}
						<div className="txt_alignRight">
							<button className="el_btnInv mt_8" type="button" onClick={() => handleClickAdd('exclude')} disabled={btnState}>{btnName}</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default GroupEditRow;