import { Campaign, CouponReward, ItemReward, Reward } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { deleteRewards, getCampaigns, getRewards } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { InsertSeparator } from "../../../lib";

interface RewardListProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function RewardList(props: RewardListProps): React.ReactElement {
	const [state] = useAppState();
	const callAPI = useAPI();
	const { register, setValue, getValues } = useForm();
	const [rewards, setRewards] = React.useState<null|Reward[]>(null);
	const [coupons, setCoupons] = React.useState<null|Reward[]>(null);
	const [campaigns, setCampaigns] = React.useState<null|Map<string, CampaignProgresses>>(null);
	const location = useLocation();
	const urlParamsTab = new URLSearchParams(location.search).get("tab");
	const [tab, setTab] = React.useState<string>(urlParamsTab === 'present' || urlParamsTab === 'coupon' ? urlParamsTab : 'present');
	
	const checkAllReward = React.useRef<null|HTMLInputElement>(null);
	const checkAllCoupon = React.useRef<null|HTMLInputElement>(null);
	
	interface ExecutingSort {
		first: Reward[],
		end: Reward[],
	}
	interface CampaignProgresses {
		ready: Set<string>,
		inProgress: Set<string>,
	}

	React.useEffect(() => {
		return callAPI(getCampaigns(state.params.shopId), (err, result) => {
			if (err) {
				return null;
			}
			const map = new Map<string, CampaignProgresses>();
			const loadCampaigns: Campaign[] = result.data.map((obj: Record<string, unknown>) => {
				return Campaign.create(obj);
			});
			const addUsingCampaign = (itemRewardId: string, state: string, campaignName: string) => {
				if (state === "ready") {
					let campaignProgresses = map.get(itemRewardId);
					if (!campaignProgresses) {
						campaignProgresses = { ready: new Set<string>(), inProgress: new Set<string>() };
						map.set(itemRewardId, campaignProgresses)
					}
					campaignProgresses.ready.add(campaignName);
				} else if (state === "inProgress") {
					let campaignProgresses = map.get(itemRewardId);
					if (!campaignProgresses) {
						campaignProgresses = { ready: new Set<string>(), inProgress: new Set<string>() };
						map.set(itemRewardId, campaignProgresses)
					}
					campaignProgresses.inProgress.add(campaignName);
				}
			};
			loadCampaigns.map(campaign => {
				const state = campaign.getState();
				const name = campaign.name;
				
				for (const itemReward of campaign.rewards) {
					addUsingCampaign(itemReward.id, state, name);
				}
			});
			setCampaigns(map);
	})}, []);
	React.useEffect(() => {
		if (rewards) {
			return;
		}
		if (!campaigns) {
			return;
		}

		return callAPI(getRewards(state.params.shopId), (err, result) => {
			if (err) {
				return null;
			}
			const rewards: Reward[] = result.data.map((obj: Record<string, unknown>) => {
				return Reward.create(obj);
			});

			const sortObj: ExecutingSort = { first: [], end: [] };
			for (const reward of rewards) {
				if (campaigns.has(reward.id)) {
					sortObj.first.push(reward);
				} else {
					sortObj.end.push(reward);
				}
			}
			const sortedReward = [...sortObj.first, ...sortObj.end];

			setRewards(sortedReward.filter(reward => reward.isCoupon == false ));
			setCoupons(sortedReward.filter(reward => reward.isCoupon == true ));
		});
	}, [rewards, campaigns]);

	const handleChangeCheckAllReward = () => {
		if (!checkAllReward.current || !rewards) {
			return;
		}
		setValue(`checkReward`, Array(rewards.length).fill(checkAllReward.current.checked));
	};
	const handleChangeCheckAllCoupon = () => {
		if (!checkAllCoupon.current || !coupons) {
			return;
		}
		setValue(`checkCoupon`, Array(coupons.length).fill(checkAllCoupon.current.checked));
	};
	const handleChangeCheckReward = () => {
		if (!checkAllReward.current) {
			return;
		}
		// React hook formの仕様上はできるはずだが、なぜか getValues('checkReward') だと取得できない
		const checks = getValues().checkReward;
		const isAll = Object.values(checks).every(Boolean);
		const isPartial = !isAll && Object.values(checks).some(Boolean);
		checkAllReward.current.checked = isAll;
		checkAllReward.current.indeterminate = isPartial;
	};
	const handleChangeCheckCoupon = () => {
		if (!checkAllCoupon.current) {
			return;
		}
		// React hook formの仕様上はできるはずだが、なぜか getValues('checkCoupon') だと取得できない
		const checks = getValues().checkCoupon;
		const isAll = Object.values(checks).every(Boolean);
		const isPartial = !isAll && Object.values(checks).some(Boolean);
		checkAllCoupon.current.checked = isAll;
		checkAllCoupon.current.indeterminate = isPartial;
	};

	const handleClickDelete = async () => {
		if (!rewards || !coupons) {
			return;
		}
		const checksReward = getValues().checkReward || [];
		const checksCoupon = getValues().checkCoupon || [];
		const checks: string[] = [...checksReward, ...checksCoupon];
		const allReward: Reward[] = [...rewards, ...coupons];
		const names = checks.map((id) => {
			const reward = allReward.find((reward) => reward.id === id);
			if (reward) {
				const rewardType = reward.isCoupon ? 'クーポン' : 'プレゼント品';
				return `・${rewardType} ${reward.name}`;
			}
		}).filter((name) => Boolean(name));
		if (names.length <= 0) {
			alert(`削除するプレゼント品・クーポンを選択してください。`);
			return;
		}
		if (window.confirm(`以下のプレゼント品・クーポンを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			for (const check of checks) {
				if (!check) {
					continue;
				}
				const id = check;
				const res = await callAPI(deleteRewards(state.params.shopId, id));
				if (!res.data) {
					const reward = allReward.find((reward) => reward.id === id);
					if (reward) {
						const rewardType = reward.isCoupon ? 'クーポン' : 'プレゼント品';
						alert(`${rewardType} ${reward.name} の削除に失敗しました。この${rewardType}は使用中です。`);
					}
				}
			}
			setRewards(null);
			setCoupons(null);
		}
	};

	const toRewardEditURL = (rewardId: string): string => {
		return state.params.basePath + '/reward/reward/' + rewardId + '?tab=' + tab;
	};
	const toRewardCopyURL = (rewardId: string): string => {
		return state.params.basePath + '/reward/reward/' + rewardId + '/copy?tab=' + tab;
	};
	const toRewardQuantity = (reward: Reward) => {
		if (!(reward instanceof ItemReward)) {
			return '';
		}
		return reward.isUnlimited ? '無制限' : reward.quantity;
	};
	const toRewardImage = (reward: Reward) => {
		if (reward instanceof ItemReward) {
			if (reward.image) {
				return <img src={`/storage/${state.params.shopId}/${reward.image}`} />;
			}
		} else if (reward instanceof CouponReward) {
			if (reward.couponImageType == 'template' || reward.couponImageType == 'user') {
				return <img src={`${reward.cabinetImageUrl}`} />;
			}
		}
		return null;
	};

	const tabPresentClass = tab == 'present' ? 'is_active' : '';
	const tabCouponClass = tab == 'coupon' ? 'is_active' : '';

	return (
		<>
			<div>
				<h1 className="el_pageTtl">レビュー特典登録</h1>
				<p className="el_pageDesc">プレゼント品・クーポンの新規登録、確認・編集ができます。</p>
				<ul className="bl_panelTab">
					<li className={`bl_panelTab_item ${tabPresentClass}`} data-privilege="present" onClick={() => setTab('present')}>プレゼント品</li>
					<li className={`bl_panelTab_item ${tabCouponClass}`} data-privilege="coupon" onClick={() => setTab('coupon')}>クーポン</li>
				</ul>
				{tab === 'present' &&
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_headerFooter">
							<Link className="el_btn el_btn__plus" type="button" to={toRewardEditURL('new')}>新規追加</Link>
							<button className="el_btnBlueInv" type="button" onClick={handleClickDelete}>削除</button>
						</div>
						{rewards && <>
							<table className="bl_table bl_table__privilege">
								<thead className="bl_table_head">
									<tr>
										<th><input className="el_checkMark" type="checkbox" name='checkAll' ref={checkAllReward} onChange={handleChangeCheckAllReward} /></th>
										<th>プレゼント品名</th>
										<th>在庫数</th>
										<th>画像</th>
										<th>登録されているキャンペーン</th>
									</tr>
								</thead>
								<tbody className="bl_table_body">
									{rewards.map((reward, idx) =>
										<tr key={reward.id}>
											<td><input className="el_checkMark" type="checkbox" name={`checkReward[${idx}]`} value={reward.id} ref={register} onChange={handleChangeCheckReward} /></td>
											<td>{reward.name}</td>
											<td>{toRewardQuantity(reward)}</td>
											<td>{toRewardImage(reward)}</td>
											<td className="bl_nestTableWrap">
												{campaigns &&
													<table className="bl_nestTable">
														<tbody>
															<tr>
																<th>実施前</th>
																<td>
																<InsertSeparator>
																	{campaigns.get(reward.id)?.ready}
																</InsertSeparator>
																</td>
															</tr>
															<tr>
																<th>実施中</th>
																<td>
																<InsertSeparator>
																	{campaigns.get(reward.id)?.inProgress}
																</InsertSeparator>
																</td>
															</tr>
														</tbody>
													</table>
												}
												<div className="bl_table_btnWrap">
													<Link className="el_btnInv" type="button" to={toRewardEditURL(reward.id)}>編集</Link>
													<Link className="el_btnInv" type="button" to={toRewardCopyURL(reward.id)}>複製</Link>
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
							<p className="el_resultCount">全{rewards.length}件</p>
						</>}
					</div>
				}
				{tab === 'coupon' &&
					<div className="bl_panel bl_panel__bt">
					<div className="bl_panel_headerFooter">
						<Link className="el_btn el_btn__plus" type="button" to={toRewardEditURL('new')}>新規追加</Link>
						<button className="el_btnBlueInv" type="button" onClick={handleClickDelete}>削除</button>
					</div>
					{coupons && <>
						<table className="bl_table bl_table__coupon">
							<thead className="bl_table_head">
								<tr>
									<th><input className="el_checkMark" type="checkbox" name='checkAll' ref={checkAllCoupon} onChange={handleChangeCheckAllCoupon} /></th>
									<th>クーポン名</th>
									<th>画像</th>
									<th>登録されているキャンペーン</th>
								</tr>
							</thead>
							<tbody className="bl_table_body">
								{coupons.map((reward, idx) =>
									<tr key={reward.id}>
										<td><input className="el_checkMark" type="checkbox" name={`checkCoupon[${idx}]`} value={reward.id} ref={register} onChange={handleChangeCheckCoupon} /></td>
										<td>{reward.name}</td>
										<td>{toRewardImage(reward)}</td>
										<td className="bl_nestTableWrap">
											{campaigns &&
												<table className="bl_nestTable">
													<tbody>
														<tr>
															<th>実行前</th>
															<td>
																<InsertSeparator>
																	{campaigns.get(reward.id)?.ready}
																</InsertSeparator>
															</td>
														</tr>
														<tr>
															<th>実施中</th>
															<td>
																<InsertSeparator>
																	{campaigns.get(reward.id)?.inProgress}
																</InsertSeparator>
															</td>
														</tr>
													</tbody>
												</table>
											}
											<div className="bl_table_btnWrap">
												<Link className="el_btnInv" type="button" to={toRewardEditURL(reward.id)}>編集</Link>
												<Link className="el_btnInv" type="button" to={toRewardCopyURL(reward.id)}>複製</Link>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
						<p className="el_resultCount">全{coupons.length}件</p>
					</>}
				</div>
				}
			</div>
		</>
	);
}
export default RewardList;