import { Notices } from '@sasagase/types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { toBR } from '../../../lib';

interface WarningListItemProps {
	content: Notices.Content;
	basePath: string;
}

export function WarningListItem(props: WarningListItemProps): React.ReactElement {
	return (
		<li>
			<h3><Link to={props.basePath + '/account/rakuten-setting'}>{props.content.title}</Link></h3>
			<p>{toBR(props.content.getContent())}</p>
		</li>
	);
}
export default WarningListItem;