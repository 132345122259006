import { Campaign, ItemGroup } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { deleteGroup, getCampaigns, getGroups } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { InsertSeparator, toBR } from "../../../lib";

export const getItemGroupRequireDescList = (group: ItemGroup) => {
	const reqs = group.getRequires();
	const desc: string[] = [];
	if (reqs.some(req => req.isAll)) {
		desc.push(`対象:全商品`);
	} else {
		const skus = reqs.reduce((sum, req) => new Set([...sum, ...req.sku]), new Set());
		if (skus.size) desc.push(`対象:${skus.size}商品`);
	}
	if (reqs.some(req => req.excludeSku.size || req.excludeRequires.length)) {
		desc.push(`除外:条件あり`);
	}
	return desc;
}

interface GroupListProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function GroupList(props: GroupListProps): React.ReactElement {
	const [state] = useAppState();
	const callAPI = useAPI();
	const [groups, setGroups] = React.useState<null|ItemGroup[]>(null);
	const [campaigns, setCampaigns] = React.useState<null|Map<string, CampaignProgresses>>(null);
	const { register, setValue, getValues } = useForm();
	const checkAll = React.useRef<null|HTMLInputElement>(null);

	interface ExecutingSort {
		first: ItemGroup[],
		end: ItemGroup[],
	}
	interface CampaignProgresses {
		ready: Set<string>,
		inProgress: Set<string>,
	}

	React.useEffect(() => {
		return callAPI(getCampaigns(state.params.shopId), (err, result) => {
			if (err) {
				return null;
			}
			const map = new Map<string, CampaignProgresses>();
			const loadCampaigns: Campaign[] = result.data.map((obj: Record<string, unknown>) => {
				return Campaign.create(obj);
			});
			const addUsingCampaign = (itemGroupId: string, state: string, campaignName: string) => {
				if (state === "ready") {
					let campaignProgresses = map.get(itemGroupId);
					if (!campaignProgresses) {
						campaignProgresses = { ready: new Set<string>(), inProgress: new Set<string>() };
						map.set(itemGroupId, campaignProgresses)
					}
					campaignProgresses.ready.add(campaignName);
				} else if (state === "inProgress") {
					let campaignProgresses = map.get(itemGroupId);
					if (!campaignProgresses) {
						campaignProgresses = { ready: new Set<string>(), inProgress: new Set<string>() };
						map.set(itemGroupId, campaignProgresses)
					}
					campaignProgresses.inProgress.add(campaignName);
				}
			};
			loadCampaigns.map(campaign => {
				const state = campaign.getState();
				const name = campaign.name;
				for (const itemGroup of campaign.itemGroup.getDecendants()) {
					addUsingCampaign(itemGroup.id, state, name);
				}
			});
			setCampaigns(map);
	})}, []);
	React.useEffect(() => {
		if (!state.params.shopId || groups) {
			return;
		}
		if (!campaigns) {
			return;
		}

		return callAPI(getGroups(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const groups = result.data.map((obj: Record<string, unknown>) => new ItemGroup(obj));

			const sortObj: ExecutingSort = { first: [], end: [] };
			for (const group of groups) {
				if (campaigns.has(group.id)) {
					sortObj.first.push(group);
				} else {
					sortObj.end.push(group);
				}
			}
			const sortedGroups = [...sortObj.first, ...sortObj.end];
			setGroups(sortedGroups);
		});
	}, [state.params.shopId, groups, campaigns]);

	const handleChangeCheckAll = () => {
		if (!checkAll.current || !groups) {
			return;
		}
		setValue('check', Array(groups.length).fill(checkAll.current.checked));
	};
	const handleChangeCheck = () => {
		if (!checkAll.current) {
			return;
		}
		// React hook formの仕様上はできるはずだが、なぜか getValues('check') だと取得できない
		const checks = getValues().check;
		const isAll = Object.values(checks).every(Boolean);
		const isPartial = !isAll && Object.values(checks).some(Boolean);
		checkAll.current.checked = isAll;
		checkAll.current.indeterminate = isPartial;
	};

	const handleClickDelete = async () => {
		if (!groups) {
			return;
		}
		const checks: boolean[] = getValues().check || [];
		const names: string[] = Array.from(checks.entries()).filter(([, check]) => check).map(([idx]) => `・${groups[idx].name}`);
		if (names.length <= 0) {
			alert(`削除する商品グループを選択してください。`);
			return;
		}
		if (window.confirm(`以下の商品グループを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			for (const [idx, check] of checks.entries()) {
				if (!check) {
					continue;
				}
				const id = groups[idx].id;
				const res = await callAPI(deleteGroup(state.params.shopId, id));
				if (!res.data) {
					alert(`商品グループ ${groups[idx].name} の削除に失敗しました。この商品グループは使用中です。`);
				}
			}
			setGroups(null);
		}
	};

	const toRequire = (group: ItemGroup) => {
		return toBR(getItemGroupRequireDescList(group).join('\n'));
	}

	const toEditURL = (groupId: string) => {
		return state.params.basePath + '/reward/group/' + groupId;
	};
	const toCopyURL = (groupId: string) => {
		return state.params.basePath + '/reward/group/' + groupId + '/copy';
	};

	return (
		<>
			<div>
				<h1 className="el_pageTtl">対象商品グループ登録</h1>
				<p className="el_pageDesc">キャンペーン対象商品グループの新規登録、確認・編集ができます。</p>
				<div className="bl_panel bl_panel__bt">
					<div className="bl_panel_headerFooter">
						<Link className="el_btn el_btn__plus" to={`${state.params.basePath}/reward/group/new`}>新規追加</Link>
						<button className="el_btnBlueInv" type="button" onClick={handleClickDelete}>削除</button>
					</div>
					{groups && <>
						<table className="bl_table bl_table__productGroup">
							<thead className="bl_table_head">
								<tr>
									<th><input className="el_checkMark" type="checkbox" name='checkAll' ref={checkAll} onChange={handleChangeCheckAll} /></th>
									<th>対象商品グループ名</th>
									<th>設定内容</th>
									<th>登録されているキャンペーン</th>
								</tr>
							</thead>
							<tbody className="bl_table_body">
								{groups.map((group, idx) =>
									<tr key={group.id}>
										<td><input className="el_checkMark" type="checkbox" name={`check[${idx}]`} ref={register} onChange={handleChangeCheck} /></td>
										<td>{group.name}</td>
										<td>{toRequire(group)}</td>
										<td className="bl_nestTableWrap">
											{campaigns &&
												<table className="bl_nestTable">
													<tbody>
														<tr>
															<th>実施前</th>
															<td>
																<InsertSeparator>
																	{campaigns.get(group.id)?.ready}
																</InsertSeparator>
															</td>
														</tr>
														<tr>
															<th>実施中</th>
															<td>
																<InsertSeparator>
																	{campaigns.get(group.id)?.inProgress}
																</InsertSeparator>
															</td>
														</tr>
													</tbody>
												</table>
											}
											<div className="bl_table_btnWrap">
												<Link className="el_btnInv" to={toEditURL(group.id)}>編集</Link>
												<Link className="el_btnInv" to={toCopyURL(group.id)}>複製</Link>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
						<p className="el_resultCount">全{groups.length}件</p>
					</>}
				</div>
			</div>
		</>
	);
}
export default GroupList;