import { User } from '@sasagase/types';
import * as React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { activateToken, getUserByToken } from "../../../api";
import { useAPI } from '../../../context';

interface AccountActivateProps {}
type AccountActivateParams = {
	token?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AccountActivate(props: AccountActivateProps): React.ReactElement {
	const params = useParams<AccountActivateParams>();
	const callAPI = useAPI();
	const [redirect, setRedirect] = React.useState<string|null>(null);

	React.useEffect(() => {
		if (!params.token) {
			return;
		}

		activateUser(params.token);
	}, [params.token]);

	const activateUser = async (token: string) => {
		try {
			const resActivate = await callAPI(activateToken(token));
			if (!resActivate.data) {
				setRedirect('/notfound');
				return;
			}
			const resUser = await callAPI(getUserByToken(token));
			if (!resUser.data) {
				setRedirect('/notfound');
				return;
			}
			const user = new User(resUser.data);
			if (!user.setPass) {
				setRedirect(`/account/regist/${params.token}`);
				return;
			}
			
		} catch (err) {
			setRedirect('/notfound');
			return;
		}
	};

	const handleClick = () => {
		setRedirect("/");
		return;
	}

	if (redirect) {
		return <Navigate to={redirect} />;
	}
	
	return (
		<div className="ly_reissue">
			<div className="ly_reissue_inner">
				<h1 className="el_entryTitle"><img src="/assets/img/logo-w.svg" alt="ラクラクあつまレビュー" /></h1>
				<div className="bl_inviteForm">
					<div className="bl_loginFormRow">
						<p className="txt_blue">
							ラクラクあつまレビュー のメンバーとして登録されました。
						</p>
					</div>
					<button className="el_largeBtn el_largeBtn__fullWidth" type="button" onClick={handleClick}>ラクラクあつまレビュー を始める</button>
				</div>
			</div>
		</div>
	);
}
export default AccountActivate;