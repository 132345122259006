import { MailContent, User } from '@sasagase/types';
import { APIRequest } from '.';
import { uri } from '../lib';

export function userLogin(mail: string, pass: string): APIRequest {
	return {
		method: 'post',
		url: '/api/user/login',
		data: { mail, pass },
	};
}
export function userLoginTwoFactorAuth(sessionId: string, code: string): APIRequest {
	return {
		method: 'post',
		url: '/api/user/login/two-factor-auth',
		data: { sessionId, code },
	};
}
export function userLoginBySession(): APIRequest {
	return {
		method: 'get',
		url: '/api/user/login',
	};
}
export function userLogout(): APIRequest {
	return {
		method: 'get',
		url: '/api/user/logout',
	};
}
export function changePassword(oldPass: string, newPass: string): APIRequest {
	return {
		method: 'put',
		url: '/api/user/password',
		data: {
			oldPass,
			newPass,
		},
	};
}
export function changeUser(user: User): APIRequest {
	return {
		method: 'put',
		url: '/api/user/user',
		data: {
			user
		},
	};
}
export function recoverPassword(mail: string): APIRequest {
	return {
		method: 'post',
		url: uri`/api/user/password/recover`,
		data: { 
			mail
		},
	};
}
export function resetPassword(token: string, password: string): APIRequest {
	return {
		method: 'post',
		url: uri`/api/user/password/reset/${token}`,
		data: { password },
	};
}
export function getUserByToken(token: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/user/user/token/${token}`,
	};
}
export function putInviteUser(token: string, name: string, pass: string): APIRequest {
	return {
		method: 'put',
		url: uri`/api/user/invite/${token}/user`,
		data: { name, pass },
	};
}
export function postEntityUser(shopId: string, mail: string, capabilities: string[], mailOption: Record<string, string>): APIRequest {
	return {
		method: 'post',
		url: uri`/api/user/entity/${shopId}/user`,
		data: {
			mail,
			capabilities,
			mailOption
		}
	};
}
export function getEntityUsers(shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/user/entity/${shopId}/user`,
	};
}
export function getEntityUser(shopId: string, userId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/user/entity/${shopId}/user/${userId}`,
	};
}
export function deleteEntityUser(shopId: string, userId: string): APIRequest {
	return {
		method: 'delete',
		url: uri`/api/user/entity/${shopId}/user/${userId}`,
	};
}
export function putEntityUserCapability(shopId: string, userId: string, capabilities: Record<string, any>[]): APIRequest {
	return {
		method: 'put',
		url: uri`/api/user/entity/${shopId}/user/${userId}/capability`,
		data: { capabilities }
	};
}
export function activateToken(token: string): APIRequest {
	return {
		method: 'post',
		url: uri`/api/user/capability/${token}`,
	};
}
export function getReviewMail(shopId: string, styleType: string, opt: Record<string, any>): APIRequest {
	return {
		method: 'post',
		url: uri`/api/user/mail/review/${shopId}/${styleType}`,
		data: opt,
	};
}
export function sendReviewMail(shopId: string, mailContent: MailContent): APIRequest {
	return {
		method: 'put',
		url: uri`/api/user/mail/review/${shopId}`,
		data: mailContent,
	};
}
export function postContact(shopId: string, form: Record<string, unknown>): APIRequest {
	return {
		method: 'post',
		url: uri`/api/user/contact/${shopId}`,
		data: form,
	};
}
export function getTwoFactorAuth(): APIRequest {
	return {
		method: 'get',
		url: '/api/user/two-factor-auth',
	};
}
export function getTwoFactorAuthCode(): APIRequest {
	return {
		method: 'get',
		url: '/api/user/two-factor-auth/code',
	};
}
export function putTwoFactorAuth(code: string): APIRequest {
	return {
		method: 'put',
		url: '/api/user/two-factor-auth',
		data: {
			code,
		},
	};
}
export function disableTwoFactorAuth(pass: string): APIRequest {
	return {
		method: 'put',
		url: '/api/user/two-factor-auth/disable',
		data: {
			pass,
		},
	};
}
