import * as React from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import PasswordInput from '../../PasswordInput';

const CLASS_INPUT_ERROR = 'is_error';

interface RakutenInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	errors: DeepMap<Record<string, any>, FieldError>;
	hide?: boolean;
	storedValue?: string;
}

export const RakutenInput = React.forwardRef<HTMLInputElement, RakutenInputProps>(function RakutenInput({ errors, hide, storedValue, ...props }, ref): React.ReactElement|null {
	const { name = '' } = props;
	const error: FieldError | undefined = errors[name];

	const passProps = {
		...props,
		className: error ? CLASS_INPUT_ERROR : undefined,
		placeholder: !storedValue ? '(未設定)' :
			hide ? `${"*".repeat(storedValue.length)} (設定済み)` : '',
	};

	if (hide) {
		return (
			<>
				<PasswordInput {...passProps} ref={ref} />
				{storedValue && <p className="txt_blue fs_12 mt_8">※入力された場合のみ更新します。</p>}
			</>
		);
	}

	return (
		<>
			<input {...passProps} ref={ref} />
		</>
	);
});
export default RakutenInput;