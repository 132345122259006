import { ShopEntity, ShopEntityAttr } from '@sasagase/types';
import * as React from 'react';
import { getShopEntity, putShopEntity } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import SettingForm from './SettingForm';

interface SettingProps {}

const initValues = {
	signature: '',
	sendFollowAfterShippingDays: '0',
	excludeMailAddress: '',
	datePicker: new Date(0,0,0,12,0)
};
type FormValues = {
	signature: string,
	sendFollowAfterShippingDays: string,
	excludeMailAddress: string,
	datePicker: Date,
};

function toShopEntityAttr(id: string, values: Partial<FormValues>,): Partial<ShopEntityAttr> {
	const sendFollowHours = values.datePicker instanceof Date ? values.datePicker.getHours().toString() : "0"
	const sendFollowMinutes = values.datePicker instanceof Date ? values.datePicker.getMinutes().toString() : "0"
	const mailSetting: Partial<ShopEntityAttr> = {
		signature: values.signature,
		sendFollowAfterShippingDays: parseInt(values.sendFollowAfterShippingDays || '0', 10),
		sendFollowHours: parseInt(sendFollowHours, 10),
		sendFollowMinutes: parseInt(sendFollowMinutes, 10),
	};
	return mailSetting;
}
function toValues(shopEntity: ShopEntity): FormValues {
	return {
		...initValues,
		signature: shopEntity.signature,
		sendFollowAfterShippingDays: shopEntity.sendFollowAfterShippingDays.toString(),
		excludeMailAddress: shopEntity.excludeMailAddress.join("\n"),
		datePicker: new Date(0, 0, 0, shopEntity.sendFollowHours, shopEntity.sendFollowMinutes)
	};
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Setting(props: SettingProps): React.ReactElement|null {
	const callAPI = useAPI();
	const [state] = useAppState();
	const [values, setValues] = React.useState<FormValues>(initValues);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		return callAPI(getShopEntity(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const shopEntity = new ShopEntity(result.data);
			setValues(toValues(shopEntity));
			setLoading(false);
		});
	}, [state.params.shopId]);

	const handleSave = async (values: Record<string, string|number|boolean|Date>) => {
		try {
			const shopEntity = toShopEntityAttr(state.params.shopId, values);
			await callAPI(putShopEntity(state.params.shopId, shopEntity));
			return true;
		} catch (err) {
			return false;
		}
	};

	if (loading) {
		return null; // loading
	}
	return <SettingForm initValues={values} onSave={handleSave} />;
}
export default Setting;