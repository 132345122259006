import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useAppState } from '../../../context';
import { useTimer } from '../../../lib';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../lib/usePrompt';
import FormErrorMessage from '../FormErrorMessage';
import LinkGuide from '../LinkGuide';
import GroupEditRow from './GroupEditRow';

interface FormValues {
	name: string;
	isAll: string;
	inputSkus: string;
	skus: string;
	inputExcludeSkus: string;
	excludeSkus: string;
	childGroupIds: string[];
	excludeChildGroupIds: string[];
	group: Record<string, boolean>;
	excludeGroup: Record<string, boolean>;
	itemName: string;
	upper: string;
	lower: string;
	excludeItemName: string;
	excludeUpper: string;
	excludeLower: string;
}
export interface GroupEditFormProps {
	initValues: Partial<FormValues>;
	groupNames: Record<string, string>;
	onSave: (values: FormValues) => Promise<boolean>;
}

export function GroupEditForm(props: GroupEditFormProps): React.ReactElement {
	const [state] = useAppState();
	const formMethods = useForm<FormValues>({
		defaultValues: {
			name: '',
			isAll: 'false',
			inputSkus: '',
			skus: '',
			inputExcludeSkus: '',
			excludeSkus: '',
			childGroupIds: [],
			excludeChildGroupIds: [],
			group: {},
			excludeGroup: {},
			itemName: '',
			upper: '',
			lower: '',
			excludeItemName: '',
			excludeUpper: '',
			excludeLower: '',
			...props.initValues,
		},
		shouldUnregister: false,
	});
	const { register, handleSubmit, errors, reset, formState: { isDirty, isSubmitting } } = formMethods;
	// isSubmitting: 新規追加成功時に新しいUUIDのページへのリダイレクト制御のため
	usePrompt(PROMPT_CONFIRM_MESSAGE, (isDirty && !isSubmitting));

	const [saveState, setSaveState] = React.useState('');

	const setTimer = useTimer();

	const handleClickSave = handleSubmit(async (values) => {
		const res = await props.onSave(values);
		setSaveState(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setSaveState(''), 6000);
			reset(values);
		}
	});

	return (
		<>
			<h1 className="el_pageTtl">対象商品グループ登録</h1>
			<p className="el_pageDesc">キャンペーン対象商品グループの新規登録、確認・編集ができます。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
				<div className="bl_panel bl_panel__bt">
					<h2 className="el_lv2Heading">
						対象商品グループ登録
						<LinkGuide guidePath='registration_setting/from_review_privilege_up_to_campaign_register' link='対象商品グループの登録' className='el_linkIcon__guideF' />
					</h2>
					<div className="bl_panel_row">
						<h3 className="el_lv3Heading">対象商品グループ名</h3>
						<input type="text" placeholder="【グループ名】を入力" name="name" ref={register({ required: 'グループ名を入力してください' })} />
						<FormErrorMessage errors={errors} name="name" />
					</div>
					<GroupEditRow methods={formMethods} groupNames={props.groupNames} />
					<div className="el_largeBtnWrap">
						<button className={"el_largeBtn " + saveState} type="button" onClick={handleClickSave}>保存</button>
					</div>
					<Link className="el_backLink" to={`${state.params.basePath}/reward/group`}>≪一覧へ戻る</Link>
				</div>
				</div>
			</div>
		</>
	);
}
export default GroupEditForm;