import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAppState } from '../../context';

interface LinkGuideProps {
	guidePath: string;
	link?: string;
	className?: string;
}
export const LinkGuide: React.FC<LinkGuideProps> = (props) => {
	const [state] = useAppState();
	const guidePath = state.params.basePath +
					"/guide/" + props.guidePath +
					(props.link ? "#" + props.link : '');
	const className = props.className ? props.className : 'el_linkIcon__guide';

	return (
		<Link to={guidePath} className={className} target="_blank"></Link>
	);
}
export default LinkGuide;