import { ItemGroup } from '@sasagase/types';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { array, boolean, define, dynamic, record, string, type } from 'superstruct';
import FormErrorMessage from '../../FormErrorMessage';
import GroupEditRow, { GroupEditRowFormValues } from '../GroupEditRow';
import { getItemGroupRequireDescList } from '../GroupList';

export interface CampaignEditFormStep3FormValue {
	itemGroup: GroupEditRowFormValues;
}

export const CampaignEditFormStep3Struct = type({
	itemGroup: dynamic((values: any) => {
		const isNotEmpty = values.isAll == 'true' || Boolean(values.skus.split(/[\n\t,]/g).filter(Boolean).length || values.childGroupIds.length);
		return type({
			isAll: string(),
			inputSkus: string(),
			skus: define<string>('item is not empty', () => isNotEmpty || '対象商品を設定してください'),
			inputExcludeSkus: string(),
			excludeSkus: string(),
			childGroupIds: array(string()),
			excludeChildGroupIds: array(string()),
			group: record(string(), boolean()),
			excludeGroup: record(string(), boolean()),
			itemName: string(),
			upper: string(),
			lower: string(),
			excludeItemName: string(),
			excludeUpper: string(),
			excludeLower: string(),
		});
	}),
});

interface CampaignEditFormStep3Props {
	methods: UseFormMethods<CampaignEditFormStep3FormValue>;
	groups: ItemGroup[];
}

export function CampaignEditFormStep3(props: CampaignEditFormStep3Props): React.ReactElement {
	const groupNames = React.useMemo(() => {
		const names: Record<string, string> = {};
		for (const group of props.groups) {
			const desc = getItemGroupRequireDescList(group);
			names[group.id] = `${group.name}【${desc.join(',')}】`;
		}
		return names;
	}, [props.groups]);

	const toName = (name: string) => `itemGroup.${name}`;

	return (
		<div>
			<GroupEditRow methods={props.methods} groupNames={groupNames} toName={toName} />
			<FormErrorMessage errors={props.methods.errors} name={toName('skus')} />
		</div>
	);
}
export default CampaignEditFormStep3;