import { User } from '@sasagase/types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { userLogin, userLoginBySession, userLoginTwoFactorAuth } from "../../api";
import { loginMessageSetter, loginSetter, sessionExpireSetter, useAPI, useAppState, userSetter } from "../../context";
import PasswordInput from '../PasswordInput';

const Login: React.FunctionComponent = () => {
	const [state, setState] = useAppState();
	const callAPI = useAPI();
	const [input, setInput] = React.useState({
		mail: '',
		password: '',
		code: '',
	});
	const [isTwoFactorAuth, setIsTwoFactorAuth] = React.useState(false);
	const [sessionId, setSessionId] = React.useState('');
	const [shakeMessage, setShakeMessage] = React.useState(0);

	React.useEffect(() => {
		if ('user' in state) {
			return;
		}

		return callAPI(userLoginBySession(), (err, result) => {
			if (err) {
				return;
			}
			const user = new User(result.data.user);
			const expire = Number(result.data.expire ?? 0);
			const handle = state.sessionExpire ??
				setTimeout(() => {
					window.location.reload();
				}, Math.max(0, expire - new Date().getTime()));
			setState([
				userSetter(user),
				loginSetter(null),
				sessionExpireSetter(handle),
			]);
		});
	}, []);

	if (!('user' in state)) {
		return <div>Session loading...</div>;
	}

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
		setInput({ ...input, [ev.target.name]: ev.target.value });
	};

	const handleSubmit: React.FormEventHandler = async (ev) => {
		ev.preventDefault();
		try {
			const result = await callAPI(userLogin(input.mail, input.password));
			const progressTwoFactorAuth = result.data.progressTwoFactorAuth;
			if (progressTwoFactorAuth) {
				setIsTwoFactorAuth(true);
				setSessionId(result.data.sessionId);
			} else {
				const user = new User(result.data.user);
				const expire = Number(result.data.expire ?? 0);
				const handle = setTimeout(() => {
					window.location.reload();
				}, Math.max(0, expire - new Date().getTime()));
				setState([
					userSetter(user),
					loginSetter(null),
					sessionExpireSetter(handle),
				]);
			}
		} catch (err) {
			setShakeMessage((p) => p + 1);
			if (err.status === 429) {
				setState(loginMessageSetter('短時間に多数のログインに失敗したため、ログインが制限されています。'));
			} else if (err.data === 'MISMATCH') {
				setState(loginMessageSetter('メールアドレスかパスワードが違います'));
			} else if (err.data === 'INVALID_USER') {
				setState(loginMessageSetter('利用可能な店舗がありません。'));
			} else {
				// 上記以外のエラーの場合
				setState(loginMessageSetter('アカウント認証に失敗しました'));
			}
		}
	};

	const handleTwoFactorAuthSubmit: React.FormEventHandler = async (ev) => {
		ev.preventDefault();
		try {
			const result = await callAPI(userLoginTwoFactorAuth(sessionId, input.code));
			const user = new User(result.data.user);
			const expire = Number(result.data.expire ?? 0);
			const handle = setTimeout(() => {
				window.location.reload();
			}, Math.max(0, expire - new Date().getTime()));

			setState([
				userSetter(user),
				loginSetter(null),
				sessionExpireSetter(handle),
			]);
		} catch (err) {
			setShakeMessage((p) => p + 1);
			if (err.data === 'MISMATCH') {
				setState(loginMessageSetter('認証コードが違います'));
			} else {
				// 上記以外のエラーの場合
				setState(loginMessageSetter('アカウント認証に失敗しました'));
			}
		}
	};

	return (
		<div className="ly_login">
			<div className="ly_entry">
				<h1 className="el_entryTitle"><img src="/assets/img/logo-w.svg" alt="ラクラクあつまレビュー" /></h1>
			</div>
			<main className="ly_loginForm">
				<div className="ly_loginForm_inner">
					{state.login?.message &&
						<p key={shakeMessage} className={`bl_loginForm_logoutNote ${shakeMessage <= 0 ? '' : 'shake'}`}>{state.login.message}</p>
					}
					<div>
						{!isTwoFactorAuth ? (
							<form onSubmit={handleSubmit}>
								<div className="bl_loginFormRow">
									<label className="bl_blueLabel" htmlFor="mail">メールアドレス</label>
									<input id="mail" type="text" name="mail" placeholder="aaa@aa.co.jp" value={input.mail} onChange={handleChange} />
								</div>
								<div className="bl_loginFormRow mb_16">
									<label className="bl_blueLabel" htmlFor="password">パスワード</label>
									<PasswordInput id="password" name="password" placeholder="***********" value={input.password} onChange={handleChange} />
								</div>
								<div className="bl_loginFormRow">
									<Link className="txt_blue" to="/account/recover">パスワードを忘れた方はこちらから</Link>
								</div>
								<div className="el_largeBtnWrap">
									<button className="el_largeBtn el_largeBtn__fullWidth" type="submit">ログイン</button>
								</div>
							</form>
						) : (
							<>
								<form onSubmit={handleTwoFactorAuthSubmit}>
									<div className="bl_loginFormRow">
										<p className="txt_blue">認証アプリに表示された認証コードを入力してください。</p>
									</div><div className="bl_loginFormRow">
										<label className="bl_blueLabel" htmlFor="mail">認証コード</label>
										<input id="code" type="text" name="code" placeholder="" value={input.code} onChange={handleChange} />
									</div><div className="el_largeBtnWrap">
										<button className="el_largeBtn el_largeBtn__fullWidth" type="submit">コードを送信</button>
									</div>
								</form>
							</>
						)}
					</div>
				</div>
			</main>
		</div>
	);
};
export default Login;