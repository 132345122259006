import { Notices, ShopEntity } from '@sasagase/types';
import * as React from 'react';
import { getNoticeShopEntity, getShopEntity, putNoticeShopEntity, putShopEntity } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import SystemAlertForm from './SystemAlertForm';

export const ALERT_DEST_MAIL_DELIMITER = ',';

interface SystemAlertProps {}

const initValues = {
	isSendAlert: false,
	alertContentType: [],
	alertDestMail: '',
	daysBeforelicenseKeyExpire: '',
	daysNotUploadedReviewCsv: ''
};
type FormValues = {
	isSendAlert: boolean;
	alertContentType: string[],
	alertDestMail: string,
	daysBeforelicenseKeyExpire: string,
	daysNotUploadedReviewCsv: string,
};

export function sortMails(value?: string): string[] {
	return (value ?? '').trim().split(/\s*,\s*/).filter((ad: string) => Boolean(ad));
}

function toShopEntityAttr(id: string, values: Partial<FormValues>,): Partial<Notices.ShopEntityAttr> {
	const shopEntity: Partial<Notices.ShopEntityAttr> = {
		isSendAlert: Boolean(values.isSendAlert),
		alertContentType: values.alertContentType,
		alertDestMail: sortMails(values.alertDestMail),
		daysBeforelicenseKeyExpire: values.daysBeforelicenseKeyExpire ? Number(values.daysBeforelicenseKeyExpire) : undefined,
		daysNotUploadedReviewCsv: values.daysNotUploadedReviewCsv ? Number(values.daysNotUploadedReviewCsv) : undefined,
	};
	return shopEntity;
}
function toValues(shopEntity: Notices.ShopEntity): FormValues {
	return {
		...initValues,
		isSendAlert: shopEntity.isSendAlert,
		alertContentType: shopEntity.alertContentType,
		alertDestMail: shopEntity.alertDestMail.join(ALERT_DEST_MAIL_DELIMITER),
		daysBeforelicenseKeyExpire: String(shopEntity.daysBeforelicenseKeyExpire),
		daysNotUploadedReviewCsv: String(shopEntity.daysNotUploadedReviewCsv),
	};
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SystemAlert(props: SystemAlertProps): React.ReactElement|null {
	const callAPI = useAPI();
	const [state] = useAppState();
	const [values, setValues] = React.useState<FormValues>(initValues);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		return callAPI(getNoticeShopEntity(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const shopEntity = new Notices.ShopEntity(result.data);
			setValues(toValues(shopEntity));
			setLoading(false);
		});
	}, [state.params.shopId]);

	const handleSave = async (values: Record<string, string|number|boolean|string[]>) => {
		try {
			const shopEntityAttr = toShopEntityAttr(state.params.shopId, values);
			const resSave = await callAPI(putNoticeShopEntity(state.params.shopId, shopEntityAttr));

			if (resSave.data && shopEntityAttr.alertContentType?.includes('notUploadedReviweCsv')) {
				const res = await callAPI(getShopEntity(state.params.shopId));
				const shopEntity = ShopEntity.create(res.data);
				// レビューCSVのアップロード期間判定用日時の初期値保存
				if (!shopEntity.lastReviewUploadDate) {
					await callAPI(putShopEntity(state.params.shopId, { lastReviewUploadDate: Date.now() }));
				}
			}

			return true;
		} catch (err) {
			return false;
		}
	};

	if (loading) {
		return null; // loading
	}
	return <SystemAlertForm initValues={values} onSave={handleSave} />;
}
export default SystemAlert;