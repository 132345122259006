import { APIRequest } from '.';

export function postImage(shopId: string, file: File): APIRequest {
	const data = new FormData();
	data.append('image', file);
	return {
		method: 'post',
		url: `/api/storage/${encodeURIComponent(shopId)}/image`,
		data,
	};
}