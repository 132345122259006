import { User } from '@sasagase/types';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { getEntityUser, putEntityUserCapability } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import AccountSettingEditForm from './AccountSettingEditForm';

interface AccountSettingEditProps {}
type AccountSettingEditParams = {
	userId?: string;
}

interface FormValues {
	name: string;
	mail: string;
	review_check_tools: 'hide' | 'read';
	review_status: 'hide' | 'read' | 'write';
	reward_mail: 'hide' | 'read' | 'write';
	analysis: 'hide' | 'read';
	account_rakuten: 'hide' | 'write';
	account_expired: 'disabled' | 'enabled';
	expired_at: Date | null;
	account_state: 'active' | 'suspend';
}

const requiredCaps: Record<string, Record<string, string[]>> = {
	review_check_tools: {
		'read': ['review_reviewCheckTool_read'],
	},
	review_status: {
		'read': ['review_reviewWaiting_read', 'review_rewardApplying_read'], 
		'write': ['review_reviewWaiting_read', 'review_rewardApplying_read', 'review_labor_write'],
	},
	reward_mail: {
		'read': ['review_management_read'], 
		'write': ['review_management_read', 'review_management_write'],
	},
	analysis: {
		'read': ['review_analysis_read'],
	},
	account_rakuten: {
		'write': ['review_system_read', 'review_system_write'],
	},
};

function toValues(shopId: string, user: User): FormValues {
	const userState = user.getState(shopId);
	let expiredAt = null;
	if (['active', 'expired'].includes(userState)) {
		const ex = user.getExpire(shopId);
		expiredAt = ex ? new Date(ex) : null;
	}
	const values: FormValues = {
		name: user.name || '',
		mail: user.mail || '',
		review_check_tools: user.hasEnableCaps('review_reviewCheckTool_read', shopId) ? 'read' : 'hide',
		review_status: 
			user.hasEnableCaps('review_labor_write', shopId) ? 'write':
			user.hasEnableCaps('review_reviewWaiting_read', shopId) && user.hasEnableCaps('review_rewardApplying_read', shopId) ? 'read': 'hide',
		reward_mail: 
			user.hasEnableCaps('review_management_write', shopId) ? 'write':
			user.hasEnableCaps('review_management_read', shopId) ? 'read': 'hide',
		analysis: user.hasEnableCaps('review_analysis_read', shopId) ? 'read' : 'hide',
		account_rakuten: user.hasEnableCaps('review_system_write', shopId) ? 'write' : 'hide',
		account_expired: expiredAt ? 'enabled': 'disabled',
		expired_at: expiredAt,
		account_state: userState === 'suspend' ? 'suspend' : 'active',
	}
	return values;
}
function toCapabilities(values: Record<string, any>): Record<string, any>[] {
	let capabilities: Record<string, any>[] = [];
	for (const [rc, caps] of Object.entries(requiredCaps)) {
		if (caps[values[rc]]) {
			caps[values[rc]].map((cap) => {
				capabilities.push({
					name: cap,
					attributes: { disabled: false }
				});
			});
		}
	}
	/**
	 * 以下の権限を全て持っていればreview_labor_readへ昇格
	 *   review_reviewCheckTool_read
	 *   review_reviewWaiting_read
	 *   review_rewardApplying_read
	 */ 
	if (values.review_check_tools == 'read' && values.review_status != 'hide') {
		capabilities = capabilities.filter(cap => {
			return !requiredCaps.review_check_tools.read.includes(cap.name) &&
					!requiredCaps.review_status.read.includes(cap.name)
		});
		capabilities.push({
			name: `review_labor_read`,
			attributes: { disabled: false }
		});
	}
	capabilities.push({
		name: 'review_login',
		attributes: {
			disabled: values.account_state === 'active' ? false : true,
			expire: (values.account_expired === 'enabled') ? new Date(values.expired_at).getTime() : undefined,
		}
	});
	return capabilities;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AccountSettingEdit(props: AccountSettingEditProps): React.ReactElement|null {
	const params = useParams<AccountSettingEditParams>();
	const callAPI = useAPI();
	const [state] = useAppState();
	const [values, setValues] = React.useState<FormValues | null>(null);

	React.useEffect(() => {
		if (!params.userId) {
			return;
		}

		return callAPI(getEntityUser(state.params.shopId, params.userId), (err, result) => {
			if (err) {
				return;
			}
			const user = new User(result.data);
			setValues(toValues(state.params.shopId, user));
		});
	}, [state.params.shopId, params.userId]);

	const handleSave = async (values: Record<string, any>) => {
		try {
			if (params.userId) {
				const res = await callAPI(putEntityUserCapability(state.params.shopId, params.userId, toCapabilities(values)));
				return Boolean(res.data);
			}
			return false;
		} catch (err) {
			return false;
		}
	};

	if (!values) {
		return null; // loading
	}
	return (
		<AccountSettingEditForm initValues={values} onSave={handleSave} />
	);
}
export default AccountSettingEdit;