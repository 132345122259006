import { Campaign, CampaignState, campaignStateInfos, campaignStateList } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { deleteCampaign, getCampaigns, setCampaignPriority } from '../../../api';
import { useAPI, useAppState } from '../../../context';

type ListItem<T> = {
	isShow: boolean;
	isUnsaved?: boolean;
	item: T;
};

interface RegisterCampaignProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function CampaignList(props: RegisterCampaignProps): React.ReactElement {
	const callAPI = useAPI();
	const [state] = useAppState();
	const { register, getValues, setValue } = useForm();
	const [items, setItems] = React.useState<ListItem<Campaign>[] | null>(null);
	const [open, setOpen] = React.useState('');
	const checkAll = React.useRef<null|HTMLInputElement>(null);

	// itemsから表示するキャンペーンを抽出(react-beautiful-dndのDraggableに連続したidxを指定する必要があるので)
	const campaigns = items && items.filter(item => item.isShow).map(item => item.item);

	React.useEffect(() => {
		if (!state.params.shopId || items != null) {
			return;
		}
		return callAPI(getCampaigns(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const campaigns: Campaign[] = result.data.map((obj: Record<string, unknown>) => Campaign.create(obj));
			setItems(campaigns.map((c) => ({ isShow: true, item: c })));
		});
	}, [state.params.shopId, items]);

	React.useEffect(() => {
		const docClickHandler = (ev: MouseEvent) => {
			if (!(ev.target instanceof HTMLElement) || ev.target.closest('th.bl_table_filterHead')) {
				return;
			}
			setOpen('');
		};
		document.body.addEventListener('click', docClickHandler);
		return () => document.body.removeEventListener('click', docClickHandler);
	}, []);

	React.useEffect(() => {
		renderCheckAll();
	}, [items]);

	// 順番入れ替えの保存(priorityが降順になるようにする)
	React.useEffect(() => {
		if (!items || items.length < 2 || !state.params.shopId) {
			return;
		}
		const promises = [];
		for (const [idx, item] of items.entries()) {
			if (!item.isUnsaved) {
				continue;
			}
			const prevPri = items[idx - 1]?.item.priority ?? Math.max(items[1].item.priority + 2, Date.now());
			const nextPri = items[idx + 1]?.item.priority ?? Math.min(items[items.length - 2].item.priority - 1, 0);
			const campaign = item.item;
			campaign.priority = Math.floor((prevPri + nextPri) / 2);

			// prevPri と nextPri の差が1の時に nextPri と同じ値になるので
			// その場合は次のキャンペーンも priority を更新する
			if (campaign.priority === nextPri && items[idx + 1]) {
				items[idx + 1].isUnsaved = true;
			}

			const p = callAPI(setCampaignPriority(state.params.shopId, campaign.id, campaign.priority));
			promises.push(p);
		}
		if (promises.length) {
			setItems(prev => prev && prev.map(item => item.isUnsaved && {
				...item,
				isUnsaved: false,
			} || item));
		}
	}, [items]);

	const renderCheckAll = () => {
		if (!checkAll.current || !items) {
			return;
		}
		const checks = getValues().checks;
		const showChecks = items.filter(i => i.isShow).map(i => checks[i.item.id]);
		const isAll = showChecks.length > 0 && Object.values(showChecks).every(Boolean);
		const isPartial = !isAll && Object.values(showChecks).some(Boolean);
		checkAll.current.checked = isAll;
		checkAll.current.indeterminate = isPartial;
	};
	const filterItem = (predicate: (item: Campaign) => boolean) => {
		setItems(prevItems => {
			if (!prevItems) {
				return null;
			}
			return prevItems.map(prev => ({
				...prev,
				isShow: predicate(prev.item),
			}));
		});
	};

	const handleClickFilter = (name: string) => () => {
		setOpen(prev => prev == name ? '' : name);
	};
	const handleQuerySubmit: React.FormEventHandler<HTMLFormElement> = (ev) => {
		ev.preventDefault();
		const query = getValues('query');
		filterItem((item) => item.name.includes(query));
		setOpen('');
	};
	const handleChangeStateFilter: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
		const val = ev.currentTarget.value;
		filterItem((item) => !val || item.getState() == val);
		setOpen('');
	};
	const handleClickDelete = async () => {
		if (!state.params.shopId) {
			throw null;
		}

		const checks: Record<string, boolean> = getValues().checks || {};
		const names = Object.entries(checks).filter(([, check]) => check).map(([id]) => {
			const item = items?.find(({item: c}) => c.id === id);
			return item?.item.name;
		}).filter((name) => Boolean(name));
		if (names.length <= 0) {
			alert(`削除するキャンペーンを選択してください。`);
			return;
		}
		if (window.confirm(`以下のキャンペーンを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			for (const { item: campaign } of items || []) {
				if (!checks[campaign.id]) {
					continue;
				}
				try {
					await callAPI(deleteCampaign(state.params.shopId, campaign.id));
				} catch (err) {
					if (err.status !== 400) {
						alert(`キャンペーン ${campaign.name} の削除に失敗しました。`);
					}
				}
			}
			setItems(null);
		}
	};

	const handleChangeCheckAll = () => {
		if (!checkAll.current) {
			return;
		}
		const checks = getValues().checks || {};
		for (const key of Object.keys(checks)) {
			checks[key] = checkAll.current.checked;
		}
		setValue('checks', checks);
	};
	const handleChangeCheck = () => {
		renderCheckAll();
	};

	const handleDragEnd: OnDragEndResponder = (result) => {
		if (!items || !campaigns || !result.destination || result.destination.index === result.source.index) {
			return;
		}
		// resultのindexは campaigns のインデックスなので items のインデックスに変換する
		const srcCamp = campaigns[result.source.index];
		const destCamp = campaigns[result.destination.index];
		const src = items.findIndex(item => item.item === srcCamp);
		const dest = items.findIndex(item => item.item === destCamp);
		if (src >= 0 && dest >= 0) {
			moveItem(src, dest);
		}
	};

	const moveItem = (src: number, dest: number) => {
		setItems(prev => {
			if (!prev || src === dest) {
				return prev;
			}
			if (src < dest) {
				return [
					...prev.slice(0, src),
					...prev.slice(src + 1, dest + 1),
					{ ...prev[src], isUnsaved: true },
					...prev.slice(dest + 1),
				];
			} else {
				return [
					...prev.slice(0, dest),
					{ ...prev[src], isUnsaved: true },
					...prev.slice(dest, src),
					...prev.slice(src + 1),
				];
			}
		});
	};

	const toDateString = (milliepoc: number | undefined): string => {
		if (!milliepoc) {
			return '';
		}
		return dayjs(milliepoc).format('YYYY年MM月DD日');
	};
	const toCampaignEditURL = (campaignId: string) => {
		return `${state.params.basePath}/reward/campaign/${campaignId}`;
	};
	const toCampaignCopyURL = (campaignId: string) => {
		return `${state.params.basePath}/reward/campaign/${campaignId}/copy`;
	};
	const toStateString = (state: CampaignState) => {
		return campaignStateInfos[state]?.name || state;
	};

	return (
		<>
			<h1 className="el_pageTtl">キャンペーン登録</h1>
			<p className="el_pageDesc">キャンペーンの新規登録、確認・編集ができます。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_headerFooter">
							<Link className="el_btn el_btn__plus" to={`${state.params.basePath}/reward/campaign/new`}>新規追加</Link>
							<button className="el_btnBlueInv ml_16" type="button" onClick={handleClickDelete}>削除</button>
						</div>
						{campaigns &&
							<>
								<table className="bl_table bl_table__campaign">
									<thead className="bl_table_head">
										<tr>
											<th></th>
											<th><input type="checkbox" className="el_checkMark" name="checkAll" ref={checkAll} onChange={handleChangeCheckAll} /></th>
											<th className={`bl_table_filterHead ${open == 'name' ? 'is_active' : ''}`}>
												<span className="bl_table_filterHeadTtl" onClick={handleClickFilter('name')}>キャンペーン名</span>
												<div className="bl_tableSearchForm">
													<p className="bl_tableSearchForm_ttl">検索</p>
													<div className="el_searchInputWrap">
														<form onSubmit={handleQuerySubmit}>
															<input type="text" name="query" ref={register} />
															<button className="el_searchBtn" type="submit" />
														</form>
													</div>
												</div>
											</th>
											<th>実施期間</th>
											<th></th>
											<th>特典詳細</th>
											<th className={`bl_table_filterHead bl_table_filterHead__beforeBtn ${open == 'state' ? 'is_active' : ''}`}>
												<span className="bl_table_filterHeadTtl" onClick={handleClickFilter('state')}>状況</span>
												<div className="bl_tableSearchForm bl_tableSearchForm__left">
													<p className="bl_tableSearchForm_ttl">フィルタ</p>
													<div className="el_selectWrap">
														<select name="state" ref={register} onChange={handleChangeStateFilter}>
															<option value="">すべて表示</option>
															{campaignStateList.map((state) =>
																<option key={state} value={state}>{toStateString(state)}</option>
															)}
														</select>
													</div>
												</div>
											</th>
										</tr>
									</thead>
									<DragDropContext onDragEnd={handleDragEnd}>
										<Droppable droppableId='campaign'>
											{(provided) => (
												<tbody className="bl_table_body" ref={provided.innerRef} {...provided.droppableProps}>
													{campaigns.map((campaign, idx) =>
														<Draggable key={campaign.id} draggableId={campaign.id} index={idx}>
															{(provided) => (
																<tr ref={provided.innerRef} {...provided.draggableProps}>
																	<td><div className="el_dragIndicator" {...provided.dragHandleProps}></div></td>
																	<td><input type="checkbox" className="el_checkMark" name={`checks.${campaign.id}`} ref={register} onChange={handleChangeCheck} /></td>
																	<td>
																		<span>
																			{campaign.isDraft &&
																				<span className="el_incomplete">(未入力アリ)</span>
																			}
																			{campaign.name}
																		</span>
																	</td>
																	<td>{toDateString(campaign.beginDate)}～<br />{toDateString(campaign.endDate)}</td>
																	<td></td>
																	<td>{campaign.rewards.map(r => r.name).join(', ')}</td>
																	<td>
																		<span>{toStateString(campaign.getState())}</span>
																		<div className="bl_table_btnWrap">
																			<Link className="el_btnInv" type="button" to={toCampaignEditURL(campaign.id)}>編集</Link>
																			<Link className="el_btnInv" type="button" to={toCampaignCopyURL(campaign.id)}>複製</Link>
																		</div>
																	</td>
																</tr>
															)}
														</Draggable>
													)}
													{provided.placeholder}
												</tbody>
											)}
										</Droppable>
									</DragDropContext>
								</table>
								<p className="el_resultCount">全{campaigns.length}件</p>
							</>
						}
					</div>
				</div>
			</div>
		</>
	);
}
export default CampaignList;