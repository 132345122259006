import { Help } from '@sasagase/types';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { getArticles } from '../../../api';
import { useAPI, useAppState } from '../../../context';

interface ArticleCategoryListProps {}
type ArticleCategoryListParams = {
	termId?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ArticleCategoryList(props: ArticleCategoryListProps): React.ReactElement | null {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const basePath = state.params.basePath;
	const params = useParams<ArticleCategoryListParams>();

	const callAPI = useAPI();
	const [categories, setCategories] = React.useState<Record<string, string[]>>({});
	const [articleMap, setArticleMap] = React.useState<Record<string, Help.Article[]>>({});

	React.useEffect(() => {
		const cond = {
			termIdOrSlug: params.termId
		};
		return callAPI(getArticles(shopId, cond), (err, res) => {
			if (err) {
				return;
			}
			const articles: Help.Article[] = res.data.map((obj: Record<string, unknown>) => Help.Article.create(obj));
			const categories: Record<string, string[]> = Object.fromEntries(articles.map(article => {
				return [article.category?.id, [article.category?.parent?.name, article.category?.name].filter(Boolean)];
			}));
			const articleMap: Record<string, Help.Article[]> = articles.reduce((map, article) => {
				if (!article.category) {
					return map;
				}

				if (!map[article.category.id]) {
					map[article.category.id] = [];
				}
				map[article.category.id].push(article);
				return map;
			}, {} as Record<string, Help.Article[]>);
			setCategories(categories);
			setArticleMap(articleMap);
		});
	}, []);

	if (Object.keys(articleMap).length <= 0) {
		return null;
	}

	const getTitle = (): React.ReactElement => {
		const firstArticle = Object.values(articleMap)[0][0];
		return (
			<>
				{firstArticle.isFaq() && <Link to={basePath + '/faq'}>よくある質問</Link>}
				{firstArticle.isGuide() && <Link to={basePath + '/guide'}>利用ガイド</Link>}
			</>
		);
	};

	const showedCategories: string[] = [];
	const showCategory = (categoryId: string) => {
		const categoryNames = categories[categoryId];
		const show = [
			categoryNames[0] && !showedCategories.includes(categoryNames[0]),
			categoryNames[1] && !showedCategories.includes(categoryNames[1])
		];
		showedCategories.push(...categoryNames);
		return (
			<>
				{show[0] && <h3 id={categoryNames[0]}>{categoryNames[0]}</h3>}
				{show[1] && <h4 id={categoryNames[1]}>{categoryNames[1]}</h4>}
			</>
		);
	};

	return (
		<>
			<h1 className="el_pageTtl">{getTitle()}</h1>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row bl_faqList">
							{Object.keys(articleMap).map((categoryId) => <React.Fragment key={categoryId}>
								{showCategory(categoryId)}
								<ul>
									{(articleMap[categoryId] ?? []).map(article =>
										<li key={article.id}><span><Link to={`${basePath}/faq/${article.id}`}>{article.titleShort}</Link></span></li>
									)}
								</ul>
							</React.Fragment>)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default ArticleCategoryList;