import { CouponReward } from '@sasagase/types';
import { APIRequest } from '.';

export function getShops(): APIRequest {
	return {
		method: 'get',
		url: `/api/rakuten/shops/review`,
	};
}
export function getShop(shopId: string): APIRequest {
	return {
		method: 'get',
		url: `/api/rakuten/${shopId}/shop`,
	};
}
export function putShop(shopId: string, shop: Record<string, string|number|undefined>): APIRequest {
	const shopAttr = Object.fromEntries(Object.entries(shop).filter(([_, v]) => v !== undefined));
	return {
		method: 'put',
		url: `/api/rakuten/${shopId}/shop`,
		data: shopAttr,
	};
}
export function getLicenseExpiryDate(shopId: string): APIRequest {
	return {
		method: 'get',
		url: `/api/rakuten/${shopId}/shop/licenseExpiryDate`,
	};
}
export function validateCoupon(shopId: string, reward: CouponReward): APIRequest {
	return {
		method: 'post',
		url: `/api/rakuten/${shopId}/coupon/validate`,
		data: reward,
	};
}
export function searchItems(shopId: string, itemName: string, itemPriceFrom?: number, itemPriceTo?: number): APIRequest {
	const params = new URLSearchParams();
	params.append('itemName', itemName);
	if (itemPriceFrom) { params.append('itemPriceFrom', String(itemPriceFrom)); }
	if (itemPriceTo) { params.append('itemPriceTo', String(itemPriceTo)); }
	return {
		method: 'get',
		url: `/api/rakuten/${shopId}/items?` + params.toString(),
	};
}
export function checkLogin(shopId: string): APIRequest {
	return {
		method: 'get',
		url: `/api/rakuten/${shopId}/checkLogin`,
	};
}