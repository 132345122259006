import { CouponReward, ItemReward, Reward, ShopEntity } from '@sasagase/types';
import * as React from 'react';
import { UseFormMethods, useFieldArray } from 'react-hook-form';
import { define, dynamic, enums, number, optional, string, type } from 'superstruct';
import { getShopEntity } from '../../../../api';
import { useAPI, useAppState } from '../../../../context';
import { nonNegative } from '../../../../lib';
import { previewPath, reviewFormUrl } from '../../../../lib/reviewForm';
import FormErrorMessage from '../../FormErrorMessage';
import { initValues } from '../CampaignEdit';

const ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED = 'キャンペーン実施中に、申込みフォームの有効・無効が切り替わる変更はできません';

export interface CampaignEditFormStep2FormValue {
	rewards: {
		val: string;
	}[],
	destType: 'form' | 'shipping' | 'orderer',
	applicationClosingDays: string,
	shouldCloseApplication: 'true' | 'false',
	inProgressDate?: number;
	waitingReviewDate?: number;
	destTypeOriginal?: string;
}

function requiredNumericString(val: unknown): boolean {
	return isFinite(parseInt(String(val), 10));
}

export const CampaignEditFormStep2Struct = dynamic((values: any) => {
	const shouldCloseApplication = values.shouldCloseApplication == 'true';
	const isStarted = Boolean(values.inProgressDate);
	const isFinishedCamp = Boolean(values.waitingReviewDate);

	return type({
		rewards: define<CampaignEditFormStep2FormValue['rewards']>('rewards', (val) => {
			const isSelect = Array.isArray(val) && val.every(reward => reward.val != '') ? true : '特典を選択してください';
			let isRewardChange: boolean | string = true;
			if (isStarted && !isFinishedCamp) {
				// 変更前の特典送り先が申込みフォーム以外の場合、フォームの有効無効の変更不可
				if (values.destTypeOriginal !== "form") {
					// 変更前の特典が複数の場合、単一に変更不可
					if (values.isManyRewardOriginal) {
						isRewardChange = Array.isArray(val) && val.length > 1 ?
						true : ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED;
					} else {
						// 変更前が単一の場合、複数に変更不可
						isRewardChange = Array.isArray(val) && val.every(reward => reward.val != '') && val.length == 1 ?
						true : ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED;
					}
				}
			}
			return isRewardChange === true ? isSelect === true ? true : isSelect : isRewardChange;
		}),
		applicationClosingDays: shouldCloseApplication ? define<string>('applicationClosingDays', (val) => requiredNumericString(val) || '受付期間を入力してください') : string(),
		destType: enums(['form', 'shipping', 'orderer']),
		shouldCloseApplication: enums(['true', 'false']),
		inProgressDate: optional(number()),
		waitingReviewDate: optional(number()),
		destTypeOriginal: optional(string()),
	});
});

interface CampaignEditFormStep2Props {
	methods: UseFormMethods<CampaignEditFormStep2FormValue>;
	rewards: Reward[];
	reviewFormCond: () => Record<string, boolean>;
}

export function CampaignEditFormStep2(props: CampaignEditFormStep2Props): React.ReactElement | null {
	const callAPI = useAPI();
	const { register, control, errors, watch, setValue, trigger, getValues } = props.methods;
	const { fields: rewardFields, remove, append } = useFieldArray({ control, name: 'rewards' });
	const [state] = useAppState();

	const shouldCloseApplication = watch('shouldCloseApplication') == 'true';
	
	const handleClickAddReward = () => {
		append({ val: '' });
		// バリデーションの実行
		void trigger('rewards');
	};
	const handleClickPreview = async () => {
		const res = await callAPI(getShopEntity(state.params.shopId));
		const shop = ShopEntity.create(res.data);
		const rewardIds = (watch('rewards') as { val: string }[]).map(r => r.val);
		const rewards = props.rewards.filter(reward => rewardIds.includes(reward.id));

		const values = {
			status: "unapplied",
			shopId: shop.id,
			shopName: shop.shopName ?? '',
			companyName: shop.companyName ?? '',
			logoImage: shop.logoImage ?? '',
			shopUrl: shop.shopUrl ?? '',
			contactType: shop.contactType ?? '',
			contactPhone: shop.contactPhone ?? '',
			contactMail: shop.contactMail ?? '',
			rewardTypes: [] as string[],
			rewardNames: [] as string[],
			rewardImages: [] as string[],
			rewardDescriptions: [] as string[],
			rewardKifuda: [] as string[],
			rewardMaruKifuda: [] as string[],
			rewardMaruKifudaM: [] as string[],
			rewardNamaebata: [] as string[],
			rewardNamaekonori: [] as string[],
		};

		for (const reward of rewards) {
			const opt = reward instanceof ItemReward ? reward.optionAttributes ?? {} : {};
			values.rewardTypes.push(reward.isCoupon ? 'coupon' : 'item');
			values.rewardNames.push(reward.name);
			values.rewardImages.push(
				reward instanceof CouponReward ? (reward.cabinetImageUrl ?? '') : 
				reward instanceof ItemReward ? (reward.image ?? '') : ''
			);
			values.rewardDescriptions.push(reward.description);
			values.rewardKifuda.push(opt.kifuda ? 'true' : 'false');
			values.rewardMaruKifuda.push(opt.maruKifuda ? 'true' : 'false');
			values.rewardMaruKifudaM.push(opt.maruKifudaM ? 'true' : 'false');
			values.rewardNamaebata.push(opt.namaebata ? 'true' : 'false');
			values.rewardNamaekonori.push(opt.namaekonori ? 'true' : 'false');
		}

		const url = new URL(previewPath, reviewFormUrl);

		const www = window.open('about:blank', 'blank');
		if (www) www.opener = null;

		const form = document.createElement('form');
		form.target = 'blank';
		form.action = url.toString();
		form.method = 'POST';

		const input = document.createElement('input');
		input.type = 'hidden';
		input.name = 'json';
		input.value = JSON.stringify(values);
		form.appendChild(input);

		const body = document.getElementsByTagName('body')[0];
		body.appendChild(form);
		form.submit();
		body.removeChild(form);
	};
	const handleChangeReward = (idx: number): React.ChangeEventHandler<HTMLSelectElement> => (ev) => {
		if (ev.currentTarget.value == '' && rewardFields.length > 1) {
			remove(idx);
		}
		const isCouponReward = props.rewards.some(reward => chooseRewardIds.includes(reward.id) && reward.isCoupon);
		setValue("isCouponReward", isCouponReward);
		// 特典送り先で「申込みフォームでお客様が選択」を選択後、レビュー特典からプレゼント品を外した場合、特典送り先をリセット
		if (getValues('destType') === 'form' && !props.rewards.some(reward => chooseRewardIds.includes(reward.id) && !reward.isCoupon)) {
			setValue('destType', initValues.destType);
		}
		// バリデーションの実行
		void trigger('rewards');
	};
	const { canChooseReward, canChooseDest, hasItemReward, disabledReviewForm, disabledDestType } = props.reviewFormCond();

	const chooseRewardIds: string[] = (watch('rewards') as { val: string }[]).map(r => r.val);
	const choiceRewards = rewardFields.map((reward, idx) => {
		return props.rewards.filter((reward) => reward.id === chooseRewardIds[idx] || !chooseRewardIds.includes(reward.id));
	});

	// キャンペーン開催中は特典の送り先を申込みフォームで選択 ⇔ 商品購入時と同じ配送先 or 注文者の住所 に変更不可
	const isStarted = Boolean(watch('inProgressDate'));
	const isFinishedCamp = Boolean(watch('waitingReviewDate'));
	const destTypeOriginal = watch('destTypeOriginal');
	let disabledDestTypeForm = false;
	let disabledDestTypeShippingOrderer = false;
	if (isStarted && !isFinishedCamp) {
		if (destTypeOriginal === 'form') {
			disabledDestTypeShippingOrderer = true;
		}
		if (destTypeOriginal === 'shipping' || destTypeOriginal === 'orderer') {
			disabledDestTypeForm = true;
		}
	}
	// 開催中で変更前の特典送り先が申込フォーム以外で単一の特典、または選択可能な特典をすべて追加済みは追加ボタン押下不可
	const isManyRewardOriginal = getValues("isManyRewardOriginal");
	const disabledAddReward = (isStarted && !isFinishedCamp && disabledDestTypeForm && !isManyRewardOriginal)
		|| (props.rewards.length == chooseRewardIds.length) ? true : false;

	const alerts = {
		destType: canChooseReward ? 'レビュー特典が複数設定されたため、申込みフォームでお客様に選択いただきます' : 
			!hasItemReward && 'レビュー特典がクーポンのみのため特典送り先情報は不要となります',
		shouldCloseApplication: (hasItemReward && !canChooseDest) ? '申込みフォームを利用しません' :
			!canChooseReward && '申込みフォームを利用しません',
	}

	return (
		<div>
			<div className="bl_panel_row">
				<h2 className="el_lv3Heading">レビュー特典</h2>
				{rewardFields.map((reward, idx) =>
					<div key={reward.id} className="el_selectWrap mb_16">
						<select name={`rewards[${idx}].val`} ref={register()} defaultValue={reward.val} onChange={handleChangeReward(idx)}>
							<option value=""></option>
							{choiceRewards[idx].map(r =>
								<option key={r.id} value={r.id}>{r.name}</option>
							)}
						</select>
					</div>
				)}
				<FormErrorMessage errors={errors} name="rewards" />
				<button className="el_btnInv" onClick={handleClickAddReward} disabled={props.rewards.length == chooseRewardIds.length || disabledAddReward}>追加</button>
				&nbsp;&nbsp;
				<button className="el_btnBlueInv" onClick={handleClickPreview} disabled={disabledReviewForm}>申込みフォームのプレビューを表示</button>
				{disabledAddReward && <>
					<p style={{ color: 'red' }}>※{ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED}</p>
				</>}
				<p className="fs_12 txt_blue mt_8">
					※追加したレビュー特典を削除したい場合、空白を選択すれば削除可能です。<br />
					※以下の条件で申込みフォームが有効となり、お客様が申込みフォームにアクセスしレビュー特典もしくは特典送り先を選ぶフローとなります<br />
				</p>
				<p className="fs_12 txt_blue mt_2 ml_8">
					・レビュー特典を複数設定された場合<br />
					・レビュー特典にプレゼント品、且つ特典送り先を「申込みフォームでお客様が選択」を設定された場合<br />
				</p>
				<p className="fs_12 txt_blue mt_2">
					※上記以外の条件では申込みフォームが無効となり、レビューの記載が確認できた時点でレビュー特典付与(申込受付完了メール送信)となります<br />
				</p>
			</div>
			<div className="bl_panel_row">
				<h2 className="el_lv3Heading">特典送り先（プレゼント品の場合）</h2>
				{disabledDestType && <>
					<p style={{ color: 'red' }}>※{alerts.destType}</p>
					{/* 以下表示のみ */}
					{canChooseReward && <>
						<label className="bl_label">
							<input type="radio" checked readOnly />
							<span>申込みフォームでお客様が選択</span>
						</label>
					</>}
				</>}
				{!disabledDestType && <>
					<label className="bl_label">
						<input type="radio" name="destType" value="form" ref={register} disabled={disabledDestType || disabledDestTypeForm} />
						<span>申込みフォームでお客様が選択</span>
					</label>
					<br />
					<label className="bl_label">
						<input type="radio" name="destType" value="shipping" ref={register} disabled={disabledDestType || disabledDestTypeShippingOrderer} />
						<span>商品購入時と同じ配送先 <span style={{ color: 'red' }}>※申込みフォームを利用しません</span></span>
					</label>
					<br />
					<label className="bl_label">
						<input type="radio" name="destType" value="orderer" ref={register} disabled={disabledDestType || disabledDestTypeShippingOrderer}/>
						<span>注文者の住所 <span style={{ color: 'red' }}>※申込みフォームを利用しません</span></span>
					</label>
					<FormErrorMessage errors={errors} name="destType" />
					{(disabledDestTypeForm || disabledDestTypeShippingOrderer) && <>
						<p style={{ color: 'red' }}>※{ERROR_CAMPAIGN_CHANGE_NOT_ALLOWED}</p>
					</>}
				</>}
			</div>
			<div className="bl_panel_row">
				<h2 className="el_lv3Heading">申込受付期間</h2>
				{disabledReviewForm && <>
					<p style={{ color: 'red' }}>※この項目は現在の設定では使用されません（{alerts.shouldCloseApplication}）</p>
				</>}
				{!disabledReviewForm && <>
					<label className="bl_label">
						<input type="radio" name="shouldCloseApplication" value="true" ref={register} disabled={disabledReviewForm} />
						<p className="bl_inputFormWrap">
							<span>期限を設定する&#12288;レビュー完了メール送付後から</span><input className="el_inlineInputM" type="text" name="applicationClosingDays" ref={register} onChange={nonNegative} disabled={disabledReviewForm} /><span> 日間有効</span>
						</p>
					</label>
					{shouldCloseApplication &&
						<FormErrorMessage errors={errors} name="applicationClosingDays" />
					}
					<br />
					<label className="bl_label">
						<input type="radio" name="shouldCloseApplication" value="false" ref={register} disabled={disabledReviewForm} /><span>無期限</span>
					</label>
				</>}
			</div>
		</div>
	);
}
export default CampaignEditFormStep2;