/* eslint-disable no-irregular-whitespace */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTimer } from '../../../lib';
import FormErrorMessage from '../FormErrorMessage';
import LinkGuide from '../LinkGuide';
import { changeValidValue } from '../reward/CampaignEditForm/DaysInput';
import { sortMails } from './SystemAlert';

interface SystemAlertFormProps {
	initValues: Record<string, string|number|boolean|string[]>;
	onSave: (values: Record<string, string|number|boolean|string[]>) => Promise<boolean>;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SystemAlertForm(props: SystemAlertFormProps): React.ReactElement {
	const { register, handleSubmit, setValue, watch, errors } = useForm({
		defaultValues: props.initValues,
	});
	const [result, setResult] = React.useState('');

	const setTimer = useTimer();

	const handleClickSave = handleSubmit(async (values) => {
		const res = await props.onSave({...values});
		setResult(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setResult(''), 6000);
		}
	});

	const handleOnChange = (type: string) => {
		if (type == 'isSendAlert') {
			if (!watch(type)) {
				setValue('alertContentType[]', []);
			}
		} else {
			const types = watch('alertContentType');
			setValue('isSendAlert', ((Array.isArray(types) ? types : [types]).length > 0));
		}
	}

	const isDisabled = (type: string) => {
		if (type == 'isSendAlert') {
			return !watch(type);
		} else {
			const types = watch('alertContentType');
			return !(Array.isArray(types) ? types : [types]).includes(type);
		}
	}

	return (
		<>
			<h1 className="el_pageTtl">システムアラート設定</h1>
			<p className="el_pageDesc">システム内で発生するアラートの通知方法の設定を行います。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row">
							<h2 className="el_lv2Heading mb_0">
								通知設定
								<LinkGuide guidePath='registration_setting/system_alert' link='メール通知可否' className='el_linkIcon__guideF' />
							</h2>
							<table className="bl_table bl_table__systemAlert">
								<tbody className="bl_table_body">
									<tr>
										<td>メール通知する</td>
										<td>
											<label className="el_toggleBtn__onoff">
												<input type="checkbox" name="isSendAlert" ref={register({
													validate: {
														require: v => {
															const types = watch('alertContentType');
															if (Boolean(v) === false && (Array.isArray(types) ? types : [types]).length > 0) {
																return '「メール通知する」を選択してください。';
															} else if (Boolean(v) && (Array.isArray(types) ? types : [types]).length <= 0) {
																return '通知内容を選択してください。';
															}
														},
													},
												})} onChange={() => handleOnChange('isSendAlert')} />
												<span />
											</label>
										</td>
									</tr>
								</tbody>
							</table>
							<h2 className="el_lv3Heading mt_16">通知先メールアドレス</h2>
							<input type="email" name="alertDestMail" placeholder="aaaaaaaa@aaa.co.jp"
								ref={register({
										validate: {
											required: v => {
												if (watch('isSendAlert')) {
													return v != '' || '通知先メールアドレスを入力してください';
												}
											},
											regexg: v => {
												if (watch('isSendAlert')) {
													const addresses: string[] = sortMails(v);
													return addresses.every(ad => ad.match(/\S+@\S+\.\S+/i)) || '通知先メールアドレスを入力してください１';
												}
											}
										},
									})}
								disabled={isDisabled('isSendAlert')}
								className={`${errors.alertDestMail ? 'is_error' : ''}`} 
								/>
							<FormErrorMessage errors={errors} name="alertDestMail" />
							<p className="txt_blue fs_12 mt_8">※通知先が複数必要な場合はカンマ区切りで入力可能です。</p>
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv2Heading">
								通知内容
								<LinkGuide guidePath='registration_setting/system_alert' link='通知内容' className='el_linkIcon__guideF' />
							</h2>
							<h2 className="el_lv3Heading mb_0">RMS WEB SERVICE 利用情報</h2>
							<table className="bl_table bl_table__systemAlert">
								<tbody className="bl_table_body">
									<tr>
										<td>
											<span>RMS WEB SERVICEの「licenseKey」の有効期限切れの </span>
											<input className="el_inlineInputM" type="text" name="daysBeforelicenseKeyExpire" ref={register({
													validate: {
														required: v => {
															const types = watch('alertContentType');
															if ((Array.isArray(types) ? types : [types]).includes('apiExpiryNotice')) {
																return v != '' || '「licenseKey」の有効期限切れの事前通知日数を入力してください。';
															}
														},
														validated: v => {
															const types = watch('alertContentType');
															if ((Array.isArray(types) ? types : [types]).includes('apiExpiryNotice')) {
																const vi = parseInt(v, 10);
																return (1 <= vi && vi <= 90) || '「licenseKey」の有効期限切れの事前通知日数は 1〜90 の範囲で入力してください。';
															}
														}
													}
												})} onChange={(ev) => changeValidValue(ev, 90, 1)} max={90}
												disabled={isDisabled('apiExpiryNotice')} />
											<span> 日前に通知する</span>
											<FormErrorMessage errors={errors} name="daysBeforelicenseKeyExpire" />
										</td>
										<td>
											<label className="el_toggleBtn__onoff">
												<input type="checkbox" name="alertContentType[]" value="apiExpiryNotice" ref={register} onChange={() => handleOnChange('alertContentType')} />
												<span />
											</label>
										</td>
									</tr>
									<tr>
										<td>RMS WEB SERVICEの「licenseKey」の有効期限切れを通知する</td>
										<td>
											<label className="el_toggleBtn__onoff">
												<input type="checkbox" name="alertContentType[]" value="apiExpired" ref={register} onChange={() => handleOnChange('alertContentType')} />
												<span />
											</label>
										</td>
									</tr>
								</tbody>
							</table>
							<p className="txt_blue fs_12">
								※「licenseKey」の有効期限が切れた場合、注文情報の取得が停止され<span className="txt_red txt_bold fs_14">キャンペーン対象の注文が追加されない状態</span>となります。<br />
								※「licenseKey」の有効期限をご確認の上、事前に更新手続きをお願いします。
							</p>
							<h2 className="el_lv3Heading mt_32 mb_0">楽天アカウント情報／レビューCSVアップロード期間</h2>
							<table className="bl_table bl_table__systemAlert">
								<tbody className="bl_table_body">
									<tr>
										<td>楽天ショップアカウントでRMSへログインできない場合に通知する</td>
										<td>
											<label className="el_toggleBtn__onoff">
												<input type="checkbox" name="alertContentType[]" value="shopAccountExpired" ref={register} onChange={() => handleOnChange('alertContentType')} />
												<span />
											</label>
										</td>
									</tr>
									<tr>
										<td>
											<span>レビューCSVが </span>
											<input className="el_inlineInputM" type="text" name="daysNotUploadedReviewCsv" ref={register({
													validate: {
														required: v => {
															const types = watch('alertContentType');
															if ((Array.isArray(types) ? types : [types]).includes('notUploadedReviweCsv')) {
																return v != '' || 'レビューCSVのアップロードで通知が必要な日数を入力してください。';
															}
														},
														validated: v => {
															const types = watch('alertContentType');
															if ((Array.isArray(types) ? types : [types]).includes('notUploadedReviweCsv')) {
																const vi = parseInt(v, 10);
																return (1 <= vi && vi <= 90) || 'レビューCSVのアップロードで通知が必要な日数は 1〜90 の範囲で入力してください。';
															}
														}
													}
												})} onChange={(ev) => changeValidValue(ev, 90, 1)} max={90}
												disabled={isDisabled('notUploadedReviweCsv')} />
											<span> 日間アップロードされていない場合に通知する</span>
											<FormErrorMessage errors={errors} name="daysNotUploadedReviewCsv" />
										</td>
										<td>
											<label className="el_toggleBtn__onoff">
												<input type="checkbox" name="alertContentType[]" value="notUploadedReviweCsv" ref={register} onChange={() => handleOnChange('alertContentType')} />
												<span />
											</label>
										</td>
									</tr>
								</tbody>
							</table>
							<p className="txt_blue fs_12">
								※「RMSへログインできない」もしくは「レビューCSVのアップロードがされていない」状態が続いた場合、<br />
								　レビューの監視が停止された状態となり<span className="txt_red txt_bold fs_14">新規のレビューの取得と取得したレビューと注文情報の紐付けがされない状態</span>となります。<br />
								※レビューの監視に楽天アカウント情報を利用する場合、楽天ショップアカウントのパスワードを更新された際はラクラクあつまレビューへの反映をお願いします。<br />
								※レビューの監視にレビューCSVのアップロードを利用する場合、指定期間アップロードがされていなければ通知を行いますので定期的なアップロードにご利用ください。
							</p>
							<FormErrorMessage errors={errors} name="isSendAlert" />
							<FormErrorMessage errors={errors} name="alertContentType" />
						</div>
						<div className="el_largeBtnWrap">
						<button className={"el_largeBtn " + result} onClick={handleClickSave}>保存</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default SystemAlertForm;