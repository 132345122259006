import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTimer } from '../../../lib';
import FormErrorMessage from '../FormErrorMessage';
import LinkGuide from '../LinkGuide';
import RakutenInput from './RakutenInput';
import { FormIsDirtys } from './RakutenSetting';

interface RakutenSettingShopAccountFormProps {
	initValues: Record<string, any>;
	setValues: Record<string, any>;
	onSave: (values: Record<string, any>) => Promise<boolean>;
	onInit: () => Promise<boolean>;
	setFormIsDirtys: React.Dispatch<React.SetStateAction<FormIsDirtys>>;
}

export function RakutenSettingShopAccountForm(props: RakutenSettingShopAccountFormProps): React.ReactElement {
	const { register, handleSubmit, errors, reset, formState: { isDirty } } = useForm({
		defaultValues: props.initValues,
	});
	React.useEffect(() => {
		props.setFormIsDirtys(prev => ({ ...prev, shopAccount: isDirty }));
	}, [isDirty]);

	const [result, setResult] = React.useState('');

	React.useEffect(() => {
		reset(props.initValues);
	}, [props.initValues]);

	const setTimer = useTimer();

	const handleClickSave = handleSubmit(async (values) => {
		const res = await props.onSave(values);
		reset(props.initValues);
		setResult(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setResult(''), 6000);
			reset(values);
		}
	});

	const handleClickInit = async () => {
		if (window.confirm("楽天ショップアカウント情報 を初期化してよろしいですか？")) {
			const res = await props.onInit();
			setResult(res ? 'is_success' : 'is_error');

			if (res) {
				setTimer(() => setResult(''), 6000);
				reset();
			}
		}
	};

	const shopAccountPassRules = props.setValues.shopAccountPass ?
		// 設定済み時(任意入力) : 未設定時(必須入力)
		{} : { required: 'パスワードを入力してください' };

	return (
		<>
			<h3 className="el_lv2Heading">楽天ショップアカウント情報</h3>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">
					ショップID
					<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_7-1' className='el_linkIcon__guideF' />
				</h3>
				<RakutenInput type="text" name="shopAccountId" ref={register({ required: 'ショップIDを入力してください' })}
					errors={errors} storedValue={props.setValues.shopAccountId} />
				<FormErrorMessage errors={errors} name="shopAccountId" />
			</div>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">
					パスワード
					<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_7-1' className='el_linkIcon__guideF' />
				</h3>
				<RakutenInput name="shopAccountPass" ref={register(shopAccountPassRules)}
					errors={errors} hide storedValue={props.setValues.shopAccountPass} />
				<FormErrorMessage errors={errors} name="shopAccountPass" />
			</div>
			<div className="el_largeBtnWrap mb_32">
				<button className={"el_largeBtn " + result} onClick={handleClickSave}>保存</button>
				<button className="el_largeBtnBlueInv" onClick={handleClickInit}>初期化</button>
			</div>
		</>
	);
}
export default RakutenSettingShopAccountForm;