import { Campaign, ItemGroup, MailContent, Reward } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { CampaignEditFormValues } from '.';
import { getReviewMail } from '../../../../api';
import { useAPI, useAppState } from '../../../../context';
import { toBR } from '../../../../lib';
import FormErrorMessage from '../../FormErrorMessage';
import { MailFormValue } from './CampaignEditFormStep4';
import { CampaignErrorList } from './CampaignErrorList';
import { DateFields } from './DateInput';

interface CampaignEditFormStep6Props {
	methods: UseFormMethods<CampaignEditFormValues>;
	rewards: Reward[];
	groups: ItemGroup[];
	setStepIdx: (idx: number) => void;
	reviewFormCond: () => Record<string, boolean>;
	campaign: Campaign;
}

export function CampaignEditFormStep6(props: CampaignEditFormStep6Props): React.ReactElement {
	const { trigger, getValues, watch, errors } = props.methods;

	React.useEffect(() => {
		const values = getValues();
		void trigger(Object.keys(values)); // react-hook-formの仕様上は trigger() で全チェックしてくれる筈だがしてくれないので
	}, []);

	const v = watch();
	const handleClickEdit = (idx: number) => () => {
		props.setStepIdx(idx);
		window.scrollTo(0, 0);
	};

	const dateFormat = (date: DateFields, sec: number): string => {
		return dayjs(date).second(sec).format("YYYY 年 MM 月 DD 日 HH 時 mm 分 ss 秒");
	}

	const isValidDateFormat = (date: DateFields): boolean => {
		return !Number.isNaN(new Date(date ?? '').getTime());
	}

	const { disabledReviewForm, disabledDestType } = props.reviewFormCond();

	return (
		<>
			<h2 className="el_lv2Heading">キャンペーン内容確認</h2>
			<section className="bl_confirm">
				<CampaignErrorList methods={props.methods} />
				<div className="bl_confirm_ttlWrap">
					<h3 className="bl_confirm_ttl">Step1 基本設定</h3>
					<button type="button" onClick={handleClickEdit(0)}>編集</button>
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading" id="キャンペーン名">キャンペーン名</h4>
					<p>{v.name}</p>
					<FormErrorMessage errors={errors} name="name" />
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading" id="キャンペーン実施期間">キャンペーン実施期間</h4>
					<p>{v.isNeverEnd == 'false' ? '期限を設定する' : '無期限'}</p>
					<p>開始 {isValidDateFormat(v.begin) ? dateFormat(v.begin, 0) : ''}</p>
					<FormErrorMessage errors={errors} name="begin" />
					{v.isNeverEnd == 'false' &&
						<>
							<p>終了 {isValidDateFormat(v.end) ? dateFormat(v.end, 59) : ''}</p>
								<FormErrorMessage errors={errors} name="end" />
						</>
					}
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading" id="レビュー受付期間">レビュー受付期間</h4>
					<p>{v.isCutCheck == 'date' ? '期限を設定する（対象日時まで）' : v.isCutCheck == 'days' ? '期限を設定する（商品発送後の指定日数期間）' : '無期限'}</p>
					{v.isCutCheck == 'date' &&
						<>
							<p>終了 {isValidDateFormat(v.deadlineDate) ? dateFormat(v.deadlineDate, 59) : ''}</p>
							<FormErrorMessage errors={errors} name="deadlineDate" />
						</>
					}
					{v.isCutCheck == 'days' &&
						<>
							<p>商品発送後 {v.deadlineDays ? v.deadlineDays + " 日まで" : ""}</p>
							<FormErrorMessage errors={errors} name="deadlineDays" />
						</>
					}
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading">対象条件</h4>
					<p>{
						{
							'item': '商品レビューを記入',
							'shop': 'ショップレビューを記入',
							'both': '商品・ショップレビューの両方を記入',
							'any': '商品・ショップレビューのどちらかを記入',
						}[v.reviewRequired]
					}</p>
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading">特典の取扱い</h4>
					<p>{v.canManyRewards == 'false' ? '購入分のうち１つのみを特典対象とする' : '購入分すべてを特典対象とする'}</p>
				</div>
			</section>
			<section className="bl_confirm">
				<div className="bl_confirm_ttlWrap">
					<h3 className="bl_confirm_ttl">Step2 特典設定</h3>
					<button type="button" onClick={handleClickEdit(1)}>編集</button>
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading" id="レビュー特典">レビュー特典</h4>
					{v.rewards.map((reward, idx) =>
						<p key={idx}>{props.rewards.find(r => r.id == reward.val)?.name}</p>
					)}
					<FormErrorMessage errors={errors} name="rewards" />
				</div>
				<div className={`bl_confirm_row ${disabledDestType && 'bl_confirm_row__disabled'}`}>
					<h4 className="el_lv3Heading" id="特典送り先の申込">特典送り先の申込</h4>
					{disabledDestType &&
						<p style={{ color: 'red' }}>※この項目は使用されません</p>
					}
					<p>
						{v.destType == 'shipping' ? '商品購入時と同じ配送先' :
							v.destType == 'orderer' ? '注文者の住所' : '申込みフォームでお客様が選択'}
					</p>
					<FormErrorMessage errors={errors} name="destType" />
				</div>
				<div className={`bl_confirm_row ${disabledReviewForm && 'bl_confirm_row__disabled'}`}>
					<h4 className="el_lv3Heading" id="送り先申込受付期間">送り先申込受付期間</h4>
					{disabledReviewForm &&
						<p style={{ color: 'red' }}>※この項目は使用されません</p>
					}
					{v.shouldCloseApplication == 'true' &&
						<>
							<p>期限を設定する レビュー完了メール送付後から {v.applicationClosingDays} 日間有効</p>
							<FormErrorMessage errors={errors} name="applicationClosingDays" />
						</>
					}
					{v.shouldCloseApplication == 'false' &&
						<p>無期限</p>
					}
				</div>
			</section>
			<section className="bl_confirm">
				<div className="bl_confirm_ttlWrap">
					<h3 className="bl_confirm_ttl" id="対象商品設定">Step3 対象商品設定</h3>
					<button type="button" onClick={handleClickEdit(2)}>編集</button>
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading">全商品対象</h4>
					<p>{v.itemGroup.isAll == 'true' ? 'ON' : 'OFF'}</p>
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading">対象商品</h4>
					{(!v.itemGroup.skus && v.itemGroup.childGroupIds.length <= 0) && '指定なし'}
					{v.itemGroup.skus && <>
						<h4 className="el_lv4Heading">対象商品管理番号</h4>
						<textarea className='mb_16' name="" rows={8} readOnly value={v.itemGroup.skus.split(/[\n\t,]/g).filter(Boolean).join(',')} />
					</>}
					{v.itemGroup.childGroupIds.length > 0 && <>
						<h4 className="el_lv4Heading">対象商品グループ</h4>
						<div className="bl_selectProduct_selected">
							{v.itemGroup.childGroupIds.map(id =>
								<React.Fragment key={id}><span>{props.groups.find(group => group.id == id)?.name}</span><br /><br /></React.Fragment>
							)}
						</div>
					</>}
					<FormErrorMessage errors={props.methods.errors} name="itemGroup.skus" />
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading">除外商品</h4>
					{(!v.itemGroup.excludeSkus && v.itemGroup.excludeChildGroupIds.length <= 0) && '指定なし'}
					{v.itemGroup.excludeSkus && <>
						<h4 className="el_lv4Heading">除外商品管理番号</h4>
						<textarea className='mb_16' name="" rows={8} readOnly value={v.itemGroup.excludeSkus.split(/[\n\t,]/g).filter(Boolean).join(',')} />
					</>}
					{v.itemGroup.excludeChildGroupIds.length > 0 && <>
						<h4 className="el_lv4Heading">除外商品グループ</h4>
						<div className="bl_selectProduct_selected">
							{v.itemGroup.excludeChildGroupIds.map(id =>
								<React.Fragment key={id}><span>{props.groups.find(group => group.id == id)?.name}</span><br /><br /></React.Fragment>
							)}
						</div>
					</>}
				</div>
			</section>
			<section className="bl_confirm">
				<div className="bl_confirm_ttlWrap">
					<h3 className="bl_confirm_ttl">Step4 メール設定</h3>
					<button type="button" onClick={handleClickEdit(3)}>編集</button>
				</div>
				<MailRow methods={props.methods} campaign={props.campaign} name="followMail" values={v.followMail}>フォローメール</MailRow>
				<MailRow methods={props.methods} campaign={props.campaign} name="requestMail" values={v.requestMail} disabled={disabledReviewForm}>レビュー完了メール</MailRow>
				<MailRow methods={props.methods} campaign={props.campaign} name="receivedMail" values={v.receivedMail}>申込受付完了メール</MailRow>
			</section>
			{/*
			<section className="bl_confirm">
				<div className="bl_confirm_ttlWrap">
					<h3 className="bl_confirm_ttl">Step5 バナー・ページ設定</h3>
					<button type="button" onClick={handleClickEdit(4)}>編集</button>
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading">キャンペーンバナー</h4>
					<p>テンプレートAを使用する</p>
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading">キャンペーンバナー貼付位置</h4>
					<p>商品ページ情報に 「&lt;!-- 任意のキーワード --&gt;」 を追加して貼付位置を設定する</p>
					<p>&lt;!-- 任意のキーワード --&gt;</p>
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading">キャンペーンページ</h4>
					<p>テンプレートを使用しない</p>
				</div>
				<div className="bl_confirm_row">
					<h4 className="el_lv3Heading">キャンペーンバナーリンク先</h4>
					<p>https://www.rakuten.ne.jp/gold/komari/</p>
				</div>
			</section>
			*/}
		</>
	);
}
export default CampaignEditFormStep6;

interface MailRow {
	methods: UseFormMethods<any>;
	values: MailFormValue | undefined;
	name: string;
	disabled?: boolean;
	children?: React.ReactNode;
	campaign?: Campaign;
}
const MailRow: React.FC<MailRow> = (props) => {
	const callAPI = useAPI();
	const [state] = useAppState();
	const [isOpen, setOpen] = React.useState(true);
	const [mailContent, setMailContent] = React.useState<MailContent | null>(null);

	React.useEffect(() => {
		if (!props.values) {
			return;
		}
		const formValues = {
			...props.values,
			campaign: props.campaign,
		};
		return callAPI(getReviewMail(state.params.shopId, 'preview', formValues), (err, result) => {
			if (err) {
				return;
			}
			setMailContent(new MailContent(result.data));
		});
	}, []);

	const handleClickHeader = () => {
		setOpen(prev => !prev);
	};

	return (
		<div className="bl_confirm_row">
			<section className={`bl_confirm_mailSection ${props.disabled && 'bl_confirm_mailSection__disabled'}`}>
				<h4 className={`bl_confirm_mailSection_ttl ${isOpen ? 'is_open' : ''}`} onClick={handleClickHeader} id={String(props.children)}>{props.children}</h4>
				<div>
					{props.disabled &&
						<p style={{ color: 'red' }}>※この項目は使用されません</p>
					}
					<h5 className="el_lv3Heading">メール送信設定</h5>
					<p>{props.values && props.values.disable === 'true' ? `${props.children}を送信しない` : `${props.children}を送信する`}</p>
					<FormErrorMessage errors={props.methods.errors} name={`${props.name}.disable`} />
					{mailContent && <>
						<h5 className="el_lv3Heading">メール件名</h5>
						<p>{mailContent.getSubject()}</p>
						<FormErrorMessage errors={props.methods.errors} name={`${props.name}.subject`} />
						<h5 className="el_lv3Heading">メール本文</h5>
						<p>{toBR(mailContent.getBody())}</p>
						<FormErrorMessage errors={props.methods.errors} name={`${props.name}.bodyElement`} />
					</>}
				</div>
			</section>
		</div>
	);
}
