import { APIRequest } from '.';
import { uri } from '../lib';

export function getNotices(shopId: string): APIRequest {
	const params = new URLSearchParams({ level: 'NOTICE' });
	return {
		method: 'get',
		url: uri`/api/notice/${shopId}/content?` + params.toString(),
	};
}
export function getWarnings(shopId: string): APIRequest {
	const params = new URLSearchParams({ level: 'WARNING' });
	return {
		method: 'get',
		url: uri`/api/notice/${shopId}/content?` + params.toString(),
	};
}
export function getAlerts(shopId: string): APIRequest {
	const params = new URLSearchParams({ level: 'ALERT' });
	return {
		method: 'get',
		url: uri`/api/notice/${shopId}/content?` + params.toString(),
	};
}
export function getNoticeShopEntity(shopId: string): APIRequest {
	return {
		method: 'get',
		url: `/api/notice/${encodeURIComponent(shopId)}/shop_entity`,
	};
}

export function putNoticeShopEntity(shopId: string, shopEntity: Record<string, unknown>): APIRequest {
	return {
		method: 'put',
		url: `/api/notice/${encodeURIComponent(shopId)}/shop_entity`,
		data: shopEntity,
	};
}