import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Campaign, ItemGroup, MailTemplate, NIL, Reward } from '@sasagase/types';
import * as React from 'react';
import { UseFormMethods, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { intersection } from 'superstruct';
import { useAppState } from '../../../../context';
import { useTimer } from '../../../../lib';
import CampaignEditFormStep1, { CampaignEditFormStep1FormValue, CampaignEditFormStep1Struct } from './CampaignEditFormStep1';
import CampaignEditFormStep2, { CampaignEditFormStep2FormValue, CampaignEditFormStep2Struct } from './CampaignEditFormStep2';
import CampaignEditFormStep3, { CampaignEditFormStep3FormValue, CampaignEditFormStep3Struct } from './CampaignEditFormStep3';
import CampaignEditFormStep4, { CampaignEditFormStep4FormValue, CampaignEditFormStep4Struct } from './CampaignEditFormStep4';
// import CampaignEditFormStep5 from './CampaignEditFormStep5';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../../lib/usePrompt';
import CampaignEditFormStep6 from './CampaignEditFormStep6';

type StepComponent = React.FC<{
	methods: UseFormMethods<any>;
	rewards: Reward[];
	groups: ItemGroup[];
	templates: MailTemplate[];
	setStepIdx: (idx: number) => void;
	reviewFormCond: () => Record<string, boolean>;
	campaign: Campaign;
}>;
interface Step {
	component: StepComponent;
	name: string;
	title: string;
}

const STEPS: Step[] = [
	{ component: CampaignEditFormStep1, name: 'Step1', title: '基本設定', },
	{ component: CampaignEditFormStep2, name: 'Step2', title: '特典設定', },
	{ component: CampaignEditFormStep3, name: 'Step3', title: '対象商品設定', },
	{ component: CampaignEditFormStep4, name: 'Step4', title: 'メール設定', },
	// { component: CampaignEditFormStep5, name: 'Step5', title: 'バナー・ページ設定', },
	// 本来なら確認・保存はStep6になるが、バナー・ページ設定を非表示にしているのでnameプロパティをStep5へ変更している
	{ component: CampaignEditFormStep6, name: 'Step5', title: '確認・保存', },
];

export type CampaignEditFormValues =
	CampaignEditFormStep1FormValue &
	CampaignEditFormStep2FormValue &
	CampaignEditFormStep3FormValue &
	CampaignEditFormStep4FormValue;

export const schema = intersection([
	CampaignEditFormStep1Struct,
	CampaignEditFormStep2Struct,
	CampaignEditFormStep3Struct,
	CampaignEditFormStep4Struct,
]);

interface CampaignEditFormProps {
	initValues: CampaignEditFormValues;
	onSave: (values: CampaignEditFormValues) => Promise<boolean>;
	rewards: Reward[];
	groups: ItemGroup[];
	templates: MailTemplate[];
	initStep?: number;
	toCampaign: (id: string, values: CampaignEditFormValues, groups: ItemGroup[], rewards: Reward[]) => Campaign;
}

export function CampaignEditForm(props: CampaignEditFormProps): React.ReactElement {
	const [state] = useAppState();
	const formMethods = useForm<CampaignEditFormValues>({
		defaultValues: props.initValues,
		shouldUnregister: false,
		mode: 'onBlur',
		resolver: superstructResolver(schema),
	});
	const { getValues, watch, reset, formState: { isDirty } } = formMethods;

	const [stepIdx, setStepIdx] = React.useState(props.initStep || 0);
	const [saveState, setSaveState] = React.useState('');
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	usePrompt(PROMPT_CONFIRM_MESSAGE, (isDirty && !isSubmitting));

	const reviewFormCond = () => {
		const rewardIds = (watch('rewards') as { val: string }[]).map(r => r.val);
		const rewards = props.rewards.filter(reward => rewardIds.includes(reward.id));
		const canChooseReward = rewards.length > 1;
		const hasItemReward = rewards.some(reward => !reward.isCoupon);
		const canChooseDest = hasItemReward && watch('destType') == 'form';
		return {
			canChooseReward,
			canChooseDest,
			hasItemReward,
			disabledReviewForm: !canChooseReward && !canChooseDest,
			disabledDestType: canChooseReward || !hasItemReward,
		};
	}

	const setTimer = useTimer();

	const handleClickProgress = (idx: number) => () => {
		setStepIdx(idx);
		window.scrollTo(0, 0);
	};
	const handleClickNext = () => {
		setStepIdx(idx => idx + 1);
		window.scrollTo(0, 0);
	};
	const handleClickPrev = () => {
		setStepIdx(idx => idx - 1);
		window.scrollTo(0, 0);
	};

	const handleClickSave = async () => {
		setIsSubmitting(true);
		const values = getValues();
		const res = await props.onSave(values);
		setSaveState(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setSaveState(''), 6000);
			reset(values);
		}
		setIsSubmitting(false);
	};

	const StepComponent = STEPS[stepIdx].component;
	const isFirst = stepIdx <= 0;
	const isLast = stepIdx >= STEPS.length - 1;
	const editingCampaign = props.toCampaign(NIL, getValues(), props.groups, props.rewards);

	return (
		<>
			<h1 className="el_pageTtl">キャンペーン登録</h1>
			<p className="el_pageDesc">キャンペーンの新規登録、確認・編集ができます。</p>
			<ol className="bl_progressTracker">
				{STEPS.map((step, idx) =>
					<ProgressTracker key={step.name} step={step} isActive={idx == stepIdx} onClick={handleClickProgress(idx)} />
				)}
			</ol>
			<p className="el_selectedStep show_mobile">基本設定</p>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<StepComponent methods={formMethods} rewards={props.rewards} groups={props.groups} templates={props.templates} setStepIdx={setStepIdx} reviewFormCond={reviewFormCond} campaign={editingCampaign} />
						<div className="el_largeBtnWrap">
							{!isFirst && !isLast &&
								<button className="el_largeBtn el_largeBtn__prev" type="button" onClick={handleClickPrev}>前のStep</button>
							}
							{!isLast &&
								<button className="el_largeBtn" type="button" onClick={handleClickNext}>次のStep</button>
							}
							{isLast &&
								<button className={"el_largeBtn " + saveState} type="button" onClick={handleClickSave}>保存</button>
							}
						</div>
						<Link className="el_backLink" to={`${state.params.basePath}/reward/campaign`}>≪一覧へ戻る</Link>
					</div>
				</div>
			</div>
		</>
	);
}
export default CampaignEditForm;

interface ProgressTrackerProps {
	step: Step;
	isActive: boolean;
	onClick: () => void;
}
function ProgressTracker({ step, isActive, onClick }: ProgressTrackerProps) {
	const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (ev) => {
		ev.stopPropagation();

		if (!isActive) {
			onClick();
		}
	};

	return (
		<li className={"bl_progressTracker_item" + (isActive ? ' is_active' : '')}>
			<a href={isActive ? undefined : ''} onClick={handleClick}>
				<span className="bl_progressTracker_step">{step.name}</span>
				<span className="bl_progressTracker_ttl">{step.title}</span>
			</a>
		</li>
	);
}