import { CouponReward, ItemGroup, ItemReward, NIL, Reward } from '@sasagase/types';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { getReward, postReward, putReward } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import RewardEditForm, { RewardEditFormValues } from './RewardEditForm';

interface RewardEditProps {}
type RewardEditParams = {
	rewardId?: string;
	flag?: string;
}

export interface SaveResult {
	result: boolean;
	errors?: {
		code: string,
		message: string,
	}[];
}

const initValues = {
	id: '',
	rewardType: 'item', // 'item' | 'coupon'
	name: '',
	description: '',
	image: '',
	limitedType: 'unlimited', // 'unlimited' | 'limited'
	quantity: '',
	csvFormat: 'general_v2',
	csvClientType: 'user', // 'orderer' | 'user'
	csvUserSettings: {},
	issueFreq: 'individual', // 'individual' | 'daily' | 'weekly' | 'monthly'
	discountType: 'price', // 'price' | 'rate' | 'shipping'
	discountPrice: '',
	discountRate: '',
	validPeriod: 'days',
	validDays: '',
	displayType: 'private', // 'private' | 'public'
	couponImageType: 'default', // 'default' | 'template' | 'user'
	template: '',
	userCouponImage: '',
	cabinetImageUrl: '',
	usingType: 'limited', // 'limited' | 'unlimited'
	usingLimit: '1',
	issueType: 'unlimited', // 'limited' | 'unlimited'
	issueLimit: '',
	combineUse: 'disallow', // 'disallow' | 'allow'
	conditionType: 'nothing', // 'nothing' | 'price' | 'amount'
	conditionPrice: '',
	conditionAmount: '',
	targetType: 'all', // 'all' | 'specify'
	couponTarget: '',
	conditionRankType: 'nothing', // 'nothing' | 'specify'
	conditionRankCond: [],
	purchaseHistoryCond: 'nothing', // 'nothing' | 'new' | 'repeat'
	dynamicPeriod: '',
	purchaseCount: '',
	purchaseCountLower: '',
	purchaseCountUpper: '',
	genderCond: 'nothing', // 'nothing' | 'male' | 'female'
	ageRangeCond: 'nothing', // 'nothing' | 'specify'
	ageRangeCondLower: '',
	ageRangeCondUpper: '',
	birthMonthCond: 'nothing', // 'nothing' | 'specify'
	birthMonth: '',
	multiPrefectureCond: 'nothing', // 'nothing' | 'specify'
	prefectureCond: []
};

function toReward(id: string|undefined, values: Partial<RewardEditFormValues>): Reward {
	if (values.rewardType == 'item') {
		const isUnlimited = values.limitedType == 'unlimited';

		const reward = new ItemReward({
			id: id || NIL,
			name: values.name,
			description: values.description,
			isCoupon: false,
			image: values.image,
			quantity: isUnlimited ? undefined : parseInt(values.quantity || '', 10),
			isUnlimited,
			csvFormat: values.csvFormat,
			csvClientType: values.csvClientType,
			csvUserSettings: values.csvUserSettings,
			optionAttributes: values.optionAttributes,
		});
		return reward;
	} else {
		const isDisplay = false; // 「公開しない」に固定
		const isUsingUnlimited = values.usingType == 'unlimited';
		const isIssueUnlimited = values.issueType == 'unlimited';
		const canCombineUse = values.combineUse == 'allow';
		const addItemGroup = values.targetType == 'specify';
		let itemGroup = undefined;
		if (addItemGroup) {
			itemGroup = ItemGroup.create({
				id: NIL,
				name: `レビュー特典 ${values.name}`,
				isRef: true,
				isAll: false,
				sku: String(values.couponTarget).split(/[,\t\n]/).map(str => str.trim()).filter(Boolean),
				excludeSku: [],
				childGroups: [],
				excludeChildGroups: [],
			});
		}
		
		const reward = new CouponReward({
			id: id || uuid(),
			name: values.name,
			description: values.description,
			isCoupon: true,
			issueFreq: values.issueFreq,
			discountType: values.discountType,
			discountPrice: values.discountType == 'price' ? parseInt(values.discountPrice || '', 10) : undefined,
			discountRate: values.discountType == 'rate' ? parseInt(values.discountRate || '', 10) : undefined,
			validPeriod: values.validPeriod,
			validDays: parseInt(values.validDays || '0', 10),
			isDisplay: isDisplay,
			couponImageType: values.couponImageType,
			template: values.couponImageType == 'template' ? values.template : undefined,
			userCouponImage: values.couponImageType == 'user' ? values.userCouponImage : undefined,
			cabinetImageUrl: values.couponImageType == 'default' ? '' : values.cabinetImageUrl,
			isUsingUnlimited: isUsingUnlimited,
			usingLimit: isUsingUnlimited ? undefined : parseInt(values.usingLimit || '', 10),
			isIssueUnlimited: isIssueUnlimited,
			issueLimit: isIssueUnlimited ? undefined : parseInt(values.issueLimit || '', 10),
			canCombineUse: canCombineUse,
			conditionType: values.conditionType,
			conditionPrice: values.conditionType == 'price' ? parseInt(values.conditionPrice || '', 10) : undefined,
			conditionAmount: values.conditionType == 'amount' ? parseInt(values.conditionAmount || '', 10) : undefined,
			couponItemGroup: itemGroup,
			conditionRankType: values.conditionRankType,
			conditionRankCond: values.conditionRankType == 'specify' ? values.conditionRankCond : undefined,
			purchaseHistoryCond: values.purchaseHistoryCond,
			dynamicPeriod: values.purchaseHistoryCond == 'repeat' ? parseInt(values.dynamicPeriod || '', 10) : undefined,
			purchaseCountLower: values.purchaseHistoryCond == 'repeat' ? parseInt(values.purchaseCountLower || '', 10) : undefined,
			purchaseCountUpper: values.purchaseHistoryCond == 'repeat' ? parseInt(values.purchaseCountUpper || '', 10) : undefined,
			genderCond: values.genderCond,
			ageRangeCond: values.ageRangeCond,
			ageRangeCondLower: values.ageRangeCond == 'specify' ? parseInt(values.ageRangeCondLower || '', 10) : undefined,
			ageRangeCondUpper: values.ageRangeCond == 'specify' ? parseInt(values.ageRangeCondUpper || '', 10) : undefined,
			birthMonthCond: values.birthMonthCond,
			birthMonth: values.birthMonthCond == 'specify' ? parseInt(values.birthMonth || '', 10) : undefined,
			multiPrefectureCond: values.multiPrefectureCond,
			prefectureCond: values.multiPrefectureCond == 'specify' ? values.prefectureCond : undefined,
		});
		return reward;
	}
}
function toValues(reward: ItemReward | CouponReward): RewardEditFormValues {
	const values = {
		...initValues,
		id: reward.id,
		name: reward.name,
		description: reward.description,
	};
	if (reward.isCoupon && reward instanceof CouponReward) {
		return {
			...values,
			rewardType: 'coupon',
			issueFreq: reward.issueFreq, // 'individual' | 'daily' | 'weekly' | 'monthly'
			discountType: reward.discountType, // 'price' | 'rate' | 'shipping'
			discountPrice: String(reward.discountPrice || ''),
			discountRate: String(reward.discountRate || ''),
			validPeriod: reward.validPeriod, // 'days' | 'nextMonth' | 'secondNextMonth'
			validDays: String(reward.validDays),
			displayType: reward.isDisplay ? 'public' : 'private', // 'private' | 'public'
			couponImageType: reward.couponImageType, // 'template' | 'user'
			template: String(reward.template || ''),
			userCouponImage: reward.userCouponImage || '',
			cabinetImageUrl: reward.cabinetImageUrl || '',
			usingType: reward.isUsingUnlimited ? 'unlimited' : 'limited', // 'limited' | 'unlimited'
			usingLimit: String(reward.usingLimit || ''),
			issueType: reward.isIssueUnlimited ? 'unlimited' : 'limited', // 'limited' | 'unlimited'
			issueLimit: String(reward.issueLimit || ''),
			combineUse: reward.canCombineUse ? 'allow' : 'disallow', // 'disallow' | 'allow'
			conditionType: reward.conditionType, // 'nothing' | 'price' | 'amount'
			conditionPrice: String(reward.conditionPrice || ''),
			conditionAmount: String(reward.conditionAmount || ''),
			targetType: reward.couponItemGroup ? 'specify' : 'all', // 'all' | 'specify'
			couponTarget: reward.couponItemGroup ? reward.couponItemGroup.sku.join(`\n`) : '',
			conditionRankType: reward.conditionRankType, // 'nothing' | 'specify'
			conditionRankCond: reward.conditionRankCond || [],
			purchaseHistoryCond: reward.purchaseHistoryCond, // 'nothing' | 'new' | 'repeat'
			dynamicPeriod: String(reward.dynamicPeriod || ''),
			purchaseCountLower: String(reward.purchaseCountLower || ''),
			purchaseCountUpper: String(reward.purchaseCountUpper || ''),
			genderCond: reward.genderCond, // 'nothing' | 'male' | 'female'
			ageRangeCond: reward.ageRangeCond, // 'nothing' | 'specify'
			ageRangeCondLower: String(reward.ageRangeCondLower || ''),
			ageRangeCondUpper: String(reward.ageRangeCondUpper || ''),
			birthMonthCond: reward.birthMonthCond, // 'nothing' | 'specify'
			birthMonth: String(reward.birthMonth),
			multiPrefectureCond: reward.multiPrefectureCond, // 'nothing' | 'specify'
			prefectureCond: reward.prefectureCond || [],
		};
	} else if (reward instanceof ItemReward) {
		return {
			...values,
			rewardType: 'item',
			image: reward.image,
			limitedType: reward.isUnlimited ? 'unlimited' : 'limited',
			quantity: String(reward.quantity || ''),
			csvFormat: reward.csvFormat,
			csvClientType: reward.csvClientType,
			csvUserSettings: reward.csvUserSettings,
			optionAttributes: reward.optionAttributes,
		};
	} else {
		throw false;
	}
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function RewardEdit(props: RewardEditProps): React.ReactElement|null {
	const params = useParams<RewardEditParams>();
	const isNew = params.rewardId == 'new';
	const isCopy = params.flag === 'copy';

	const callAPI = useAPI();
	const navigate = useNavigate();
	const [state] = useAppState();
	const [values, setValues] = React.useState<RewardEditFormValues | null>(isNew ? initValues : null);

	React.useEffect(() => {
		if (!params.rewardId || isNew) {
			return;
		}
		return callAPI(getReward(state.params.shopId, params.rewardId), (err, result) => {
			if (err) {
				return;
			}
			const reward = Reward.create(result.data);
			if (isCopy) {
				reward.name += ' のコピー';
			}
			setValues(toValues(reward));
		});
	}, [state.params.shopId, params.rewardId]);

	const handleSave = async (values: RewardEditFormValues, file?: File): Promise<SaveResult> => {
		const isCreate = isNew || isCopy;
		const id = isCreate ? uuid() : params.rewardId;
		const reward = toReward(id, values);
		try {
			let ret: SaveResult = {
				result: false
			};
			if (isCreate) {
				const res = await callAPI(postReward(state.params.shopId, reward, file));
				ret = res.data;
				if (ret.result) {
					navigate(`${state.params.basePath}/reward/reward/${id}`, {state: {newId: id}, replace: true});
				}
			} else {
				const res = await callAPI(putReward(state.params.shopId, reward.id, reward, file));
				ret = res.data;
			}
			if (!ret.result && !ret.errors) {
				ret.errors = [
					{ code: '', message: 'レビュー特典の保存に失敗しました。' }
				];
			}
			return ret;
		} catch (err) {
			return { result: false };
		}
	};

	if (!values) {
		return null; // loading
	}
	return <RewardEditForm initValues={values} onSave={handleSave} />;
}
export default RewardEdit;