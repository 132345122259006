import { MailNotCovered, NIL, ShopEntity } from '@sasagase/types';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getNotCovered, getShopEntity, postNotCovered, putNotCovered } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { NotCoveredEditForm } from './NotCoveredEditForm';

interface NotCoveredEditProps {}
type NotCoveredEditParams = {
	notCoveredId?: string;
	flag?: string;
}

const initValues = {
	name: '',
	subject: '',
	body: '',
	signature: '',
	email: '',
};
type FormValues = typeof initValues;

function toMailNotCovered(id: string|undefined, values: Partial<FormValues>): MailNotCovered {
	return new MailNotCovered({
		id: id || NIL,
		name: values.name,
		subject: values.subject,
		body: values.body,
		signature: values.signature,
		isEnable: false, // ここでの更新時は無視する
		isNonRakutenMemberEnabled: false,
	});
}
function toValues(notCovered: MailNotCovered): FormValues {
	return {
		...initValues,
		name: notCovered.name,
		subject: notCovered.subject,
		body: notCovered.body,
		signature: notCovered.signature,
	};
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function NotCoveredEdit(props: NotCoveredEditProps): React.ReactElement | null {
	const params = useParams<NotCoveredEditParams>();
	const isNew = params.notCoveredId == 'new';
	const isCopy = params.flag === 'copy';

	const navigate = useNavigate();
	const callAPI = useAPI();
	const [state] = useAppState();
	const [values, setValues] = React.useState<FormValues | null>(null);

	React.useEffect(() => {
		if (!params.notCoveredId || isNew || !state.shop) {
			return;
		}
		const email = String(state.shop.mail);
		return callAPI(getNotCovered(state.params.shopId, params.notCoveredId), (err, result) => {
			if (err) {
				return;
			}
			const notCovered = new MailNotCovered(result.data);
			if (isCopy) {
				notCovered.name += ' のコピー';
			}
			setValues({
				...toValues(notCovered),
				email: email,
			});
		});
	}, [state.params.shopId, params.notCoveredId, state.shop]);

	React.useEffect(() => {
		if (!state.shop || !isNew) {
			return;
		}
		const email = String(state.shop.mail);
		return callAPI(getShopEntity(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const shopEntity = new ShopEntity(result.data);
			setValues({
				...initValues,
				signature: shopEntity.signature,
				email: email,
			});
		});
	}, [state.shop]);

	const handleSave = async (values: Record<string, any>) => {
		const id = isNew || isCopy ? '' : params.notCoveredId;
		const notCovered = toMailNotCovered(id, values);
		try {
			if (!id) {
				const res = await callAPI(postNotCovered(state.params.shopId, notCovered));
				const id = res.data;
				navigate(state.params.basePath + '/mail/not-covered/' + id, {state: {newId: id}, replace: true});
				return true;
			} else {
				const res = await callAPI(putNotCovered(state.params.shopId, notCovered.id, notCovered));
				return Boolean(res);
			}
		} catch (err) {
			return false;
		}
	};
	
	if (!values) {
		return null; // loading
	}

	return <NotCoveredEditForm initValues={values} onSave={handleSave} />;
}
export default NotCoveredEdit;
