import * as React from 'react';
import { Navigate, useParams } from 'react-router-dom';

interface AccountCompletedProps {}
type AccountCompletedParams ={
	type?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AccountCompleted(props: AccountCompletedProps): React.ReactElement | null{
	const params = useParams<AccountCompletedParams>();
	const [redirect, setRedirect] = React.useState<string | null>(null);
	const toString = (type: string |undefined) => {
		return type ? {
			'regist': 'アカウントの登録が完了しました。',
			'reset': 'パスワードの再設定が完了しました。'
		}[type] : '';
	};

	const handleClick = () => {
		setRedirect("/");
		return;
	}

	if (redirect) {
		return <Navigate to={redirect} />;
	}

	return (
		<div className="ly_reissue">
			<div className="ly_reissue_inner">
				<h1 className="el_entryTitle"><img src="/assets/img/logo-w.svg" alt="ラクラクあつまレビュー" /></h1>
				<div className="bl_inviteForm">
					<div className="bl_loginFormRow">
						<p className="txt_blue">
							{toString(params.type)}<br />
						</p>
					</div>
					<button className="el_largeBtn el_largeBtn__fullWidth" type="button" onClick={handleClick}>ラクラクあつまレビュー を始める</button>
				</div>
			</div>
		</div>
	);
}
export default AccountCompleted;