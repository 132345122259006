import { ErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import { FieldErrors } from 'react-hook-form';

interface ErrorMessageProps {
	errors: FieldErrors;
	name: string;
	message?: string;
}
export const FormErrorMessage: React.FC<ErrorMessageProps> = (props) => {
	return <ErrorMessage
		{...props}
		render={({message}) =>
			<p style={{color: 'red'}}>{message}</p>
		}
	/>;
}
export default FormErrorMessage;
