import * as React from 'react';
import api, { APICaller, APIRequest } from '../api';
import { ContextState } from './state';

export * from './setter';
export * from './state';

type CanArray<T> = T | T[];
export type SetStateActions = CanArray<React.SetStateAction<ContextState>>;
type AppDispatch = React.Dispatch<SetStateActions>;
export type StateSetTuple = [ContextState, AppDispatch];

function defaultFunc(...args: unknown[]) {
	console.error('Not under the provider.', ...args);
}

const context = React.createContext<StateSetTuple>([{}, defaultFunc]);
const callAPIContext = React.createContext<APICaller>(api(defaultFunc));

export const providers = {
	state: context.Provider,
	api: callAPIContext.Provider,
};

export function useAPI(): APICaller {
	return React.useContext(callAPIContext);
}

type APILoadConverter<T> = (all: T | undefined, current: T | undefined) => T | undefined;

interface APILoadController<T> {
	reload: ((initValue: T) => void) & (() => void);
	setConverter: ((callback: APILoadConverter<T>) => void);
	count: number;
	isLoading: boolean;
}
type UseAPILoadRet<T> = [T, APILoadController<T>];

interface APILoadParameter<T, U> {
	initValue?: T;
	onFetch: (data: U) => T;
	onConverter?: APILoadConverter<T>;
}

function defaultConverter<T>(all: T | undefined, current: T | undefined): T | undefined {
	return all || current;
}

export function useAPILoad<T, U = any>(apiRequest: APIRequest<U> | null, params: APILoadParameter<T, U>): UseAPILoadRet<T | undefined> {
	const { initValue, onFetch, onConverter } = params;

	const hasApiRequest = Boolean(apiRequest);

	interface State {
		count: number;
		all: T | undefined;
		value: T | undefined;
		isLoading: boolean;
		onConverter: APILoadConverter<T>;
	}
	const [state, setState] = React.useState<State>({
		count: 0,
		all: initValue,
		value: initValue,
		isLoading: hasApiRequest,
		onConverter: onConverter || defaultConverter,
	});
	const callAPI = useAPI();

	React.useEffect(() => {
		if (!apiRequest) {
			return;
		}

		return callAPI(apiRequest, (err, result) => {
			if (err) {
				setState(prev => ({
					...prev,
					isLoading: false,
				}));
				return;
			}

			const value = onFetch(result.data);
			setState(prev => ({
				...prev,
				all: value,
				value: prev.onConverter(value, prev.value),
				isLoading: false,
			}));
		});
	}, [state.count, hasApiRequest]);

	const reload = React.useCallback((initValue?: T) => {
		setState(prev => ({
			...prev,
			count: prev.count + 1,
			value: initValue === undefined ? prev.value : prev.onConverter(initValue, prev.value),
			isLoading: hasApiRequest,
		}));
	}, [setState, hasApiRequest]);

	const setConverter = React.useCallback((callback: APILoadConverter<T>) => {
		setState(prev => ({
			...prev,
			value: callback(prev.all, prev.value),
			onConverter: callback,
		}));
	}, [setState]);

	const ret = React.useMemo<UseAPILoadRet<T | undefined>>(() => {
		const controller = {
			reload,
			setConverter,
			count: state.count,
			isLoading: state.isLoading,
		};
		return [state.value, controller];
	}, [state]);

	return ret;
}

export function useAppState(): StateSetTuple {
	return React.useContext(context);
}
