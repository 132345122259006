import { Help } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { getArticles, postContact } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import ContactCompleted from './ContactCompleted';
import ContactConfirm from './ContactConfirm';
import ContactInput from './ContactInput';

export const CONTACT_SUBJECTS: Record<string, string> = {
	initSetting: '初期設定（楽天システム・店舗・メール設定）について',
	reviewSetting: 'レビュー施策（レビュー特典・キャンペーン）について',
	order: '注文の状況確認について',
	contract: '契約・料金について',
	trouble: '不具合・トラブルについて',
	request: 'ご意見・ご要望',
	other: 'その他',
};

const initValues = {
	formType: 'adminContact',
	name: '',
	email: '',
	subject: '',
	content: '',
	orderNumber: '',
};
export type FormValues = typeof initValues;

interface ContactProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Contact(props: ContactProps): React.ReactElement {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const basePath = state.params.basePath;
	const shopName = state.shop?.name;
	const methods = useForm<FormValues>({
		defaultValues: initValues,
		shouldUnregister: false,
	});
	const { handleSubmit } = methods;

	const callAPI = useAPI();
	const [formType, setFormType] = React.useState<'input'|'confirm'|'completed'>('input');
	const [btnClass, setBtnClass] = React.useState<string>('');
	const [articles, setArticles] = React.useState<Help.Article[]>([]);

	React.useEffect(() => {
		const cond = {
			articleType: 'faq' as const,
			termIdOrSlug: 'showContact'
		};
		return callAPI(getArticles(shopId, cond), (err, res) => {
			if (err) {
				return;
			}
			const articles: Help.Article[] = res.data.map((obj: Record<string, unknown>) => Help.Article.create(obj));
			setArticles(articles);
		});
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleClickConfirm = handleSubmit(async (values)  => {
		setFormType('confirm');
	});
	const handleClickBack = ()  => {
		setFormType('input');
	};

	const handleClickSend = handleSubmit(async (values) => {
		const form = {
			...values,
			shopId: state.shop?.id,
			shopName,
			loginUserName: state.user?.name,
			loginUserMail: state.user?.mail,
			subjectTitle: CONTACT_SUBJECTS[values.subject],
			navigator: JSON.stringify({
				userAgent: window.navigator.userAgent,
				language: window.navigator.language,
			}, undefined, '  '),
		};
		const res = await callAPI(postContact(state.shop?.id ?? '', form));
		if (res.data) {
			setFormType('completed');
		} else {
			setBtnClass('is_error');
		}
		return res.data;
	});

	return (
		<>
			<h1 className="el_pageTtl">よくある質問</h1>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row bl_faqList">
							<ul className="mb_0">
								{articles.map((article) => 
									<li key={article.id}><span><a href={`${basePath}/faq/${article.id}`}>{article.titleShort}</a></span></li>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<h1 className="el_pageTtl">メールでのお問い合わせ</h1>
			{formType === 'input' && <p className="el_pageDesc">下記フォームにご記入の上、「確認画面へ」ボタンを押してください。</p>}
			{formType === 'confirm' && <p className="el_pageDesc">入力内容確認後、「送信」ボタンを押してください。</p>}
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					{formType === 'input' &&
						<ContactInput methods={methods}
							shopName={shopName}
							onConfirm={handleClickConfirm}
							/>
					}
					{formType === 'confirm' &&
						<ContactConfirm methods={methods}
							shopName={shopName}
							onSend={handleClickSend}
							onBack={handleClickBack}
							btnClass={btnClass}
							/>
					}
					{formType === 'completed' &&
						<ContactCompleted basePath={state.params.basePath} />
					}
				</div>
			</div>
			{formType === 'input' && <>
				<h1 className="el_pageTtl">お電話でのお問い合わせ</h1>
				<p className="el_pageDesc">ご質問・ご要望など、お気軽にお問い合わせください。</p>
				<div className="bl_row">
					<div className="bl_col bl_col__8">
						<div className="bl_panel bl_panel__bt">
							<div className='fs_18 txt_bold'>086-239-0344</div>
							<div className="fs_12 mt_8">受付時間 11:00 ~ 17:30（土・日・祝日を除く）</div>
						</div>
					</div>
				</div>
			</>}
		</>
	);
}
export default Contact;