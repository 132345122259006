import * as React from 'react';
import { useForm } from 'react-hook-form';
import { postImage } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { useTimer } from '../../../lib';
import { previewPath, reviewFormUrl } from '../../../lib/reviewForm';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../../lib/usePrompt';
import FormErrorMessage from '../FormErrorMessage';

interface ShopSettingProps {
	initValues: Record<string, string|number|boolean>;
	onSave: (values: Record<string, string|number|boolean>) => Promise<boolean>;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ShopSetting(props: ShopSettingProps): React.ReactElement {
	const { register, handleSubmit, getValues, setValue, watch, errors, reset, formState: { isDirty } } = useForm({
		defaultValues: props.initValues,
	});
	usePrompt(PROMPT_CONFIRM_MESSAGE, isDirty);

	const callAPI = useAPI();
	const [state] = useAppState();
	const [result, setResult] = React.useState('');

	const setTimer = useTimer();

	React.useEffect(() => {
		register('logoImage');
	}, [register]);

	const handleChangeFile = async (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!ev.target.files?.length) {
			return;
		}
		const format = /^((.{0,20}).*)(\.(?:jpg|jpeg|png|gif|bmp|tif))$/i;
		if (!format.test(ev.target.files[0].name)) {
			alert('規定外のファイル形式です。画像ファイル(gif、jpg、jpeg、tif、png、bmp)を選択してください。');
			return;
		}
		const id = await callAPI(postImage(state.params.shopId, ev.target.files[0]));
		setValue(ev.target.name, id.data);
		ev.target.value = '';
	};

	const handleClickDeleteImage = (name: string) => () => {
		setValue(name, '');
	};

	const handleClickSave = handleSubmit(async (values) => {
		const res = await props.onSave({...values});
		setResult(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setResult(''), 6000);
			reset(values);
		}
	});

	const handleClickPreview = (status: string) => {
		const values = {
			...getValues(),
			status,
			shopId: state.params.shopId,
		};
		const url = new URL(previewPath, reviewFormUrl);
		url.search = '?' + new URLSearchParams(values).toString();
		const www = window.open(url, "_blank", "noopener,noreferrer");
		if (www) www.opener = null;
	};

	const logoImage = watch('logoImage');

	return (
		<>
			<h1 className="el_pageTtl">店舗設定</h1>
			<p className="el_pageDesc">お客様がレビュー特典申込の URL にアクセスした際などにこちらで設定した店舗情報が反映されます。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__8 bl_col__mobileFullWidth">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">店舗名</h2>
							<input type="text" name="shopName" ref={register({ required: '店舗名を入力してください' })} />
							<FormErrorMessage errors={errors} name="shopName" />
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">運営会社名</h2>
							<input type="text" name="companyName" ref={register({ required: '運営会社名を入力してください' })} />
							<FormErrorMessage errors={errors} name="companyName" />
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">店舗ロゴ</h2>
							<div className="bl_imgUpload">
								<div className="bl_imgUpload_logoimgWrap">
									{logoImage &&
										<img src={`/storage/${state.params.shopId}/${logoImage}`} />
									}
								</div>
								<div className="bl_imgUpload_txtWrap">
									<label className="el_btnTxt">
									<input type="file" name="logoImage" onChange={handleChangeFile} />
										画像のアップロード
									</label>
									<button className="el_btnTxt" onClick={handleClickDeleteImage('logoImage')}>削除</button>
									<p>※画像サイズは280px×100pxを推奨</p>
								</div>
							</div>
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">店舗URL</h2>
							<input type="text" name="shopUrl" ref={register({ required: '店舗URLを入力してください' })} />
							<FormErrorMessage errors={errors} name="shopUrl" />
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">問い合わせ先</h2>
							<label className="bl_label">
								<input type="radio" name="contactType" value="phone" ref={register({ required: '問い合わせ先を選択してください' })} />
								<span>電話番号を設定する</span>
							</label>
							<div className="pl_26">
								<input type="text" name="contactPhone" ref={register({
									validate: (v) => (getValues('contactType') == 'phone' && v == '') ? '問い合わせ先の電話番号を入力してください' : undefined
								})} />
								<FormErrorMessage errors={errors} name="contactPhone" />
							</div>
							<label className="bl_label">
								<input type="radio" name="contactType" value="mail" ref={register({ required: '問い合わせ先を選択してください' })} />
								<span>メールアドレスを設定する</span>
							</label>
							<div className="pl_26">
								<input type="text" name="contactMail" ref={register({
									validate: (v) => (getValues('contactType') == 'mail' && /\S+@\S+.\S+/.test(v) == false) ? '問い合わせ先のメールアドレスを入力してください' : undefined,
								})} />
								<FormErrorMessage errors={errors} name="contactMail" />
							</div>
							<FormErrorMessage errors={errors} name="contactType" />
						</div>
						<div className="bl_panel_row">
							<h2 className="el_lv3Heading">申込みフォームのプレビュー</h2>
							<button className="el_btnBlueInv mr_8" onClick={() => handleClickPreview('unapplied')}>申込み前のプレビューを表示</button>
							<button className="el_btnBlueInv" onClick={() => handleClickPreview('applied')}>申込み後のプレビューを表示</button>
						</div>
						<div className="el_largeBtnWrap">
						<button className={"el_largeBtn " + result} onClick={handleClickSave}>保存</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default ShopSetting;