import * as React from 'react';
import { useForm } from 'react-hook-form';
import { disableTwoFactorAuth, getTwoFactorAuth, getTwoFactorAuthCode, putTwoFactorAuth } from '../../api';
import { useAPI } from '../../context';
import { useTimer } from '../../lib';
import { PROMPT_CONFIRM_MESSAGE, usePrompt } from '../../lib/usePrompt';
import PasswordInput from '../PasswordInput';
import FormErrorMessage from './FormErrorMessage';

interface TwoFactorAuthEditProps { }
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TwoFactorAuthEdit(props: TwoFactorAuthEditProps): React.ReactElement {
	const { register, handleSubmit, errors, reset, formState: { isDirty } } = useForm({
		defaultValues: {
			code: '',
			password: '',
		},
	});
	usePrompt(PROMPT_CONFIRM_MESSAGE, isDirty);

	const callAPI = useAPI();
	const [result, setResult] = React.useState('');
	const [isTwoFactorAuth, setIsTwoFactorAuth] = React.useState<null|boolean>(null);
	const [qrCodeUrl, setQrCodeUrl] = React.useState('');
	const [secretKey, setSecretKey] = React.useState('');
	const setTimer = useTimer();

	React.useEffect(() => {
		return callAPI(getTwoFactorAuth(), (err, result) => {
			setIsTwoFactorAuth(result.data);
			if (!result.data) {
				void getQrCode();
			}
		});
	}, [isTwoFactorAuth]);

	const handleClickBack = (ev: React.MouseEvent) => {
		ev.preventDefault();
		history.go(-1);
	};
	const handleClickDisabledSave = handleSubmit(async (values) => {

		const ret = await callAPI(disableTwoFactorAuth(values.password));
		if (ret.data) {
			setResult('is_success');
			setTimer(() => setResult(''), 6000);
			setIsTwoFactorAuth(false);
			reset(values);
		} else {
			setResult('is_error');
		}
	});
	const handleClickSave = handleSubmit(async (values) => {

		const ret = await callAPI(putTwoFactorAuth(values.code));
		if (ret.data) {
			setResult('is_success');
			setTimer(() => setResult(''), 6000);
			setIsTwoFactorAuth(true);
			reset(values);
		} else {
			setResult('is_error');
		}
	});

	const getQrCode = async () => {
		try {
			const ret = await callAPI(getTwoFactorAuthCode());
			if (ret.data) {
				setQrCodeUrl(ret.data.qrCodeUrl);
				setSecretKey(ret.data.secretKey);
			}
		} catch (err) {
			return;
		}
	}

	const passClassName = result == 'is_error' ? 'is_error' : '';

	return (
		<>
			<h1 className="el_pageTtl">2段階認証設定</h1>
			<p className="el_pageDesc">
				設定が完了すると、ログイン時に、Google Authenticatorなどの認証アプリで生成されたコードの入力が必要となります。<br />
				2段階認証についての詳細はご利用ガイドページをご覧ください。</p>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						{(isTwoFactorAuth !=null && isTwoFactorAuth) &&
							<div className="bl_panel_row">
								<h2 className="el_lv2Heading">2段階認証が設定中</h2>
								<div className="bl_panel_row bl_panel_row__indent">
									<p>
										解除する場合は現在のパスワードを入力してください
									</p>
									<div className="bl_panel_row bl_panel_row__indent">
										<h3 className="el_lv3Heading">現在のパスワード</h3>
										{/* NOTE: エラー: is_error */}
										<PasswordInput name="password"
											ref={register({
												minLength: {
													value: 8,
													message: 'パスワードは8文字以上で入力してください。',
												},
											})}
										/>
										<FormErrorMessage errors={errors} name="password" />
										{/* NOTE: エラーメッセージ: span要素 */}
										{passClassName &&
											<span>パスワードが異なります</span>
										}
									</div>
									<div className="el_largeBtnWrap">
										<button className={"el_largeBtn " + result} type="button" onClick={handleClickDisabledSave}>解除</button>
									</div>
								</div>
							</div>
						}
						{(isTwoFactorAuth != null && !isTwoFactorAuth) &&
							<>
								<div className="bl_panel_row">
									<h2 className="el_lv2Heading">認証用アプリをダウンロード</h2>
									<div className="bl_panel_row bl_panel_row__indent">
										<p>
											2段階認証を設定する際に、認証アプリのGoogle Authenticatorが必要となります。<br />
											お手持ちのスマートフォンまたはタブレットへGoogle Authenticatorをダウンロードしてください。<br />
											Google Authenticatorは
											<span className='el_externalLink'>
												<a className='el_externalLink' href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" rel="noopener noreferrer" target="_blank">Google Play ストア</a>
											</span>
											または
											<span className='el_externalLink'>
												<a href="https://apps.apple.com/us/app/google-authenticator/id388497605" rel="noopener noreferrer" target="_blank">iOS App Store </a>
											</span>
											からダウンロードできます。
										</p>
									</div>
								</div><div className="bl_panel_row">
									<h2 className="el_lv2Heading">バーコードをスキャン</h2>
									<div className="bl_panel_row bl_panel_row__indent">
										<h3 className="el_lv3Heading">スマートフォンのカメラでスキャンする</h3>
										<p>
										Google Authenticatorで[+]アイコンをタップします。<br />
											スマートフォンのカメラを使って下記の画像をスキャンしてください。
										</p>
										{qrCodeUrl && <img src={qrCodeUrl} alt="QR Code" style={{ width: '200px' }} />}
										<h3 className="el_lv3Heading">スキャンできない場合</h3>
										<p>
											上記のバーコードがスキャンできない場合、手動で入力することができます。<br />
											1. Google Authenticatorで、[+] &gt; [セットアップ キーを入力] をタップします。<br />
											2. 以下のキーを入力します。<br />
											{secretKey && <span className='ml_16'>{secretKey}</span>}<br />
											3. [時間ベース] が選択されていることを確認します<br />
											4. [追加] をタップして終了します<br />
										</p>
									</div>
								</div><div className="bl_panel_row">
									<h2 className="el_lv2Heading mb_24">認証コードの入力</h2>
									<div className="bl_panel_row bl_panel_row__indent">
										<h3 className="el_lv3Heading">スキャン後、アプリに表示される６桁のコードを入力してください</h3>
										<div className="bl_panel_row bl_panel_row__indent">
											<input type="text" name="code"
												ref={register({
													required: '確認コードを入力してください',
													maxLength: {
														value: 6,
														message: '確認コードは6文字で入力してください。',
													},
													minLength: {
														value: 6,
														message: '確認コードは6文字で入力してください。',
													},
												})}
												className={`${errors.code ? 'is_error' : ''}`} />
											<FormErrorMessage errors={errors} name="code" />
										</div>
									</div>
								</div><div className="el_largeBtnWrap">
									<button className={"el_largeBtn " + result} type="button" onClick={handleClickSave}>保存</button>
								</div></>
						}
						<a className="el_backLink" href="#" onClick={handleClickBack}>≪戻る</a>
					</div>
				</div>
			</div>
		</>
	);
}
export default TwoFactorAuthEdit;