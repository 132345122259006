import { MASTER_ENTITY_ID } from '@sasagase/types';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { userLogout } from "../../api";
import { useAPI, useAppState, userLogoutSetter } from '../../context';

interface HeaderProps {
	onClickBurger: () => void;
	basePath: string;
}

function Header(props: HeaderProps): React.ReactElement {
	const [state, setState] = useAppState();
	const callAPI = useAPI();
	const [open, setOpen] = React.useState('');
	const refOpen = React.useRef<EventTarget>();
	const navigate = useNavigate();

	React.useEffect(() => {
		const docClickHandler = (ev: MouseEvent) => {
			if (ev.target == refOpen.current) {
				return;
			}
			setOpen('');
		};
		document.body.addEventListener('click', docClickHandler);
		return () => document.body.removeEventListener('click', docClickHandler);
	}, []);

	const handleClickMenu = (name: string) => (ev: React.MouseEvent) => {
		if (open == name) {
			setOpen('');
		} else {
			setOpen(name);
			refOpen.current = ev.target;
		}
	};
	const handleClickShop = (shopId: string) => (ev: React.MouseEvent) => {
		ev.preventDefault();
		const rebasePath = props.basePath.split('/');
		if (!rebasePath.length) {
			return;
		}

		rebasePath[rebasePath.length - 1] = shopId;
		navigate(rebasePath.join('/'));
	};
	const handleClickManagement = (ev: React.MouseEvent) => {
		ev.preventDefault();
		navigate(props.basePath + '/management');
	};
	const handleClickLogout = (ev: React.MouseEvent) => {
		ev.preventDefault();
		callAPI(userLogout(), (err, result) => {
			if (result.data) {
				setState(userLogoutSetter());
				navigate('/');
			}
		});
	};
	const getShopName = (shopId: string) => state.shops?.[shopId]?.name || shopId;
	const selectShops = state.user?.getEnableCaps('review_login').filter(cap => cap.entityId !== MASTER_ENTITY_ID) || [];

	return (
		<header className="ly_header">
			<div className="ly_header_inner ly_header_inner__ttl">
				<button className="el_navToggle menu" type="button" onClick={props.onClickBurger} />
				<p className="el_systemName">
					<picture>
						<img src="/assets/img/logo-c.svg" alt="ラクラクあつまレビュー" />
					</picture>
				</p>
				<div className="el_links">
					<Link className="el_linkIcon el_linkIcon__faqL" to={props.basePath + '/faq'}><span><br className="br" />よくある<br className="br" />質問</span></Link>
					<Link className="el_linkIcon el_linkIcon__guideL" to={props.basePath + '/guide/use_guide/top'}><span><br className="br" />ご利用<br className="br" />ガイド</span></Link>
					<Link className="el_linkIcon el_linkIcon__contactL" to={props.basePath + '/contact'}><span><br className="br" />お問い<br className="br" />合わせ</span></Link>
				</div>
			</div>
			<ul className="ly_header_inner ly_header_inner__menu">
				<li className={`bl_pullDown bl_pullDown__shop ${open == 'shop' ? 'is_open' : ''}`}>
					<span className="bl_pullDown_selected" onClick={handleClickMenu('shop')}>{getShopName(state.params?.shopId)}</span>
					<ul className="bl_pullDown_list">
						{
							selectShops.map(cap =>
								<li className="bl_pullDown_item" key={cap.entityId}>
									<a href="#" onClick={handleClickShop(cap.entityId)}>{getShopName(cap.entityId)}</a>
								</li>
							)
						}
					</ul>
				</li>
				<li className={`bl_pullDown bl_pullDown__user ${open == 'user' ? 'is_open' : ''}`}>
					<span className="bl_pullDown_selected" onClick={handleClickMenu('user')}>{state.user?.name}</span>
					<ul className="bl_pullDown_list">
						<li className="bl_pullDown_item"><a href="#" onClick={handleClickManagement}>ユーザー管理</a></li>
						<li className="bl_pullDown_item"><a href="#" onClick={handleClickLogout}>ログアウト</a></li>
					</ul>
				</li>
			</ul>
		</header>
	);
}
export default Header;