import { Campaign, RewardTarget } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { uri } from '../../../lib';

interface ExclusionOrderFormProps  {
	campaigns: Campaign[];
	targets: RewardTarget[];
	onChangeCampaign: (campaignId: string) => void;
	onChangeCanSendMail: (target: RewardTarget, canSendMail: boolean) => void;
	onFilterTarget: (orderId: string) => void;
}

export function ExclusionOrderForm(props: ExclusionOrderFormProps): React.ReactElement {
	const { register, getValues, setValue, watch } = useForm({
		defaultValues: {
			'select_campaign': '',
			'order_id': '',
		},
	});
	const [searchForm, setSearchFrom] = React.useState('');

	const handleClickChangeCampaign = () => {
		const campaignId = getValues('select_campaign');
		if (!campaignId) {
			return;
		}
		props.onChangeCampaign(campaignId);
		setValue('order_id', '');
	};

	const handleClickSearchForm = () => {
		setSearchFrom(searchForm ? '' : 'is_active');
	};

	const handleClickSearchSubmit = () => {
		setSearchFrom('');
		const orderId = getValues('order_id');
		if (!orderId) {
			// 選択中のキャンペーンで再取得
			const campaignId = getValues('select_campaign');
			props.onChangeCampaign(campaignId);
		} else {
			props.onFilterTarget(orderId);
		}
	};

	const handleChangeCanSendMail = (target: RewardTarget) => (ev: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeCanSendMail(target, ev.currentTarget.checked);
	};

	const toRMSOrderDetailURL = (orderNumber: string) => uri`https://order-rp.rms.rakuten.co.jp/order-rb/individual-order-detail-sc/init?orderNumber=${orderNumber}`;

	const selectCampaign = watch('select_campaign');

	return (
		<div className="bl_col bl_col__4">
			<div className="bl_panel bl_panel__ttlInv">
				<h2 className="el_lv2Heading">注文番号から設定</h2>
				<div className="bl_panel_row mb_24">
					<h3 className="el_lv3Heading">実施中のキャンペーンから選択</h3>
					<div className="el_selectWrap mb_8">
						{props.campaigns && <>
							<select name="select_campaign" ref={register}>
								<option value="" disabled hidden>キャンペーンを選択してください</option>
								{props.campaigns.map((campaign) => 
									<option key={campaign.id} value={campaign.id}>{campaign.name}</option>
								)}
							</select>
						</>}
					</div>
					<button className="el_btnInv" type="button" onClick={handleClickChangeCampaign} disabled={selectCampaign === ''}>表示</button>
				</div>
					<table className="bl_table bl_table__headBorderBottom">
						<thead className="bl_table_head">
							<tr>
								<th className={`bl_table_filterHead ` + searchForm}>
									<span className="bl_table_filterHeadTtl" onClick={handleClickSearchForm}>注文番号</span>
									<div className="bl_tableSearchForm">
										<p className="bl_tableSearchForm_ttl">検索</p>
										<div className="el_searchInputWrap">
											<input type="text" name="order_id" ref={register} />
											<button className="el_searchBtn" type="button" onClick={handleClickSearchSubmit} />
										</div>
									</div>
								</th>
								<th>メール送信</th>
							</tr>
						</thead>
						{props.targets && <>
							<tbody className="bl_table_body">
								{props.targets.map((target) => 
									<tr key={target.id}>
										<td>
											<ul>
												{target.orders.map(order =>
													<li key={order.number}><a href={toRMSOrderDetailURL(order.number)} target="_blank" rel="noreferrer">{order.number}</a></li>
												)}
											</ul>
										</td>
										<td>
											<label className="el_toggleBtn">
												<input type="checkbox" checked={target.orders.some(order => order.canSendingMail)}  onChange={handleChangeCanSendMail(target)} />
												<span />
											</label>
										</td>
									</tr>
								)}
							</tbody>
							</>}
					</table>
				<p className="el_resultCount">全{props.targets.length}件</p>
			</div>
		</div>
	);
}
export default ExclusionOrderForm;