import { toJapaneseDate } from '@sasagase/to-japanese-date';
import { DestType, findKamon, RewardRequestDetail } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { getRewardRequestDetail } from '../../../api';
import { useAPILoad } from '../../../context';
import Modal from '../Modal';

const DEST_NAME: Record<DestType, string> = {
	'shipping': '注文時の配送先',
	'orderer': '注文者の住所',
	'other': '個別指定',
} as const;

interface RequestDetailProps {
	requestId: string;
	onClose: () => void;
}
type RequestDetailParams ={
	shopId: string;
}

export function RequestDetail(props: RequestDetailProps): React.ReactElement {
	const params = useParams<RequestDetailParams>();
	const [detail] = useAPILoad(getRewardRequestDetail(params.shopId ?? '', props.requestId), {
		onFetch: (data) => {
			return RewardRequestDetail.create(data);
		},
	});

	const handleClickClose = () => {
		props.onClose();
	};

	const toDateString = (date?: number) => {
		if (!date) {
			return '';
		}
		return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
	};
	const toKifudaKamonName = (kamonId: string) => {
		const kamon = findKamon(kamonId);
		if (!kamon) {
			return `不明(${kamonId})`;
		}
		return `${kamon.id} : ${kamon.name}`;
	};

	const opt = detail?.rewardOptionValues;

	return (
		<Modal onClickBackground={handleClickClose}>
			<div className="bl_modal_header">
				<h1 className="bl_modal_ttl">注文の詳細</h1>
				<button className="el_closeBtn" onClick={handleClickClose}></button>
			</div>
			{detail &&
				<table className="bl_modal_table">
					<tbody>
						<tr>
							<th>注文番号</th>
							<td>{detail.orderNumber}</td>
						</tr>
						<tr>
							<th>注文者</th>
							<td>{detail.ordererName}</td>
						</tr>
						<tr>
							<th>キャンペーン</th>
							<td>{detail.campaignName}</td>
						</tr>
						<tr>
							<th>希望の特典</th>
							<td>{detail.rewardName}</td>
						</tr>
						<tr>
							<th>配送先</th>
							<td>{DEST_NAME[detail.destType] || detail.destType}</td>
						</tr>
						{detail.destType == 'other' &&
							<>
								<tr>
									<th>配送先郵便番号</th>
									<td>{detail.destZip}</td>
								</tr>
								<tr>
									<th>配送先住所</th>
									<td>{detail.destAddress}</td>
								</tr>
								<tr>
									<th>配送先建物名等</th>
									<td>{detail.destBuilding}</td>
								</tr>
								<tr>
									<th>配送先氏名</th>
									<td>{detail.destName}</td>
								</tr>
								<tr>
									<th>配送先電話番号</th>
									<td>{detail.destPhone}</td>
								</tr>
							</>
						}
						<tr>
							<th>SKU</th>
							<td>{detail.sku}</td>
						</tr>
						<tr>
							<th>RAC 番号</th>
							<td>{detail.rac}</td>
						</tr>
						<tr>
							<th>数量インデックス番号</th>
							<td>{detail.quantityIdx}</td>
						</tr>
						<tr>
							<th>発送日</th>
							<td>{toDateString(detail.shippingDate)}</td>
						</tr>
						<tr>
							<th>ショップレビュー確認時間</th>
							<td>{toDateString(detail.shopReviewDate)}</td>
						</tr>
						<tr>
							<th>商品レビュー確認時間</th>
							<td>{toDateString(detail.itemReviewDate)}</td>
						</tr>
						<tr>
							<th>フォローメール送信時間</th>
							<td>{toDateString(detail.followMailDate)}</td>
						</tr>
						<tr>
							<th>リクエストメール送信時間</th>
							<td>{toDateString(detail.requestMailDate)}</td>
						</tr>
						<tr>
							<th>受付メール送信時間</th>
							<td>{toDateString(detail.receivedMailDate)}</td>
						</tr>
						<tr>
							<th>処理日</th>
							<td>{toDateString(detail.transactDate)}</td>
						</tr>
						{opt?.kifudaKamon != null &&
							<tr>
								<th>木札 絵柄</th>
								<td>{typeof opt.kifudaKamon == 'string' ? toKifudaKamonName(opt.kifudaKamon) : String(opt.kifudaKamon)}</td>
							</tr>
						}
						{opt?.kifudaName != null &&
							<tr>
								<th>木札 名前</th>
								<td>{String(opt.kifudaName)}</td>
							</tr>
						}
						{opt?.kifudaFurigana != null &&
							<tr>
								<th>木札 ふりがな</th>
								<td>{opt.kifudaFurigana === false ? '[フリガナなし]' : String(opt.kifudaFurigana)}</td>
							</tr>
						}
						{opt?.kifudaBirthDay != null &&
							<tr>
								<th>木札 生年月日</th>
								<td>{typeof opt.kifudaBirthDay == 'string' ? toJapaneseDate(opt?.kifudaBirthDay) : String(opt.kifudaBirthDay)}</td>
							</tr>
						}
						{opt?.namaebataName != null &&
							<tr>
								<th>名前旗 名前</th>
								<td>{String(opt.namaebataName)}</td>
							</tr>
						}
						{opt?.namaebataBirthDay != null &&
							<tr>
								<th>名前旗 生年月日</th>
								<td>{typeof opt.namaebataBirthDay == 'string' ? toJapaneseDate(opt?.namaebataBirthDay) : String(opt.namaebataBirthDay)}</td>
							</tr>
						}
						{opt?.konoriName != null &&
							<tr>
								<th>konori名入れ 名前</th>
								<td>{String(opt.konoriName)}</td>
							</tr>
						}
						{opt?.namaemagnetIllust != null &&
							<tr>
								<th>お名前マグネット パーツの形・イラスト</th>
								<td>{String(opt.namaemagnetIllust)}</td>
							</tr>
						}
						{opt?.namaemagnetName != null &&
							<tr>
								<th>お名前マグネット 名前</th>
								<td>{String(opt.namaemagnetName)}</td>
							</tr>
						}
					</tbody>
				</table>
			}
		</Modal>
	);
}
export default RequestDetail;
