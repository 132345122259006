import { bodyElementType, bodyElementTypeNames, Campaign, MailContent } from '@sasagase/types';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import { getReviewMail, sendReviewMail } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import FormErrorMessage from '../FormErrorMessage';
import OriginalTagModal from './OriginalTagModal';
import Preview from './Preview';

export interface MailEditRowFormValues {
	bodyHeader: string;
	bodyElement: {
		type: string;
		content: string;
	}[];
	signature: string;
	email: string;
}
const nameList: ReadonlyArray<keyof MailEditRowFormValues> = ['bodyHeader', 'bodyElement', 'signature', 'email'] as const;

const defaultToName = (name: string) => name;

const bodyElementInitValues = {
	type: 'free_text',
	content: '',
};
interface MailEditRowProps {
	methods: UseFormMethods<any>;
	toName?: (name: string) => string;
	applyValues?: Partial<MailEditRowFormValues>;
	disabled?: boolean;
	campaign?: Campaign;
}

export function MailEditRow(props: MailEditRowProps): React.ReactElement {
	const [state] = useAppState();
	const enableBodyElementType = bodyElementType.filter(type => !['campaign_url'].includes(type));
	const bodyElementTypeSelect = enableBodyElementType;
	const toName = props.toName || defaultToName;
	const names: Record<typeof nameList[number], string> = React.useMemo(() => {
		const ret: Record<string, string> = {};
		for (const name of nameList) {
			ret[name] = toName(name);
		}
		return ret;
	}, [toName]);

	const { register, getValues, control, watch, setValue, trigger, errors } = props.methods;
	const { fields, append, move, remove } = useFieldArray({
		control,
		name: names.bodyElement,
	});
	const callAPI = useAPI();
	const [previewValues, setPreviewValues] = React.useState<MailContent | null>(null);
	const [enableTagModal, setEnableTagModal] = React.useState<boolean>(false);
	const [sendComplete, setSendComplete] = React.useState<boolean>(false);
	const [isClick, setIsClick] = React.useState(false);

	React.useEffect(() => {
		if (!props.applyValues) {
			return;
		}

		setValue(names.bodyHeader, props.applyValues.bodyHeader);
		// useFieldArray内のformの値に直接setValueすると以降でappendした値と並び替えができない
		remove();
		if (props.applyValues.bodyElement) {
			for (const v of props.applyValues.bodyElement) {
				append(v);
			}
		}
		setValue(names.signature, props.applyValues.signature);
		// メール本文のバリデーション実行
		void trigger(names.bodyElement);
	}, [props.applyValues]);

	const handleClickAddBodyElement = () => {
		if (props.disabled) {
			return;
		}
		append(bodyElementInitValues);
		// メール本文のバリデーション実行
		void trigger(names.bodyElement);
	};

	const handleClickDeleteBodyElement = (idx: number) => {
		remove(idx);
		// メール本文のバリデーション実行
		void trigger(names.bodyElement);
	};

	const handleOnDragEnd = (result: any) => {
		if (result.destination) {
			if (result.source.index !== result.destination.index) {
				move(result.source.index, result.destination.index);
			}
		}
	};

	const getFormValue = () => {
		let bodyElementValue = [];
		let subjectValue = undefined;
		if (names.bodyElement.indexOf('.') != -1) {
			const [parent, child] = names.bodyElement.split('.');
			const childValues = getValues()[parent];
			bodyElementValue = childValues[child];
			subjectValue = childValues.subject;
		} else {
			bodyElementValue = getValues()[names.bodyElement];
		}
		return {
			subject: subjectValue,	// キャンペーン登録・編集時のフォームのみ参照可能
			bodyHeader: getValues(names.bodyHeader),
			bodyElement: (bodyElementValue || []),
			signature: getValues(names.signature),
			to: getValues(names.email),
			campaign: props.campaign
		};
	};

	const handleClickOpenPreview = async () => {
		const formValues = getFormValue();
		const res = await callAPI(getReviewMail(state.params.shopId, 'preview', formValues));
		const mailContent = new MailContent(res.data);
		setPreviewValues(mailContent);
	};

	const handleClickClosePreview = () => {
		setPreviewValues(null);
	};

	const handleClickSwitchTagModal = () => {
		setEnableTagModal(!enableTagModal);
	};

	const handleChangeType = (ev: React.ChangeEvent<HTMLSelectElement>) => {
		const contentKey = ev.target.name.replace('type', 'content');
		if (ev.target.value !== 'free_text') {
			setValue(contentKey, '');
		}
		// メール本文のバリデーション実行
		void trigger(names.bodyElement);
	}

	const handleClickSendTest = async () => {
		if (props.disabled) {
			return;
		}
		const formValues = getFormValue();
		const to = formValues.to;
		const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
		if (reg.test(to)) {
			setIsClick(true);

			const res = await callAPI(getReviewMail(state.params.shopId, 'testMail', formValues));
			const mailContent = MailContent.create({
				...res.data
			});
			callAPI(sendReviewMail(state.params.shopId, mailContent), (err, result) => {
				if (err) {
					return;
				}
				if (result.data) {
					setSendComplete(true);
				}
			});
		} else {
			alert(`メールアドレスを入力してください`);
		}
	};

	const toSendTestButtonString = (flg: boolean) => {
		return flg ? '送信完了' : 'テスト送信';
	};

	const toBodyElements = fields.length ? watch(names.bodyElement, []) : [];

	return (
		<>
			{previewValues && <Preview values={previewValues} onClose={handleClickClosePreview} />}
			{enableTagModal && <OriginalTagModal onClose={handleClickSwitchTagModal} />}
			<div className="bl_panel_row bl_panel_row__indent">
				<h3 className="el_lv3Heading">メール本文</h3>
				<div className='mb_8 fs_12 txt_blue'>
					フリーテキスト内には独自タグを入力できます。
					<button className="el_btnBlueInv" type="button" onClick={handleClickSwitchTagModal} disabled={enableTagModal}>独自タグ一覧</button>
				</div>
				<input type="text" name={names.bodyHeader} ref={register} disabled={props.disabled} />
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId={`element-${names.bodyElement}`}>
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{fields.map((bodyElement, idxBody) => {
									return (
										<Draggable key={bodyElement.id} draggableId={`element-${bodyElement.id}`} index={idxBody} isDragDisabled={props.disabled}>
											{(provided) => (
												<div className="bl_sortableField" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<div className="bl_sortableField_knob"></div>
													<div className="bl_sortableField_txtWrap">
														<div className="bl_sortableField_txtWrap_header">
															<div className="el_selectWrap">
																<select name={`${names.bodyElement}[${idxBody}].type`} ref={register()} defaultValue={bodyElement.type} disabled={props.disabled} onChange={handleChangeType}>
																	{bodyElementTypeSelect.map((elementType) => {
																		return (
																			bodyElementTypeNames[elementType] && <option key={elementType} value={elementType}>{bodyElementTypeNames[elementType]}</option>
																		);
																	})}
																</select>
															</div>
															<button className="el_btnBlueInv" onClick={() => handleClickDeleteBodyElement(idxBody)} disabled={props.disabled}>削除</button>
														</div>
														<textarea name={`${names.bodyElement}[${idxBody}].content`} 
															ref={register()} 
															defaultValue={bodyElement.content} 
															style={{ display: (toBodyElements[idxBody] || bodyElement).type == 'free_text' ? '' : 'none' }}
															disabled={props.disabled}
															/>
													</div>
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<div className="bl_addSentence" onClick={handleClickAddBodyElement}>
					<p>文章を追加</p>
				</div>
				<FormErrorMessage errors={errors} name={names.bodyElement} />
			</div>
			<div className="bl_panel_row bl_panel_row__indent">
				<h3 className="el_lv3Heading">署名</h3>
				<textarea className="el_textarea" name={names.signature} rows={3} placeholder="ぷりふあ人形&#010;〒700-0976 岡山県岡山市北区辰巳29-113&#010;(TEL) 086-250-0092　 (e-mail) rakuten@prefer.jp" ref={register} disabled={props.disabled} />
			</div>
			<div className="bl_panel_row mb_16">
				<button className="el_btnBlueInv" type="button" onClick={handleClickOpenPreview} disabled={props.disabled}>プレビューを表示</button>
			</div>
			<div className="bl_panel_row bl_mailTemp_test">
				<button className="el_btnInv" type="button" onClick={handleClickSendTest} disabled={isClick || props.disabled}>{toSendTestButtonString(sendComplete)}</button>
				<input type="email" name={names.email} ref={register} disabled={props.disabled}/>
			</div>
		</>
	);
}
export default MailEditRow;