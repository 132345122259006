import { AddReviewsResult, Campaign, ItemGroup, MailNotCovered, MailNotCoveredEnabledOption, MailTemplate, PageParams, Review, Reward, TransactRewardRequestResult } from '@sasagase/types';
import { APIRequest } from '.';
import { uri } from '../lib';

export function getRewards(shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/reward`,
	};
}
export function getReward(shopId: string, rewardId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/reward/${rewardId}`,
	};
}
export function putReward(shopId: string, rewardId: string, reward: Reward, file?: File): APIRequest {
	const data = new FormData();
	data.append('reward', JSON.stringify(reward));
	if (file) { data.append('image', file, file.name); }
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/reward/${rewardId}`,
		data,
	};
}
export function postReward(shopId: string, reward: Reward, file?: File): APIRequest {
	const data = new FormData();
	data.append('reward', JSON.stringify(reward));
	if (file) { data.append('image', file, file.name); }
	return {
		method: 'post',
		url: uri`/api/review/${shopId}/reward`,
		data,
	};
}
export function deleteRewards(shopId: string, rewardId: string): APIRequest {
	return {
		method: 'delete',
		url: uri`/api/review/${shopId}/reward/${rewardId}`,
	};
}
export function getRewardTemplateImage(shopId: string, template: string, discountType: string, discountFactor: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/reward/image/template/${template}`,
		params: {
			discountType,
			discountFactor
		},
		responseType: 'blob',
	};
}
/** @deprecated 未使用 */
export function getCoupon(shopId: string, rewardId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/coupon/${rewardId}`,
	};
}

export function getGroups(shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/group`,
	};
}
export function getGroup(shopId: string, groupId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/group/${groupId}`,
	};
}
export function postGroup(shopId: string, group: ItemGroup): APIRequest {
	return {
		method: 'post',
		url: uri`/api/review/${shopId}/group`,
		data: group,
	};
}

export function putGroup(shopId: string, groupId: string, group: ItemGroup): APIRequest {
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/group/${groupId}`,
		data: group,
	};
}
export function deleteGroup(shopId: string, groupId: string): APIRequest {
	return {
		method: 'delete',
		url: uri`/api/review/${shopId}/group/${groupId}`,
	};
}

export function getShopEntity(shopId: string): APIRequest {
	return {
		method: 'get',
		url: `/api/review/${encodeURIComponent(shopId)}/shop_entity`,
	};
}

export function putShopEntity(shopId: string, shopEntity: Record<string, unknown>): APIRequest {
	return {
		method: 'put',
		url: `/api/review/${encodeURIComponent(shopId)}/shop_entity`,
		data: shopEntity,
	};
}

export function getTemplates(shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/template`,
	};
}
export function getTemplate(shopId: string, templateId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/template/${templateId}`,
	};
}
export function getTemplateFilter(shopId: string, templateCategory: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/templateCategory/${templateCategory}`,
	};
}
export function postTemplate(shopId: string, template: MailTemplate): APIRequest {
	return {
		method: 'post',
		url: uri`/api/review/${shopId}/template`,
		data: template,
	};
}
export function putTemplate(shopId: string, templateId: string, template: MailTemplate): APIRequest {
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/template/${templateId}`,
		data: template,
	};
}
export function putTemplateFavorite(shopId: string, templateId: string, isFavorite: boolean): APIRequest {
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/template/${templateId}/favorite`,
		data: { isFavorite: isFavorite },
	};
}
export function deleteTemplate(shopId: string, templateId: string): APIRequest {
	return {
		method: 'delete',
		url: uri`/api/review/${shopId}/template/${templateId}`,
	};
}

export function postCampaign(shopId: string, campaign: Campaign): APIRequest {
	return {
		method: 'post',
		url: uri`/api/review/${shopId}/campaign`,
		data: campaign,
	};
}
export function getCampaign(shopId: string, campaignId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/campaign/${campaignId}`,
	};
}
export function getCampaignOrderNum(shopId: string, campaignIds: string[]): APIRequest {
	const params = new URLSearchParams();
	campaignIds.map((id) => {
		params.append('campaignIds', id);
	});
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/campaign/orderNum?` + params.toString(),
	};
}
export function getCampaigns(shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/campaign`,
	};
}
export function getCampaignsByShopReward(shopId: string, rewardId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/reward/${rewardId}/campaign/`,
	};
}
export function putCampaign(shopId: string, campaignId: string, campaign: Campaign): APIRequest {
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/campaign/${campaignId}`,
		data: campaign,
	};
}
export function setCampaignPriority(shopId: string, campaignId: string, priority: number): APIRequest {
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/campaign/${campaignId}/priority`,
		data: { priority },
	};
}
export function deleteCampaign(shopId: string, campaignId: string): APIRequest {
	return {
		method: 'delete',
		url: uri`/api/review/${shopId}/campaign/${campaignId}`,
	};
}

export function getNotCovereds(shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/notCovered`,
	};
}
export function getNotCovered(shopId: string, notCoveredId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/notCovered/${notCoveredId}`,
	};
}
export function postNotCovered(shopId: string, notCovered: MailNotCovered): APIRequest {
	return {
		method: 'post',
		url: uri`/api/review/${shopId}/notCovered`,
		data: notCovered,
	};
}
export function putNotCovered(shopId: string, notCoveredId: string, notCovered: MailNotCovered): APIRequest {
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/notCovered/${notCoveredId}`,
		data: notCovered,
	};
}
export function putNotCoveredEnable(shopId: string, notCoveredId: string, opt: MailNotCoveredEnabledOption): APIRequest {
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/notCovered/${notCoveredId}/enable`,
		data: opt,
	};
}
export function deleteNotCovered(shopId: string, notCoveredId: string): APIRequest {
	return {
		method: 'delete',
		url: uri`/api/review/${shopId}/notCovered/${notCoveredId}`,
	};
}

export function getTargetsPagination(shopId: string, cond?: Record<string, string|undefined>, pageParams?: PageParams): APIRequest {
	cond = Object.fromEntries(Object.entries(cond || {}).filter(([,v]) => v !== undefined));
	const params = new URLSearchParams(cond as Record<string, string>);
	params.append('pagination', "1");	// true
	if (pageParams?.page) {
		params.append('page', String(pageParams?.page));
	}
	if (pageParams?.perPage) {
		params.append('perPage', String(pageParams?.perPage));
	}
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/target?` + params.toString(),
	};
}

export function getTargets(shopId: string, cond?: Record<string, string>): APIRequest {
	const params = new URLSearchParams(cond || {});
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/target?` + params.toString(),
	};
}

export function getTargetSummary(shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/target/summary`,
	};
}

export function getTargetTopSummary(shopId: string, campaignIds: string[]): APIRequest {
	const params = new URLSearchParams();
	campaignIds.forEach(id => params.append('campaignIds', id));
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/target/topSummary?` + params.toString(),
	};
}

export function getTarget(shopId: string, targetId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/target/${targetId}`,
	};
}

export function putOrderCanSendingMail(shopId: string, orderNumber: string, canSendingMail: boolean): APIRequest {
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/order/${orderNumber}/canSendingMail`,
		data: { canSendingMail },
	};
}

export function getOrdersByTerm(shopId: string, start: number, end?: number): APIRequest {
	const params = new URLSearchParams();
	params.append('start', String(start));
	if (end) {
		params.append('end', String(end));
	}
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/order?` + params.toString(),
	};
}

export function getReviewsByTerm(shopId: string, start: number, end?: number): APIRequest {
	const params = new URLSearchParams();
	params.append('start', String(start));
	if (end) {
		params.append('end', String(end));
	}
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/review?` + params.toString(),
	};
}

export function getReviewsByCampaigns(shopId: string, campaignIds: string[]): APIRequest {
	const params = new URLSearchParams();
	campaignIds.map((id) => {
		params.append('campaignIds', id);
	});
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/review?` + params.toString(),
	};
}

export function postReviews(shopId: string, reviews: Review[]): APIRequest<AddReviewsResult> {
	return {
		method: 'post',
		url: uri`/api/review/${shopId}/review`,
		data: reviews,
	};
}

export function getRewardRequests(shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/request`,
	};
}

export function getRewardRequest(shopId: string, requestId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/request/${requestId}`,
	};
}

export function getRewardRequestDetail(shopId: string, requestId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/review/${shopId}/request/${requestId}/detail`,
	};
}

export function putRewardRequestTransact(shopId: string, requestIds: string[], csvFormat?: string, isFormatGroup?: string): APIRequest<TransactRewardRequestResult> {
	return {
		method: 'put',
		url: uri`/api/review/${shopId}/request/transact`,
		params: {
			csvFormat,
			isFormatGroup,
		},
		data: requestIds,
	};
}

export function sendRakutenTest(shopId: string, mail: string): APIRequest {
	return {
		method: 'post',
		url: uri`/api/review/${shopId}/mail/rakuten`,
		data: {
			mail
		}
	};
}