import * as React from 'react';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../FormErrorMessage';
import LinkGuide from '../LinkGuide';

interface RakutenSettingSmtpTestMailProp {
	enable: boolean,
	onClick: (mail: string) => Promise<string>,
}

export function RakutenSettingSmtpTestMail(props: RakutenSettingSmtpTestMailProp): React.ReactElement {
	const { register, handleSubmit, errors } = useForm({
		defaultValues: { mail: '' },
	});
	const [info, setInfo] = React.useState('');
	const [buttonEnable, setButtonEnable] = React.useState(true);

	React.useEffect(() => {
		setInfo('');
	}, [props.enable]);

	const handleClick = handleSubmit(async (values) => {
		const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}shop\.rakuten\.co\.jp$/;
		if (reg.test(values.mail)) {
			alert('楽天ドメイン(shop.rakuten.co.jp)のメールアドレス(マスクアドレス)は利用できません。\n楽天RMS上の店舗・企業詳細情報に表示されている「店舗連絡先メールアドレス」を入力してください');
			return;
		}

		const res = props.onClick(values.mail);
		setButtonEnable(false);
		const result = await res;
		setInfo(result);
		setButtonEnable(true);
	});

	return (
		<>
			<h3 className="el_lv2Heading">テストメールを送信する<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_6' className='el_linkIcon__guideF' /></h3>
			<p className="el_rakutenSettingDesc">保存された楽天あんしんメルアドサーバ情報を使用して、下記の送信先メールアドレス宛にテストメールを送信します。</p>
			<p className="el_rakutenSettingNote">{info}</p>
			<div className="bl_panel_row">
				<h3 className="el_lv3Heading">
					送信先メールアドレス
					<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_6' className='el_linkIcon__guideF' />
				</h3>
				<input type="text" name="mail"
					ref={register({
						required: '送信先メールアドレスを入力してください',
						pattern: {
							value: /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
							message: '送信先メールアドレスを入力してください'
						}
					})} />
				<FormErrorMessage errors={errors} name="mail" />
			</div>
			<button className="el_largeBtn" onClick={handleClick} disabled={!props.enable || !buttonEnable} >テスト送信</button>
		</>
	);
}
export default RakutenSettingSmtpTestMail;