import { MailNotCovered, MailNotCoveredEnabledOption } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { deleteNotCovered, getNotCovereds, putNotCoveredEnable } from '../../../api';
import { useAPI, useAppState } from '../../../context';

interface NotCoveredListProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function NotCoveredList(props: NotCoveredListProps): React.ReactElement {
	const [state] = useAppState();
	const callAPI = useAPI();
	const [notCovereds, setNotCovereds] = React.useState<null|MailNotCovered[]>(null);
	const { register, setValue, getValues } = useForm();
	const checkAll = React.useRef<null|HTMLInputElement>(null);

	React.useEffect(() => {
		if (!state.params.shopId || notCovereds) {
			return;
		}
		return callAPI(getNotCovereds(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const notCovereds = result.data.map((obj: Record<string, unknown>) => new MailNotCovered(obj));
			setNotCovereds(notCovereds);
		});
	}, [state.params.shopId, notCovereds]);

	const handleChangeCheckAll = () => {
		if (!checkAll.current || !notCovereds) {
			return;
		}
		setValue('check', Array(notCovereds.length).fill(checkAll.current.checked));
	};
	const handleChangeCheck = () => {
		if (!checkAll.current) {
			return;
		}
		// React hook formの仕様上はできるはずだが、なぜか getValues('check') だと取得できない
		const checks = getValues().check;
		const isAll = Object.values(checks).every(Boolean);
		const isPartial = !isAll && Object.values(checks).some(Boolean);
		checkAll.current.checked = isAll;
		checkAll.current.indeterminate = isPartial;
	};

	const handleChangeEnable = (id: string, type: keyof MailNotCoveredEnabledOption) => async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!notCovereds) {
			return;
		}

		const notConvered = notCovereds.find((val: MailNotCovered) => val.id === id);
		if (notConvered) {
			await callAPI(putNotCoveredEnable(state.params.shopId, id, { [type]: e.currentTarget.checked }));
		}
		setNotCovereds(null);
	}

	const handleClickDelete = async () => {
		if (!notCovereds) {
			return;
		}
		const checks: boolean[] = getValues().check || [];
		const names: string[] = Array.from(checks.entries()).filter(([, check]) => check).map(([idx]) => `・${notCovereds[idx].name}`);

		if (names.length <= 0) {
			alert(`削除する特典対象外商品用メールを選択してください。`);
			return;
		}

		if (window.confirm(`以下の特典対象外商品用メールを削除してよろしいですか？\n\n${names.join(`\n`)}`)) {
			for (const [idx, check] of checks.entries()) {
				if (!check) {
					continue;
				}
				const id = notCovereds[idx].id;
				const res = await callAPI(deleteNotCovered(state.params.shopId, id));
				if (!res.data) {
					alert(`特典対象外商品用メール ${notCovereds[idx].name} の削除に失敗しました。この特典対象外商品用メールは使用中です。`);
				}
			}
		}
		setNotCovereds(null);
	};

	const toEditURL = (notCoveredId: string) => {
		return state.params.basePath + '/mail/not-covered/' + notCoveredId;
	};
	const toCopyURL = (notCoveredId: string) => {
		return state.params.basePath + '/mail/not-covered/' + notCoveredId + '/copy';
	};

	return (
		<>
			<div>
				<h1 className="el_pageTtl">特典対象外商品用メール</h1>
				<p className="el_pageDesc">特典対象外の商品を購入したお客様に送信するメールの新規作成、確認・編集ができます。</p>
				<div className="bl_row">
					<div className="bl_col bl_col__12">
						<div className="bl_panel">
							<div className="bl_panel_headerFooter">
								<Link className="el_btn el_btn__plus" to={`${state.params.basePath}/mail/not-covered/new`}>新規追加</Link>
								<button className="el_btnBlueInv" type="button" onClick={handleClickDelete}>削除</button>
							</div>
							{notCovereds && <>
								<table className="bl_table bl_table__notCovered">
									<thead className="bl_table_head">
										<tr>
											<th><input className="el_checkMark" type="checkbox" name='checkAll' ref={checkAll} onChange={handleChangeCheckAll} /></th>
											<th>テンプレート名</th>
											<th>メール件名</th>
											<th>楽天会員に送信</th>
											<th>非楽天会員に送信</th>
											<th></th>
										</tr>
									</thead>
									<tbody className="bl_table_body">
										{notCovereds.map((notCovered, idx) => 
											<tr key={notCovered.id}>
												<td><input className="el_checkMark" type="checkbox" name={`check[${idx}]`} ref={register} onChange={handleChangeCheck} /></td>
												<td>{notCovered.name}</td>
												<td>{notCovered.subject}</td>
												<td>
													<label className="el_toggleBtn el_toggleBtn__send">
														<input type="checkbox" name="isEnable" ref={register} defaultChecked={notCovered.isEnable} onChange={handleChangeEnable(notCovered.id, 'isEnable')} />
														<span />
													</label>
												</td>
												<td>
													<label className="el_toggleBtn el_toggleBtn__send">
														<input type="checkbox" name="isNonRakutenMemberEnabled" ref={register} defaultChecked={notCovered.isNonRakutenMemberEnabled} onChange={handleChangeEnable(notCovered.id, 'isNonRakutenMemberEnabled')} />
														<span />
													</label>
												</td>
												<td>
													<div className="bl_table_btnWrap">
														<Link className="el_btnInv" to={toEditURL(notCovered.id)}>編集</Link>
														<Link className="el_btnInv" to={toCopyURL(notCovered.id)}>複製</Link>
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
								<p className="el_resultCount">全{notCovereds.length}件</p>
							</>}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default NotCoveredList;