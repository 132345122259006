import * as chartjs from "chart.js";
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';

interface Tooltips {
	classList: string[];
	label: string;
	value: string;
	index?: number;
}

interface ReviewDoughnutProps {
	title?: string;
	default?: Tooltips;
	labels: string[];
	data: number[];
}

export function ReviewDoughnut(props: ReviewDoughnutProps): React.ReactElement {
	const [tooltips, setTooltips] = React.useState<Tooltips|null>(props.default ? props.default : null);

	const handleHover = (event: MouseEvent, elements: any[]) => {
		if (elements.length === 0) {
			setTooltips(null);
			return;
		}

		// 現在ホバーしているデータ
		const element = elements[0];
		const view = element._view;
		const model = element._model;

		// 弧の位置
		const x = view.x;
		const y = view.y;
		const innerRadius = view.innerRadius;
		const arcRadius   = innerRadius * 0.9;

		// 描画に使用するデータのインデックス
		const index: number = element._index;

		// 描画に使用する色
		const bgc = model.backgroundColor;

		// ホバー時の弧の角度
		const startAngle = model.startAngle;
		const endAngle   = model.endAngle;

		// ツールチップのテキストをセット
		const datasets    = element._chart.config.data.datasets[0].data;
		const dataset     = datasets[index];
		const dataValue   = (typeof dataset === 'number') ? dataset : 1;
		const percentage  = dataValue ? ((dataValue / 100) * 100).toFixed(1) : 0;

		const tp = {
			classList: [
				'el_doughnutTooltip__topPage',
				'el_doughnutTooltip__topPage' + (index + 1),
			],
			label: props.default ? view.label : '',
			value: percentage + '%',
			index: index,
		}
		if (!tooltips || (tooltips.index !== tp.index)) {
			setTooltips(tp);
		}

		// 弧をクリア
		const ctx = element._chart.ctx;
		ctx.lineWidth = 8;
		ctx.strokeStyle = '#FFF';
		ctx.beginPath();
		ctx.arc(x, y, arcRadius, startAngle, endAngle);
		ctx.stroke();

		// 弧を描画
		ctx.lineWidth = arcRadius * 0.05;
		ctx.strokeStyle = bgc;
		ctx.beginPath();
		ctx.arc(x, y, arcRadius, startAngle, endAngle);
		ctx.stroke();
	};

	const chartData = {
		labels: props.labels,
		datasets: [
			{
				data: props.data,
				backgroundColor: ['#3D4854', '#47C5E6', '#EBA707', '#92E831', '#F368AE', '#8D7EC7'],
				hoverBackgroundColor: ['#3D4854', '#47C5E6', '#EBA707', '#92E831', '#F368AE', '#8D7EC7'],
				borderWidth: 0,
			},
		],
	};

	const chartBaseOptions = {
		// ドーナツグラフの幅
		cutoutPercentage: 76,
		// 凡例非表示
		legend: {
			display: false,
		},
		// アニメーション無効化
		animation: {
			duration: 0
		},
		hover: {
			animationDuration: 0,
		},
		responsiveAnimationDuration: 0,
		// デフォルトのツールチップを無効化
		tooltips: {
			enabled: false,
		},
		onHover: handleHover,
	};
	let chartTitileOptions = undefined;
	if (props.title) {
		chartTitileOptions = {
			title: {
				display: true,
				fontFamily: "'Yu Gothic', 'YuGothic', 'sans-serif'",
				fontSize: 14,
				fontColor: '#3F81CB',
				text: props.title,
			},
			layout: {
				padding: {
					top: -10,
				},
			},
		};
	}
	const chartOptions: chartjs.ChartOptions = {
		...chartBaseOptions,
		...chartTitileOptions,
	};

	return (
		<div id="topDoughnutWrap" className="bl_canvasWrap">
			<Doughnut height={208}
				data={chartData}
				options={chartOptions}
				/>
			{tooltips &&
				<div className={`el_doughnutTooltip ${tooltips.classList.join(' ')}`}>
					<p className="el_doughnutTooltip_label">{tooltips.label}</p>
					<p className="el_doughnutTooltip_value">{tooltips.value}</p>
				</div>
			}
		</div>
	);
}
export default ReviewDoughnut;