import * as React from 'react';
import { FieldError, UseFormMethods } from 'react-hook-form';
import { CampaignEditFormValues } from '.';

interface CampaignErrorListProps {
	methods: UseFormMethods<CampaignEditFormValues>;
}

interface ErrorCheck {
	step: number;
	key: string;
	title: string;
	link: string;
}

export const CampaignErrorList: React.FC<CampaignErrorListProps> = (props) => {
	const { errors, formState: { isValidating } } = props.methods;
	if (!errors || Object.keys(errors).length <= 0 || isValidating) {
		return null;
	}

	const isFieldError = (error: any): error is FieldError => {
		return error !== null &&
			typeof error === 'object' &&
			typeof error.type === 'string';
	}
	const flatErrors: Record<string, FieldError> = React.useMemo(() => {
		return Object.entries(errors).reduce((flat, [key, error]) => {
			if (isFieldError(error)) {
				Object.assign(flat, { [key]: error });
			} else {
				for (const [cKey, cError] of Object.entries(error)) {
					if (isFieldError(cError)) {
						Object.assign(flat, { [`${key}.${cKey}`]: cError });
					}
				}
			}
			return flat;
		}, {});
	}, [errors]);

	const errorCheckList: ErrorCheck[] = [
		{ step: 1, key: "name", title: "キャンペーン名", link: "キャンペーン名" },
		{ step: 1, key: "begin", title: "キャンペーン実施期間の開始日時", link: "キャンペーン実施期間" },
		{ step: 1, key: "end", title: "キャンペーン実施期間の終了日時", link: "キャンペーン実施期間" },
		{ step: 1, key: "deadlineDate", title: "レビュー受付期間（対象日時まで）", link: "レビュー受付期間" },
		{ step: 1, key: "deadlineDays", title: "レビュー受付期間（商品発送後の指定日数期間）", link: "レビュー受付期間" },
		{ step: 2, key: "rewards", title: "レビュー特典", link: "レビュー特典" },
		{ step: 2, key: "destType", title: "特典送り先の申込", link: "特典送り先の申込" },
		{ step: 2, key: "applicationClosingDays", title: "送り先申込受付期間の期限", link: "送り先申込受付期間" },
		{ step: 3, key: "itemGroup.skus", title: "対象商品設定", link: "対象商品設定" },
		{ step: 4, key: "followMail.subject", title: "フォローメールの件名" , link: "フォローメール"  },
		{ step: 4, key: "requestMail.disable", title: "レビュー完了メールの送信設定" , link: "レビュー完了メール"  },
		{ step: 4, key: "requestMail.subject", title: "レビュー完了メールの件名" , link: "レビュー完了メール"  },
		{ step: 4, key: "requestMail.bodyElement", title: "レビュー完了メールの本文" , link: "レビュー完了メール"  },
		{ step: 4, key: "receivedMail.disable", title: "申込受付完了メールの送信設定" , link: "申込受付完了メール"  },
		{ step: 4, key: "receivedMail.subject", title: "申込受付完了メールの件名" , link: "申込受付完了メール"  },
		{ step: 4, key: "receivedMail.bodyElement", title: "申込受付完了メールの本文" , link: "レビュー完了メール"  },
	];
	const stepTitles: string[] = ['', 'Step1 基本設定', 'Step2 特典設定', 'Step3 対象商品設定', 'Step4 メール設定'];

	const isError = (cond: {
		step?: number,
		key?: string
	}): boolean => {
		if (cond.step) {
			return errorCheckList.some(c => c.step === cond.step && Object.keys(flatErrors).includes(c.key));
		} else if (cond.key) {
			return Boolean(flatErrors[cond.key]);
		}
		return false;
	}

	return (
		<>
			<div className="bl_confirm_ttlWrap">
				<h3 className="bl_confirm_ttl">エラー</h3>
			</div>
			<div className="bl_confirm_row">
				{stepTitles.map((title, idx) => <React.Fragment key={idx}>
					{isError({ step: idx }) && <>
						<h4 className="el_lv3Heading">{title}</h4>
						<ul className="bl_errorTarget_list">
							{errorCheckList.filter(c => c.step === idx).map(check => <React.Fragment key={check.key}>
								{isError({ key: check.key }) &&
									<li key={check.key}><a href={`#${check.link}`}>{check.title}</a></li>
								}
							</React.Fragment>)}
						</ul>
					</>}
				</React.Fragment>)}
			</div>
		</>
	);
}
export default CampaignErrorList;
