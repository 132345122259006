import * as React from 'react';

interface SideDrawerItemProps {
	children?: React.ReactNode;
	className: string;
	title: React.ReactNode;
	isOpen?: boolean;
	onClickMenu?: () => void;
}

export function SideDrawerItem(props: SideDrawerItemProps): React.ReactElement {
	const classNames = ['bl_drawer_item'];

	if (props.isOpen) {
		classNames.push('is_open');
	}
	if (props.className) {
		classNames.push(props.className);
	}

	const className = classNames.join(' ');

	return (
		<li className={className}>
			<span className="bl_drawer_ttlWrap" onClick={props.onClickMenu} >
				<span className="bl_drawer_icon" />
				<span className="bl_drawer_ttl">{props.title}</span>
			</span>
			{props.children}
		</li>
	);
}
export default SideDrawerItem;