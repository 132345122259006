import * as React from 'react';
import { useTimer } from '../../../lib';
import Modal from '../Modal';

interface OriginalTagModalProps {
	onClose: () => void;
}

export function OriginalTagModal(props: OriginalTagModalProps): React.ReactElement {
	const setTimer = useTimer();

	const handleClickClose = () => {
		props.onClose();
	};

	const handleClickCopyClipboard = async (ev: React.MouseEvent<HTMLButtonElement>) => {
		const tips = ev.currentTarget.getElementsByClassName('el_tooltip-text_hidden');
		Array.from(tips).forEach(tip => {
			tip.setAttribute
			tip.classList.replace('el_tooltip-text_hidden', 'el_tooltip-text');

			setTimer(() => {
				tip.classList.replace('el_tooltip-text', 'el_tooltip-text_hidden');
			}, 3000);
		})
		await navigator.clipboard.writeText(String(ev.currentTarget.getAttribute('data-tag')));
	};

	return (
		<Modal onClickBackground={handleClickClose} className='bl_modal__mailPreview'>
			<div className="bl_modal_header">
				<h1 className="bl_modal_ttl">独自タグ一覧</h1>
				<button className="el_closeBtn" onClick={handleClickClose}></button>
			</div>
			<div className="bl_panel_row mt_16 mb_0">
				メールごとに対象注文の情報を自動入力します。
				<table className='bl_borderTable'>
					<tbody>
						<tr>
							<th><input type="text" name="name" value="{{orderFull}}" readOnly /></th>
							<td>
								<button className='el_btnTooltip' onClick={handleClickCopyClipboard} data-tag='{{orderFull}}'>
									コピー
									<span className='el_tooltip-text_hidden'>コピーしました</span>
								</button>
							</td>
							<td>お客様の氏名<br />例：楽天 太郎</td>
						</tr>
						<tr>
							<th><input type="text" name="name" value="{{ordernumber}}" readOnly /></th>
							<td>
								<button className='el_btnTooltip' onClick={handleClickCopyClipboard} data-tag='{{ordernumber}}'>
									コピー
									<span className='el_tooltip-text_hidden'>コピーしました</span>
								</button>
							</td>
							<td>注文番号<br />例：396635-20230101-0032042979</td>
						</tr>
						<tr>
							<th><input type="text" name="name" value="{{orderItemList}}" readOnly /></th>
							<td>
								<button className='el_btnTooltip' onClick={handleClickCopyClipboard} data-tag='{{orderItemList}}'>
									コピー
									<span className='el_tooltip-text_hidden'>コピーしました</span>
								</button>
							</td>
							<td>[購入商品]<br />商品名(商品番号)<br />----------<br />商品名(商品番号)</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Modal>
	);
}
export default OriginalTagModal;
