import { User } from '@sasagase/types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useParams } from 'react-router-dom';
import { getUserByToken, resetPassword } from '../../../api';
import { useAPI } from '../../../context';
import PasswordInput from '../../PasswordInput';
import FormErrorMessage from '../FormErrorMessage';

interface AccountPasswordResetProps {}
type AccountPasswordResetParams = {
	token?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AccountPasswordReset(props: AccountPasswordResetProps): React.ReactElement | null {
	const params = useParams<AccountPasswordResetParams>();
	const { register, handleSubmit, errors, getValues } = useForm({});
	const callAPI = useAPI();
	const [redirect, setRedirect] = React.useState<string|null>(null);
	const [user, setUser] = React.useState<User|null>(null);

	React.useEffect(() => {
		if (!params.token) {
			return;
		}
		const token = params.token;

		return callAPI(getUserByToken(token), (err, result) => {
			if (err || !result.data) {
				setRedirect('/notfound');
				return;
			}

			const user = new User(result.data);
			if (!user.hasEnableResetPasswordToken(token)) {
				setRedirect('/notfound');
				return;
			}
			setUser(user);
		});
	}, [params.token]);

	const handleClickReset = handleSubmit(async (values) => {
		if (!params.token) {
			return;
		}

		try {
			const result = await callAPI(resetPassword(params.token, values.password));
			if (result.data) {
				setRedirect(`/account/completed/reset`);
			}
		} catch (err) {
			return;
		}
	});

	if (redirect) {
		return <Navigate to={redirect} />;
	}
	if (!user) {
		return null;
	}

	return (
		<div className="ly_reissue">
			<div className="ly_reissue_inner">
				<h1 className="el_entryTitle"><Link to="/"><img src="/assets/img/logo-w.svg" alt="ラクラクあつまレビュー" /></Link></h1>
				<div className="bl_passForm">
					<div className="bl_loginFormRow">
						<p className="txt_blue">パスワードをお忘れの方は、メールアドレスをお確かめのうえ、パスワードを再設定してください。</p>
					</div>
					<div className="bl_loginFormRow">
						<label className="bl_blueLabel" htmlFor="mail">メールアドレス</label>
						<p>{user.mail}</p>
					</div>
					<div className="bl_loginFormRow">
						<label className="bl_blueLabel">新しいパスワード</label>
						<PasswordInput name="password"
							ref={register({
									required: '新しいパスワードを入力してください。',
									minLength: {
										value: 8,
										message: '新しいパスワードは8文字以上で入力してください。',
									},
								})}
							/>
						<FormErrorMessage errors={errors} name="password" />
					</div>
					<div className="bl_loginFormRow">
						<label className="bl_blueLabel">新しいパスワード（確認用）</label>
						<PasswordInput name="password_confirm"
							ref={register({
									required: '確認用の新しいパスワードを入力してください。',
									validate: input => input === getValues("password") || '入力した新しいパスワードと一致していません。',
								})}
							/>
						<FormErrorMessage errors={errors} name="password_confirm" />
					</div>
					<button className="el_largeBtn el_largeBtn__fullWidth" type="button" onClick={handleClickReset}>新しいパスワードを登録する</button>
				</div>
			</div>
		</div>
	);
}
export default AccountPasswordReset;