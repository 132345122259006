import dayjs from 'dayjs';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useAppState } from '../../../context';
import { useTimer } from '../../../lib';
import FormErrorMessage from '../FormErrorMessage';
import LinkGuide from '../LinkGuide';
import RakutenInput from './RakutenInput';
import { FormIsDirtys } from './RakutenSetting';

interface RakutenSettingRmsFormProps {
	initValues: Record<string, any>;
	setValues: Record<string, any>;
	onSave: (values: Record<string, any>) => Promise<boolean>;
	onInit: () => Promise<boolean>;
	setFormIsDirtys: React.Dispatch<React.SetStateAction<FormIsDirtys>>;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function RakutenSettingRmsForm(props: RakutenSettingRmsFormProps): React.ReactElement {
	const [state] = useAppState();
	const useServiceSquare = state.shop?.useServiceSquare ?? false;
	const { register, handleSubmit, errors, reset, formState: { isDirty } } = useForm({
		defaultValues: props.initValues,
	});
	React.useEffect(() => {
		props.setFormIsDirtys(prev => ({ ...prev, rms: isDirty }));
	}, [isDirty]);

	const [result, setResult] = React.useState('');
	const [popover, setPopover] = React.useState(false);

	React.useEffect(() => {
		reset(props.initValues);
	}, [props.initValues]);

	const setTimer = useTimer();

	const trimValues = (obj: Record<string, unknown>) => {
		return Object.fromEntries(Object.entries(obj).map(([k, v]) => ([k, typeof v === 'string' ? v.trim() : v])));
	};

	const handleClickSave = handleSubmit(async (values) => {
		const res = await props.onSave(trimValues(values));
		reset(props.initValues);
		setResult(res ? 'is_success' : 'is_error');

		if (res) {
			setTimer(() => setResult(''), 6000);
			reset(trimValues(values));
		}
	});

	const handleClickInit = async () => {
		if (window.confirm("RMS WEB SERVICE 利用情報 を初期化してよろしいですか？")) {
			const res = await props.onInit();
			setResult(res ? 'is_success' : 'is_error');

			if (res) {
				setTimer(() => setResult(''), 6000);
				reset();
			}
		}
	}

	const message = useServiceSquare
		? "licenseKey キー情報を入力してください。"
		: "serviceSecret・licenseKey キー情報を入力してください。";

	return (
		<>
			<div className="bl_col bl_col__4">
				<div className="bl_panel bl_panel__ttlInv">
					<h2 className="el_lv2Heading">RMS WEB SERVICE 利用情報</h2>
					<p className="el_rakutenSettingDesc">{message}</p>
					<p className="txt_blue mb_16"><a href="#" onClick={() => setPopover(prev => !prev)}><span className="el_linkIcon__guideF"></span>入力値の参照先は？</a></p>
					{popover &&
						<div className="bl_panel_row">
							<h3 className="el_lv3Heading">
								{!useServiceSquare && 'serviceSecret・'}licenseKey キー情報参照先
							</h3>
							<div className="ml_8 el_txtOrange fs_12">
								<p className="txt_bold mb_8">RMSへログイン後、以下の項目をご確認ください</p>
								{useServiceSquare &&
									<ol>
										<li className="mb_8">1. RMS メインメニューの「店舗様向け情報・サービス ＞ 3 各種申請・設定変更 ＞ システム開発企業からのAPIアクセス許可設定の設定変更」</li>
										<li className="mb_8">2. 利用製品一覧の「ご利用中の製品」に表示されているラクラクあつまレビューの「ライセンスキー」</li>
									</ol>
								}
								{!useServiceSquare &&
									<ol>
										<li className="mb_8">1. RMS メインメニューの「店舗様向け情報・サービス＞5 WEB APIサービス」</li>
										<li className="mb_8">2. 楽天RMS WEB SERVICEの「2 利用設定＞2-1 WEB API」に表示されている「serviceSecret」と「licenseKey」</li>
									</ol>
								}
							</div>
						</div>
					}
					{!useServiceSquare &&
						<div className="bl_panel_row">
							<h3 className="el_lv3Heading">
								serviceSecret キー情報
								<LinkGuide guidePath='registration_setting/rakuten_system_setting' link='各種設定_2a' className='el_linkIcon__guideF' />
							</h3>
							<RakutenInput type="text" name="serviceSecret" ref={register({ required: 'serviceSecret キー情報を入力してください' })}
								errors={errors} storedValue={props.setValues.serviceSecret} />
							<FormErrorMessage errors={errors} name="serviceSecret" />
						</div>
					}
					<div className="bl_panel_row">
						<h3 className="el_lv3Heading">
							licenseKey キー情報
							<LinkGuide guidePath='registration_setting/rakuten_system_setting' link={useServiceSquare ? '各種設定_2b' : '各種設定_2a'} className='el_linkIcon__guideF' />
						</h3>
						<RakutenInput type="text" name="licenseKey" ref={register({ required: 'licenseKey キー情報を入力してください' })}
							errors={errors} storedValue={props.setValues.licenseKey} />
						<FormErrorMessage errors={errors} name="licenseKey" />
					</div>
					<div className="bl_panel_row">
						<h3 className="el_lv3Heading">licenseKey 有効期限</h3>
						<p className="el_rakutenSettingNote">
							{props.setValues.licenseExpiryDate ? dayjs(props.setValues.licenseExpiryDate).format('YYYY年MM月DD日') : '取得できません'}
						</p>
					</div>
					<div className="el_largeBtnWrap">
						<button className={"el_largeBtn " + result} onClick={handleClickSave}>保存</button>
						<button className="el_largeBtnBlueInv" onClick={handleClickInit}>初期化</button>
					</div>
				</div>
			</div>
		</>
	);
}
export default RakutenSettingRmsForm;