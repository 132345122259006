import { User, UserState } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { deleteEntityUser, getEntityUsers, postEntityUser } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import FormErrorMessage from '../FormErrorMessage';

interface AccountSettingProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AccountSettingList(props: AccountSettingProps): React.ReactElement {
	const { register, handleSubmit, errors } = useForm({});
	const [state] = useAppState();
	const callAPI = useAPI();
	const [users, setUsers] = React.useState<null|User[]>(null);
	const [reload, setReload] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!state.params.shopId) {
			return;
		}

		return callAPI(getEntityUsers(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const users = result.data.map((obj: Record<string, unknown>) => new User(obj));
			setUsers(users);
		});
	}, [state.params.shopId, reload]);

	const getShopName = (shopId: string) => state.shops?.[shopId]?.name || shopId;

	const handleClickSendInviteMail = handleSubmit(async (values) => {
		try {
			const inputs: string[] = values['email'].split(',').map((val: string) => val.trim());
			const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
			const targets: string[] = inputs.filter((val) => {
				return reg.test(val);
			});
			if (!targets.length) {
				alert("招待するユーザーのメールアドレスを入力してください。");
			}
			const invited = [];
			const failed = [];
			for (const target of [...new Set(targets)]) {
				const mailOption = {
					shop_name: getShopName(state.params.shopId),
					invite_name: state.user?.name || '',
				};
				const result = await callAPI(postEntityUser(state.params.shopId, target, ['review_login', 'pending'], mailOption));
				if (result.data) {
					invited.push(target);
				} else {
					failed.push(target);
				}
			}
			const msg = [];
			if (invited.length > 0) {
				msg.push(`${invited.length}件のアカウントを招待しました。`);
				setReload(!reload);
			}
			if (failed.length > 0) {
				msg.push(`${failed.length}件のアカウントの招待に失敗しました。`);
			}
			if (msg.length > 0) {
				alert(msg.join("\n"));
			}
		} catch (err) {
			return false;
		}
	});

	const handleClickDeleteUser = async (userId: string) => {
		const user = users?.find((u) => u.id === userId);
		if (user) {
			if (window.confirm(`${user.mail} アカウントを削除してよろしいですか？`)) {
				const res = await callAPI(deleteEntityUser(state.params.shopId, user.id));
				if (res) {
					setReload(!reload);
				}
			}
		}
	};

	const toDateString = (milliepoc: number | undefined): string => {
		if (!milliepoc) {
			return '';
		}
		return dayjs(milliepoc).format('YYYY年MM月DD日 HH:mmまで');
	};
	const toUserEditURL = (userId: string) => {
		return `${state.params.basePath}/account/account-setting/${userId}`;
	};
	const toEditButton = (user: User) => {
		if (['inviting', 'invitation_expired'].includes(user.getState(state.params.shopId))) {
			return <span className="el_btnTerm">編集</span>
		} else {
			return <Link className="el_btnInv" type="button" to={toUserEditURL(user.id)}>編集</Link>
		}
	}
	const toStateString = (state: UserState) => {
		return {
			'inviting': '招待中',
			'invitation_expired': '招待有効期限切れ',
			'pending': '承認待ち',
			'active': 'アクティブ中',
			'suspend': '停止中',
			'expired': 'アカウント有効期限切れ',
			'none': '',
		}[state] || state;
	};

	return (
		<>
			<div>
				<h1 className="el_pageTtl">ユーザーアカウント設定</h1>
				<p className="el_pageDesc">ユーザーアカウントの新規作成、確認・編集ができます。</p>
				<div className="bl_row">
					<div className="bl_col bl_col__12">
						<div className="bl_panel bl_panel__bt">
							<div className="bl_panel_row">
								<h2 className="el_lv3Heading">ユーザーアカウントを新規追加</h2>
								<input className="mb_16" type="text" name="email" placeholder="aaaaaaaa@aaa.co.jp" ref={register({ required: '招待するユーザーのメールアドレスを入力してください。\n複数ある場合はカンマ区切りで入力可能です。' })} />
								<FormErrorMessage errors={errors} name="email" />
								<button className="el_btnInv" type="button" onClick={handleClickSendInviteMail}>招待メールを送信</button>
							</div>
							<h2 className="el_lv3Heading fs_18">ユーザーアカウント管理</h2>
							{users && <>
								<table className="bl_table bl_table__userTable">
									<thead className="bl_table_head">
										<tr>
											<th>ユーザー名</th>
											<th>メールアドレス</th>
											<th>アカウント有効期限</th>
											<th>状態</th>
											<th>2段階認証</th>
											<th></th>
										</tr>
									</thead>
									<tbody className="bl_table_body">
										{users.map((user) => 
											<tr key={user.id}>
												<td className={user.isAdmin(state.params.shopId) ? 'el_admin' : ''}>{user.name}</td>
												<td>{user.mail}</td>
												<td>{toDateString(user.getExpire(state.params.shopId))}</td>
												<td>
													<span>{toStateString(user.getState(state.params.shopId))}</span>
												</td>
												<td>{user.twoFactorAuth ? '設定中' : ''}</td>
												<td>
													<div className="bl_table_btnWrap">
														{toEditButton(user)}
														<button className="el_btnInv" type="button" onClick={() => handleClickDeleteUser(user.id)}>削除</button>
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
								<p className="el_resultCount">全{users.length}件</p>
							</>}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default AccountSettingList;