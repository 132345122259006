import { ReactHelpMarkdown } from '@sasagase/react-help-markdown';
import { Help } from '@sasagase/types';
import * as React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getArticle } from '../../../api';
import { setModal, useAPI, useAppState } from '../../../context';
import { toBR } from '../../../lib';
import { modifyGuideAnchor } from '../guide/markdownModify';

interface FaqDetailProps {}
type FaqDetailParams = {
	faqId?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function FaqDetail(props: FaqDetailProps): React.ReactElement | null {
	const [state, setState] = useAppState();
	const shopId = state.params.shopId;
	const basePath = state.params.basePath;
	const params = useParams<FaqDetailParams>();
	const location = useLocation();

	const callAPI = useAPI();
	const [article, setArticle] = React.useState<Help.Article|null>(null);

	React.useEffect(() => {
		if (!params.faqId) {
			return;
		}

		return callAPI(getArticle(shopId, params.faqId), (err, res) => {
			if (err) {
				return;
			}
			const article = Help.Article.create(res.data);
			setArticle(article);
		});
	}, [params.faqId]);

	const pageScroll = (): void => {
		const searchId = decodeURIComponent(location.hash)?.slice(1) ?? "";
		const targetElement = document.getElementById(searchId);

		if (!searchId || !targetElement) {
			return;
		}
		const elementPosition = targetElement.getBoundingClientRect();
		const xPotition = window.pageXOffset + (elementPosition?.left ?? 0);
		const yPotition = window.pageYOffset + (elementPosition?.top ?? 0);
		window.scrollTo(xPotition, yPotition);
	};

	if (!article || !article.isFaq() ) {
		return null;
	}

	const getBreadcrumb = (article: Help.Article) => {
		const breadcrumbs = [];
		breadcrumbs.push(<Link key="faqTop" to={basePath + '/faq'}>よくある質問</Link>);
		const categories: Help.Term[] = [article.category?.parent, article.category].filter((c): c is Help.Term => typeof c !== undefined);
		categories.forEach(c => {
			breadcrumbs.push(<Link key={c.id} to={basePath + '/article/category/' + c.getSlug()}>{c.name}</Link>);
		});
		return breadcrumbs.reduce<React.ReactNode[]>((prev, curr, idx) => {
			return [...prev, curr, <React.Fragment key={idx}> &gt; </React.Fragment>];
		}, []).slice(0, -1);
	};

	const mdCallback = {
		showLightBox: (src?: string) => setState(setModal(Boolean(src))),
		load: pageScroll,
	};

	return (
		<>
			<h1 className="el_pageTtl">{getBreadcrumb(article)}</h1>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row bl_guide">
							<h3>質問</h3>
							<p className="mb_16">{toBR(article.title)}</p>
							<h3>回答</h3>
							<ReactHelpMarkdown
								md={article.content}
								modifyAnchor={modifyGuideAnchor(basePath)}
								callback={mdCallback}
								/>
						</div>
						<div className="bl_panel_row">
							<Link className="el_backLink" to={basePath + '/faq'}>≪一覧へ戻る</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default FaqDetail;