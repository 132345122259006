import { CapabilityType, MASTER_ENTITY_ID } from '@sasagase/types';
import * as React from 'react';
import { useAppState } from '../../context';

interface SideElementProps {
	caps: CapabilityType[];
	children: React.ReactNode;
}

export function SideElement(props: SideElementProps): React.ReactElement|null {
	const [state] = useAppState();
	const entityId = state.params.shopId;

	let isCap, isCapMaster;
	for(const cap of props.caps) {
		isCap = state.user?.hasEnableCaps(cap, entityId);
		isCapMaster = state.user?.hasEnableCaps(cap, MASTER_ENTITY_ID);
		if (isCap || isCapMaster) break;
	}

	if(isCap || isCapMaster) {
		return <>{props.children}</>;
	} else {
		return null;
	}
}