import { ShopEntity, ShopEntityAttr } from '@sasagase/types';
import * as React from 'react';
import { getShopEntity, putShopEntity } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import ShopSettingForm from './ShopSettingForm';

interface ShopSettingProps {}

const initValues = {
	shopName: '',
	companyName: '',
	logoImage: '',
	shopUrl: '',
	contactType: '',
	contactPhone: '',
	contactMail: '',
};
type FormValues = {
	shopName: string,
	companyName: string,
	logoImage: string,
	shopUrl: string,
	contactType: string,
	contactPhone: string,
	contactMail: string,
};

function toShopEntityAttr(id: string, values: Partial<FormValues>,): Partial<ShopEntityAttr> {
	const shopEntity: Partial<ShopEntityAttr> = {
		shopName: values.shopName,
		companyName: values.companyName,
		logoImage: values.logoImage,
		shopUrl: values.shopUrl,
		contactType: values.contactType,
		contactPhone: values.contactPhone,
		contactMail: values.contactMail,
	};
	return shopEntity;
}
function toValues(shopEntity: ShopEntity): FormValues {
	return {
		...initValues,
		shopName: shopEntity.shopName || '',
		companyName: shopEntity.companyName || '',
		logoImage: shopEntity.logoImage || '',
		shopUrl: shopEntity.shopUrl || '',
		contactType: shopEntity.contactType || '',
		contactPhone: shopEntity.contactPhone || '',
		contactMail: shopEntity.contactMail || ''
	};
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ShopSetting(props: ShopSettingProps): React.ReactElement|null {
	const callAPI = useAPI();
	const [state] = useAppState();
	const [values, setValues] = React.useState<FormValues>(initValues);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		return callAPI(getShopEntity(state.params.shopId), (err, result) => {
			if (err) {
				return;
			}
			const shopEntity = new ShopEntity(result.data);
			setValues(toValues(shopEntity));
			setLoading(false);
		});
	}, [state.params.shopId]);

	const handleSave = async (values: Record<string, string|number|boolean|Date>) => {
		try {
			const shopEntity = toShopEntityAttr(state.params.shopId, values);
			await callAPI(putShopEntity(state.params.shopId, shopEntity));
			return true;
		} catch (err) {
			return false;
		}
	};

	if (loading) {
		return null; // loading
	}
	return <ShopSettingForm initValues={values} onSave={handleSave} />;
}
export default ShopSetting;