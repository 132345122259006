import ja from 'date-fns/locale/ja';
import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, UseFormMethods } from 'react-hook-form';
registerLocale('ja', ja);

export type DateFields = Date | number | string;

export function toDate(fields: DateFields): Date {
	if (!(fields instanceof Date)) {
		fields = new Date(parseInt(fields != null ? fields.toString() : '', 10));
	}
	return fields;
}

export function toDateFields(date?: Date | number): Date|"" {
	const checkedDate = date ? new Date(date) : "";
	return checkedDate;
}

interface DateInputProps {
	name: string;
	methods: UseFormMethods<any>;
	seconds: string;
	minDate: Date;
	disabled?: boolean;
}

export const DateInput: React.FC<DateInputProps> = (props) => {
	const { setValue, clearErrors, control } = props.methods;

	const handleClickClear: React.MouseEventHandler = () => {
		clearErrors(props.name);
		setValue(props.name, '');
	};

	return (
		<>
			<Controller
				control={control}
				name={props.name}
				render={({ onChange, onBlur, value }) => (
					<ReactDatePicker
						popperClassName="datePickerPopper"
						locale="ja"
						onChange={onChange}
						onBlur={onBlur}
						onCalendarClose={onBlur}
						selected={value}
						minDate={props.minDate}
						showTimeSelect
						timeIntervals={10}
						timeCaption="時間"
						timeFormat="HH：mm"
						dateFormat="yyyy/MM/dd HH:mm"
						disabled={props.disabled}
					/>
				)}
			/>
			<button className="el_btnTxt" type="button" onClick={handleClickClear}>クリア</button>
		</>
	);
};
export default DateInput;
