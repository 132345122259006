import { MASTER_ENTITY_ID, Notices } from '@sasagase/types';
import * as React from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { getAlerts, getShop, getShops } from '../../api';
import { setParams, shopSetter, shopsSetter, useAPI, useAppState } from '../../context';
import Header from './Header';
import Side from './Side';

interface ReviewProps {}
type ReviewParams = {
	shopId: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Review(props: ReviewProps): React.ReactElement|null {
	const param = useParams<ReviewParams>();
	const [state, setState] = useAppState();
	const [alert, setAlert] = React.useState<Notices.Content|null>(null);
	const [isOpen, setIsOpen] = React.useState(true);
	const [isOpenMobile, setIsOpenMobile] = React.useState(false);
	const location = useLocation();
	
	const callAPI = useAPI();

	React.useEffect(() => {
		return callAPI(getAlerts(param.shopId ?? ''), (err, res) => {
			if (err) {
				return;
			}
			const contents: Notices.Content[] = res.data.map((obj: Record<string, unknown>) => Notices.Content.create(obj));
			setAlert(contents.shift() || null);
		});
	}, [location.pathname]);

	React.useEffect(() => {
		// 例えばpath='/review/:shopId/' の子供の path='/' ならshopIdが取得できるが、
		// 子供の path='/aaa' からは useParamsなどでも取得できないのでcontextに設定する
		setState(setParams({
			shopId: param.shopId,
			basePath: `/review/${param.shopId ?? ''}`,
		}));
	}, [param.shopId]);

	React.useEffect(() => {
		return callAPI(getShops(), (err, result) => {
			if (err) {
				return;
			}
			setState(shopsSetter(result.data));
		});
	}, [state.user?.capabilities]);

	// 選択中のShopをstate.shopに設定する
	const shop = state.shops?.[param.shopId || ''];
	React.useEffect(() => {
		setState(shopSetter(shop))
	}, [shop]);

	React.useEffect(() => {
		if (!state.user?.hasEnableCaps('admin_login', MASTER_ENTITY_ID)) {
			return;
		}
		const pShopId = param.shopId;
		if (shop || !pShopId) {
			return;
		}
		return callAPI(getShop(pShopId), (err, result) => {
			if (err || !result.data) {
				return;
			}
			const shops = state.shops || {};
			shops[pShopId] = result.data;
			setState(shopsSetter(shops));
			setState(shopSetter(result.data));
		});
	}, [shop, param.shopId]);

	React.useEffect(() => {
		const isState = (val: unknown): val is Record<string, unknown> => typeof location.state == 'object' && location.state != null;
		if (isState(location.state) && location.state.newId) {
			return;
		}

		if (!location.hash) {
			window.scrollTo(0, 0);
		}
	}, [location.pathname]);

	const handleClickBurger = () => {
		setIsOpen(v => !v);
		setIsOpenMobile(v => !v);
	};
	const handleSizeClose = () => {
		setIsOpenMobile(false);
	};

	// 不正なshopIdははじく
	if (!param.shopId || !state.user?.getEnableCaps('review_login', param.shopId)) {
		return <Navigate to=".." />;
	}

	const basePath = state.params?.basePath;
	if (!basePath) {
		return null;
	}

	return (
		<div className={state.modal ? 'show_detail' : ''}>
			<Header onClickBurger={handleClickBurger} basePath={basePath} />
			<div className="ly_body">
				<main className={"ly_main"}>
					{alert &&
						<div className='ly_main_error'>
							<h2>通信エラー</h2>
							<p>{alert.getContent()}</p>
						</div>
					}
					<div className='ly_main_content'>
						<Outlet />
					</div>
				</main>
				<Side isOpen={isOpen} isOpenMobile={isOpenMobile} basePath={basePath} onClose={handleSizeClose} />
			</div>
		</div>
	);
}
export default Review;