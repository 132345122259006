import { BodyElement, BodyElementType, bodyElementType, Campaign, MailContent, MailTemplate } from '@sasagase/types';
import { toArrayMap } from '@sasagase/util';
import * as React from 'react';
import { FieldErrors, UseFormMethods } from 'react-hook-form';
import { array, define, dynamic, enums, string, type } from 'superstruct';
import { getReviewMail } from '../../../../api';
import { useAPI, useAppState } from '../../../../context';
import FormErrorMessage from '../../FormErrorMessage';
import { MailEditRow } from '../../mail/MailEditRow';
import Preview from '../../mail/Preview';

export interface MailFormValue {
	template: string;
	disable: string;
	subject: string;
	bodyHeader: string;
	bodyElement: BodyElement[];
	signature: string;
	email: string;
}

export interface CampaignEditFormStep4FormValue {
	followMail: MailFormValue,
	requestMail: MailFormValue,
	receivedMail: MailFormValue,
	destType: 'form' | 'shipping' | 'orderer',
}

const BodyElementStruct = type({
	type: enums<BodyElementType>(bodyElementType),
	content: string(),
  });

const MailFormValueStruct = type({
	disable: string(),
	subject: define<string>('subject', (val) => String(val).length > 0 || '件名を入力してください'),
	template: string(),
	bodyHeader: string(),
	bodyElement: array(BodyElementStruct),
	signature: string(),
	email: string(),
});

const ApplyFormMailFormValueStruct = type({
	disable: string(),
	subject: define<string>('subjecta', (val) => String(val).length > 0 || '件名を入力してください'),
	template: string(),
	bodyHeader: string(),
	bodyElement: define<BodyElement[]>('bodyElement', (val) => {
		const bodyElement = val as typeof BodyElementStruct[];
		const hasType = bodyElement.some(element => element.type === 'apply_form_url');
		return hasType || '本文に申込みフォームURLを追加してください';
	}),
	signature: string(),
	email: string(),
});

const CouponMailFormValueStruct = type({
	disable: define<string>('disable', (val) => String(val) === 'false' || 'レビュー特典にクーポンが含まれるため、申込受付完了メールの送信は必要です'),
	subject: define<string>('subject', (val) => String(val).length > 0 || '件名を入力してください'),
	template: string(),
	bodyHeader: string(),
	bodyElement: define<BodyElement[]>('bodyElement', (val) => {
		const bodyElement = val as typeof BodyElementStruct[];
		const hasType = bodyElement.some(element => element.type === 'coupon_url');
		return hasType || '本文にクーポン URLを追加してください';
	}),
	signature: string(),
	email: string(),
});

const MailFormEmptyValueStruct = type({
	disable: string(),
	subject: string(),
	template: string(),
	bodyHeader: string(),
	bodyElement: array(BodyElementStruct),
	signature: string(),
	email: string(),
});

export const CampaignEditFormStep4Struct = dynamic((values: any) => {
	const canChooseReward = values.rewards.length > 1;
	const destType = values.destType;
	const enableFollowMail = values.followMail.disable !== "true";
	const enableReceivedMail = values.receivedMail.disable !== "true";
	const isCouponReward = values.isCouponReward;
	const canChooseDest = !isCouponReward && destType === 'form';
	return type({
		followMail: enableFollowMail ? MailFormValueStruct : MailFormEmptyValueStruct,
		requestMail: canChooseReward || canChooseDest ? ApplyFormMailFormValueStruct : MailFormEmptyValueStruct,
		receivedMail: isCouponReward ? CouponMailFormValueStruct : enableReceivedMail ? MailFormValueStruct : MailFormEmptyValueStruct,
		destType: enums(['form', 'shipping', 'orderer']),
	});
});

interface CampaignEditFormStep4Props {
	methods: UseFormMethods<CampaignEditFormStep4FormValue>;
	templates: MailTemplate[];
	reviewFormCond: () => Record<string, boolean>;
	campaign: Campaign;
}

export function CampaignEditFormStep4(props: CampaignEditFormStep4Props): React.ReactElement {
	const { errors, getValues } = props.methods;
	const categories = React.useMemo(() => {
		return toArrayMap(props.templates, (template) => template.category);
	}, [props.templates]);

	const { canChooseReward, canChooseDest, hasItemReward, disabledReviewForm } = props.reviewFormCond();
	const alert = (hasItemReward && !canChooseDest) ? '特典送り先をお客様が選択不可' :
		!canChooseReward && 'レビュー特典が１つのみ'

	// レビュー完了メールの送信必須(特典が複数 or クーポン以外の特典で特典の送り先を申込みフォーム選択)
	const sendRequiredRequestMail = canChooseReward || canChooseDest ? true : false;
	const requiredReasonRequestMail = sendRequiredRequestMail ? '申込みフォームが設定されているため、レビュー完了メールの送信は必須となります' : '';
	// 申込受付完了メールの送信必須
	const isCouponReward = getValues("isCouponReward");
	const sendRequiredReceivedMail = isCouponReward ? true : false;
	const requiredReasonReceivedMail = sendRequiredReceivedMail ? 'レビュー特典にクーポンが含まれるため、申込受付完了メールの送信は必須となります' : '';

	return (
		<>
			<MailSection methods={props.methods} templates={categories.review_request || []} name="followMail" errors={errors.followMail} campaign={props.campaign} sendRequired={false}>フォローメール</MailSection>
			<MailSection
				methods={props.methods}
				templates={categories.review_completed || []}
				name="requestMail"
				errors={errors.requestMail}
				message={disabledReviewForm ? `※この項目は現在の設定では使用されません（${alert}）` : ''}
				disabled={disabledReviewForm}
				campaign={props.campaign}
				sendRequired={sendRequiredRequestMail}
				sendRequiredReason={requiredReasonRequestMail}
				>レビュー完了メール</MailSection>
			<MailSection methods={props.methods} templates={categories.application_completed || []} name="receivedMail" className="mb_48" errors={errors.receivedMail} campaign={props.campaign} sendRequired={sendRequiredReceivedMail} sendRequiredReason={requiredReasonReceivedMail}>申込受付完了メール</MailSection>
		</>
	);
}
export default CampaignEditFormStep4;

function toTemplateValues(template: MailTemplate): Partial<MailFormValue> {
	const bodyHeaderData = template.body.find((val) => val.type === "header");
	return {
		bodyHeader: bodyHeaderData ? bodyHeaderData.content : '',
		bodyElement: template.body.filter((val) => val.type !== "header"),
		signature: template.signature,
	};
}

interface MailSectionProps {
	methods: UseFormMethods<CampaignEditFormStep4FormValue>;
	templates: MailTemplate[];
	name: string;
	isOpen?: boolean;
	className?: string;
	errors?: FieldErrors;
	message?: string;
	disabled?: boolean;
	children?: React.ReactNode;
	campaign: Campaign;
	sendRequired: boolean;
	sendRequiredReason?: string;
}
const MailSection: React.FC<MailSectionProps> = (props) => {
	const [state] = useAppState();
	const { register, getValues, errors } = props.methods;
	const callAPI = useAPI();
	const [isOpen, setOpen] = React.useState(props.isOpen ?? true);
	const [previewValues, setPreviewValues] = React.useState<MailContent | null>(null);
	const toName = React.useCallback((name: string) => {
		return `${props.name}.${name}`;
	}, [props.name]);
	const [applyValues, setApplyValues] = React.useState<Partial<MailFormValue>|undefined>(undefined);

	const handleClickHeader = () => {
		setOpen(prev => !prev);
	};
	const handleTemplateApply = () => {
		const templateId = getValues(toName('template'));
		const template = props.templates.find(template => template.id == templateId);
		if (!template) {
			return;
		}
		setApplyValues(toTemplateValues(template));
		setPreviewValues(null);
	}
	const handleClickTemplateApply: React.MouseEventHandler<HTMLButtonElement> = () => {
		handleTemplateApply();
	};
	const handleClickSendPreview = async () => {
		const templateId = getValues(toName('template'));
		const template = props.templates.find(template => template.id == templateId);
		if (!template) {
			return;
		}
		const values = toTemplateValues(template);
		const res = await callAPI(getReviewMail(state.params.shopId, 'preview', values));
		const mailContent = new MailContent(res.data);
		setPreviewValues(mailContent);
	};
	const handleClickClosePreview = () => {
		setPreviewValues(null);
	};

	return (
		<>
			{previewValues && <Preview values={previewValues} onClose={handleClickClosePreview} onApply={handleTemplateApply} />}
			<section className={"bl_mailTemp " + (props.className || '')}>
				<h2 className={`bl_mailTemp_ttl ${isOpen ? 'is_active' : ''}`} onClick={handleClickHeader}>{props.children}設定</h2>
				<div className="bl_mailTemp_body">
					{props.message &&
						<div className="bl_panel_row bl_panel_row__indent">
							<span className="fs_14" style={{ color: 'red' }}>{props.message}</span>
						</div>
					}
					<div className={`bl_panel_row ${props.disabled && 'bl_panel_row__disabled'}`}>
						<div className="bl_panel_row bl_panel_row__indent">
							<h3 className="el_lv3Heading">メール送信設定</h3>
							<label className="bl_label">
								<input type="radio" name={toName('disable')} value="false" ref={register} disabled={props.disabled} />
								<span>{props.children}を送信する</span>
							</label>
							<label className="bl_label">
								<input type="radio" name={toName('disable')} value="true" ref={register} disabled={props.disabled || props.sendRequired} />
								<span>{props.children}を送信しない</span>
							</label>
							<FormErrorMessage errors={errors} name={toName('disable')} />
							{props.sendRequiredReason &&
								<p style={{ color: 'red' }}>※{props.sendRequiredReason}</p>
							}
						</div>
						<div className="bl_panel_row bl_panel_row__indent">
							<h3 className="el_lv3Heading">テンプレートを選択</h3>
							<div className="bl_mailTemp_selectTemp">
								<select name={toName('template')} ref={register} disabled={props.disabled}>
									<option value=""></option>
									{props.templates.map(template =>
										<option key={template.id} value={template.id}>{template.name}</option>
									)}
								</select>
								<button className="el_note" type="button" onClick={handleClickSendPreview} disabled={props.disabled}>プレビュー</button>
								&nbsp;
								&nbsp;
								&nbsp;
								<button className="el_note" type="button" onClick={handleClickTemplateApply} disabled={props.disabled}>適用</button>
							</div>
						</div>
						<div className="bl_panel_row bl_panel_row__indent">
							<h3 className="el_lv3Heading">メール件名</h3>
							<input type="text" placeholder="テキスト" name={toName('subject')} ref={register} disabled={props.disabled} />
							<FormErrorMessage errors={errors} name={toName('subject')} />
						</div>
						<MailEditRow methods={props.methods} toName={toName} applyValues={applyValues} disabled={props.disabled} campaign={props.campaign} />
					</div>
				</div>
			</section>
		</>
	);
};