import * as React from 'react';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../FormErrorMessage';

interface ExclusionMailFormProps  {
	excludeMails: string[];
	onAddExcludeMail: (values: string) => Promise<boolean>;
	onRemoveExcludeMail: (values: string[]) => Promise<boolean>;
}

export function ExclusionMailForm(props: ExclusionMailFormProps): React.ReactElement {
	const { register, handleSubmit, errors, setValue, getValues } = useForm();
	
	const handleClickAddMail = handleSubmit(async () => {
		const addEmail = getValues("email");
		const res = await props.onAddExcludeMail(addEmail);
		if (res) {
			setValue("email", "");
		}
	});

	const handleClickDeleteMail = async () => {
		let deleteEmail = getValues("deleteEmail");
		deleteEmail = Array.isArray(deleteEmail) ? deleteEmail : [deleteEmail];
		if (!deleteEmail || deleteEmail.length <= 0) {
			alert(`削除する除外メールアドレスを選択してください。`);
			return;
		}
		if (window.confirm(`以下の除外メールアドレスを削除してよろしいですか？\n\n${deleteEmail.join(`\n`)}`)) {
			const res = await props.onRemoveExcludeMail(deleteEmail);
			if (res) {
				setValue("deleteEmail", false);
				alert(`以下の除外メールアドレスを削除しました。\n\n` + deleteEmail.join("\n"));
			} else {
				alert(`除外メールアドレスの削除に失敗しました。`);
			}
		}
	}

	return (
		<div className="bl_col bl_col__4">
			<div className="bl_panel bl_panel__ttlInv">
				<h2 className="el_lv2Heading">メールアドレスから設定</h2>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">除外メールアドレス追加</h3>
					<input className="mb_8" type="text" name="email"
						ref={register({
								required: 'メールアドレスを入力してください',
								pattern: {
									value: /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
									message: 'メールアドレスを入力してください'
								}
							})}
						/>
					<FormErrorMessage errors={errors} name="email" />
					<button className="el_btnInv" type="button" onClick={handleClickAddMail}>設定</button>
				</div>
				<div className="bl_panel_row">
					<h3 className="el_lv3Heading">除外メールアドレス一覧</h3>
					<div className="bl_checkListWrap mb_8">
						<ul className="bl_checkList">
							{props.excludeMails && <>
								{props.excludeMails.map((mail, idx) => 
									<li key={`mail_${idx}`} className="bl_checkList_item">
										<label>
											<input className="el_checkMark" type="checkbox" name="deleteEmail" value={mail} ref={register} />
											<span>{mail}</span>
										</label>
									</li>
								)}
							</>}
						</ul>
					</div>
					<button className="el_btnBlueInv" type="button" onClick={handleClickDeleteMail}>削除</button>
				</div>
			</div>
		</div>
	);
}
export default ExclusionMailForm;