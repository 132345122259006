import * as React from 'react';
import { Navigate } from 'react-router-dom';

interface NotfoundProps {
	redirect?: string;
}

export function Notfound(props: NotfoundProps): React.ReactElement {
	if (props.redirect) {
		return <Navigate to={props.redirect} />;
	}

	return (
		<div className="ly_reissue">
			<div className="ly_reissue_inner">
				<div className="bl_inviteForm">
					<div className="bl_loginFormRow">
						<p className="el_errorMessage">
							指定されたページは見つかりません<br />
							URLを確認してください
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Notfound;