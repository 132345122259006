import { Help } from '@sasagase/types';
import { APIRequest } from '.';
import { uri } from '../lib';

export function getArticles(shopId: string, cond?: { articleType?: Help.ArticleTypes, termIdOrSlug?: string }): APIRequest {
	const params = new URLSearchParams();
	if (cond) {
		if (cond.articleType) {
			params.append('articleType', cond.articleType);
		}
		if (cond.termIdOrSlug) {
			params.append('termIdOrSlug', cond.termIdOrSlug);
		}
	}
	return {
		method: 'get',
		url: uri`/api/help/${shopId}/article?` + params.toString(),
	};
}
export function getArticle(shopId: string, articleId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/help/${shopId}/article/${articleId}`,
	};
}